import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const AccessView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const accessParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( accessParams, [])
	const accessOnLoaded = (options) => {
		run([{"function":"goto","view":"login","if":{"what":"@firebase.user.id","is":null}},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"set","what":"@cookie.type","value":"@firebase.firestore.users.(@cookie.userID).type"},{"function":"set","what":"@cookie.historyType","value":"orders","if":{"what":"@cookie.historyType","is":null}},{"function":"goto","view":"admin_access","if":{"what":"@cookie.type","is":"admin"}},{"function":"goto","view":"sales_access","if":{"what":"@cookie.type","is":"sales"}},{"function":"goto","view":"customer_access","if":{"what":"@cookie.type","is":"customer"}}], options)
	}
	useEffect(accessOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('access.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="accessBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px" >
			</Group>
      </FullscreenWrapper>
    </>
  );
};
