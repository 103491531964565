import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Sales_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, Input, List } from '../components';
import { useTranslation } from 'react-i18next';

export const Sales_ordersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminOrderActionDeleteOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const adminOrderActionReopenOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"}], options)
	}
	const openOrderActionConfirm1661701704914OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderActionEditDateOnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const holdedSyncButton1663682621230OnClick = (options) => {
		run([{"function":"set","what":"@property.action","value":"force_shipping"},{"function":"popup","dialog":"confirmation"}], options)
	}
	const component1654860541008OnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('sales_orders.title')}</title>
      </Helmet>
      <Sales_wrapperWrapper>
          
			<Group
				id="adminOrdersBody1654852952245"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="adminOrdersBody1654852952245"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="ordersHeader1654852952244"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Icon
							id="component1654860541008"
							onClick={component1654860541008OnClick}
							src="arrow-left"
							className="w-auto h-auto valign-center position-relative me-16px"
						/>
						<Text
							id="ordersHeaderTitle1654852952244"
							if={{"what":"@cookie.order_status","is":"open"}}
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-antonregular" >
							{t('sales_orders.ordersHeaderTitle1654852952244')}
						</Text>
						<Text
							id="ordersHeaderTitle1661556287252"
							if={{"what":"@cookie.order_status","is":"confirmed"}}
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-antonregular" >
							{t('sales_orders.ordersHeaderTitle1661556287252')}
						</Text>
						<Input.Select
							id="ordersHeaderSelector1654852952244"
							if={{"what":"@property.flag","is":"shouldShowSelector"}}
							label={t('sales_orders.ordersHeaderSelector1654852952244.label')}
							name="select"
							src="@cookie.historyType"
							className="w-auto h-auto valign-center position-relative" >
							<option value={"orders"}>{t('sales_orders.ordersHeaderSelector1654852952244.opt0')}</option>
							<option value={"orders_processing"}>{t('sales_orders.ordersHeaderSelector1654852952244.opt1')}</option>
						</Input.Select>
						<Text
							id="holdedSyncButton1663682621230"
							onClick={holdedSyncButton1663682621230OnClick}
							className="w-auto h-auto border-radius-50px valign-center position-relative bg-primary px-16px py-6px" >
							{t('sales_orders.holdedSyncButton1663682621230')}
						</Text>
					</Group>
					<List
						id="adminOrderList"
						src="@firebase.firestore.orders"
						filter={{"what":"order_status","is":"@cookie.order_status"}}
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="historyOrderCard1659919974495"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
							<Group
								id="historyOrderCardHeader1659919974495"
								direction="auto"
								className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
								<Group
									id="openOrderCreationGroup1659919974495"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('sales_orders.openOrderCreationTitle1659919974495')}
									</Text>
									<Text
										id="openOrderCreationValue1659919974495"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup1659919974495"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('sales_orders.openOrderDeliveryTitle1659919974495')}
									</Text>
									<Text
										id="openOrderDeliveryValue1659919974495"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup1659919974495"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('sales_orders.openOrderBilledTitle1659919974495')}
									</Text>
									<Text
										id="openOrderBilledValue1659919974495"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('sales_orders.openOrderBilledValue1659919974495')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup1659919974495"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('sales_orders.openOrderLocationTitle1659919974495')}
									</Text>
									<Text
										id="openOrderLocationValue1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('sales_orders.openOrderLocationValue1659919974495')}
									</Text>
								</Group>
								<Group
									id="component1659919974495"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup1659919974495"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('sales_orders.openOrderIdTitle1659919974495')}
									</Text>
									<Text
										id="openOrderIdValue1659919974495"
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('sales_orders.openOrderIdValue1659919974495')}
									</Text>
								</Group>
							</Group>
							<Group
								id="historyOrderCardContent1659919974495"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="historyOrderCardCart1659919974495"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<List
										id="openOrderCartList1659919974495"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow1659919974495"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Text
												id="openOrderCartListRowUnits1659919974495"
												className="w-50px h-auto position-relative me-12px ff-label" >
												{t('sales_orders.openOrderCartListRowUnits1659919974495')}
											</Text>
											<Text
												id="openOrderCartListRowName1659919974495"
												src="name"
												className="w-fill h-auto position-relative ff-label" >
												{t('sales_orders.openOrderCartListRowName1659919974495')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="historyOrderCardActions1659919974495"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="openOrderActionEditDate"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionEditDateOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
										{t('sales_orders.openOrderActionEditDate')}
									</Text>
									<Text
										id="openOrderActionConfirm1661701704914"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionConfirm1661701704914OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
										{t('sales_orders.openOrderActionConfirm1661701704914')}
									</Text>
									<Text
										id="adminOrderActionReopen"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={adminOrderActionReopenOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
										{t('sales_orders.adminOrderActionReopen')}
									</Text>
									<Text
										id="adminOrderActionDelete"
										if={{"what":"@field.order_status","is":"open","or":{"what":"@field.order_status","is":"confirmed"}}}
										onClick={adminOrderActionDeleteOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('sales_orders.adminOrderActionDelete')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Sales_wrapperWrapper>
    </>
  );
};
