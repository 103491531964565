import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Customer_company_existsDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const customer_company_exists_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"customer_company_exists"}], options)
	}

  return (
    
    
			<Modal id="customer_company_exists">
					<Modal.Body>
						<Text
							id="existingCompanyApplied"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('customer_company_exists.existingCompanyApplied')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="customer_company_exists_buttons_positive"
							onClick={customer_company_exists_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('customer_company_exists.customer_company_exists_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Customer_company_existsDialog;
