import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Image, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';
import menta from '../assets/helados/menta.png';

export const Product_shopDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const detailButtonClose1657094813623OnClick = (options) => {
		run([{"function":"hide","dialog":"product_buy"}], options)
	}
	const detailButtonGotoCart1657094813623OnClick = (options) => {
		run([{"function":"hide","dialog":"product_shop"},{"function":"goto","view":"customer_cart"}], options)
	}
	const detailButtonKeepBuying1657094813623OnClick = (options) => {
		run([{"function":"hide","dialog":"product_shop"}], options)
	}

  return (
    
    
			<Offcanvas id="product_shop" placement="end">
					<Offcanvas.Body>
						<Group
							id="detailBody1657094813623"
							direction="horizontal"
							className="w-100 min-h-100 fg-100 position-relative" >
							<Group
								id="detailInfoCenter1657094813623"
								direction="vertical"
								className="max-w-1200px w-100 h-auto align-center valign-center position-relative" >
								<Group
									id="detailContent1657094813623"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Image
										id="detailImage1657094813622"
										fallback={menta}
										src="@cookie.product.image"
										className="w-200px h-auto align-center position-relative" />
									<Group
										id="detailInfo1657094813623"
										direction="vertical"
										className="w-fill h-auto position-relative" >
										<Text
											id="detailName1657094813622"
											tag="h3"
											src="@cookie.product.name"
											className="w-100 h-auto position-relative mt-32px ff-antonregular text-center" >
											{t('product_shop.detailName1657094813622')}
										</Text>
										<Text
											id="detailDescription1657094813623"
											src="@cookie.product.description"
											className="w-100 h-auto position-relative ff-librebaskervilleregular text-center" >
											{t('product_shop.detailDescription1657094813623')}
										</Text>
										<Group
											id="detailUnitsGroup1657094813623"
											direction="horizontal"
											className="w-auto h-auto align-center position-relative mt-32px" >
											<Text
												id="detailUnitsLabel1657094813623"
												className="w-auto h-auto valign-center position-relative pe-16px ff-librebaskervillebold" >
												{t('product_shop.detailUnitsLabel1657094813623')}
											</Text>
											<Input.Number
												id="detailUnits1657094813623"
												name="text"
												src="@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.productID).units"
											autoComplete="off"
												className="w-75px h-auto position-relative" />
										</Group>
									</Group>
								</Group>
								<Group
									id="detailButtons1657094813623"
									direction="vertical"
									className="w-100 h-auto align-center position-relative mt-64px" >
									<Button
										id="detailButtonKeepBuying1657094813623"
										if={{"what":"@cookie.shopButtons","is":"shop"}}
										onClick={detailButtonKeepBuying1657094813623OnClick}
										variant="primary"
										className="w-100 h-auto border-radius-100px position-relative" >
										{t('product_shop.detailButtonKeepBuying1657094813623')}
									</Button>
									<Button
										id="detailButtonGotoCart1657094813623"
										if={{"what":"@cookie.shopButtons","is":"shop_never"}}
										onClick={detailButtonGotoCart1657094813623OnClick}
										variant="accent"
										className="w-100 h-auto border-radius-100px position-relative mt-12px" >
										{t('product_shop.detailButtonGotoCart1657094813623')}
									</Button>
									<Button
										id="detailButtonClose1657094813623"
										if={{"what":"@cookie.shopButtons","is":"cart_never"}}
										onClick={detailButtonClose1657094813623OnClick}
										variant="accent"
										className="w-100 h-auto border-radius-100px position-relative mt-12px" >
										{t('product_shop.detailButtonClose1657094813623')}
									</Button>
								</Group>
							</Group>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Product_shopDialog;
