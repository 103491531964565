import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Button, List, Icon, Lottie } from '../components';
import { useEffect } from 'react';
import anim_wait_white from '../assets/animation/anim_wait_white.json';
import { useTranslation } from 'react-i18next';

export const OrdersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const ordersParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( ordersParams, [])
	const ordersOnLoaded = (options) => {
		run([{"function":"set","what":"@property.showCurtain","value":false}], options)
	}
	useEffect(ordersOnLoaded, [])
	const openOrderActionDeleteOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const openOrderActionCloneOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/cloneOrder","method":"post","response":"json-api","into":"@database.response","params":{"orderID":"@cookie.orderID"},"onerror":[{"function":"set","what":"@property.error","value":"No se ha podido duplicar el pedido. Int\u00e9ntalo m\u00e1s tarde."},{"function":"popup","dialog":"error_dialog"}],"onsuccess":[{"function":"set","what":"@cookie.orderID","value":"@database.response.orderID"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const openOrderActionChangeDeliveryOnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const openOrderActionEditOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"goto","view":"store"}], options)
	}
	const openOrderActionConfirmOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderPlusButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.+1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.+1"}]}], options)
	}
	const openOrderMinusButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.-1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.-1"}],"if":{"what":"@field.units","more_than":1}},{"function":"do","what":[{"function":"delete","what":"@database.orders.(@cookie.orderID).cart.(@field.id)"},{"function":"delete","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id)"}],"if":{"what":"@field.units","is":1}}], options)
	}
	const newPurchaseButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"customer_new_order"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('orders.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="customerWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="ordersOpen"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Text
						id="ordersOpenHeaderTitle1657095319640"
						if={{"what":"@element.openOrderList.count","is":0}}
						tag="h3"
						className="w-auto h-auto valign-center position-relative mb-24px ff-title" >
						{t('orders.ordersOpenHeaderTitle1657095319640')}
					</Text>
					<Group
						id="ordersOpenHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Text
							id="ordersOpenHeaderTitle"
							if={{"what":"@element.openOrderList.count","more_than":0}}
							tag="h3"
							className="w-auto h-auto valign-center position-relative me-24px ff-title" >
							{t('orders.ordersOpenHeaderTitle')}
						</Text>
						<Button
							id="newPurchaseButton"
							onClick={newPurchaseButtonOnClick}
							variant="accent"
							className="w-auto h-auto border-radius-64px valign-center position-relative" >
							{t('orders.newPurchaseButton')}
						</Button>
					</Group>
					<List
						id="openOrderList"
						src="@firebase.firestore.orders"
						filter={{"what":"order_status","is":"open","and":{"what":"customer","is":"@cookie.userID"}}}
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="openOrderCard"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
							<Group
								id="openOrderCardHeader"
								direction="auto"
								className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
								<Group
									id="openOrderCreationGroup"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('orders.openOrderCreationTitle')}
									</Text>
									<Text
										id="openOrderCreationValue"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('orders.openOrderDeliveryTitle')}
									</Text>
									<Text
										id="openOrderDeliveryValue"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('orders.openOrderBilledTitle')}
									</Text>
									<Text
										id="openOrderBilledValue"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('orders.openOrderBilledValue')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('orders.openOrderLocationTitle')}
									</Text>
									<Text
										id="openOrderLocationValue"
										tag="small"
										src="location_name"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('orders.openOrderLocationValue')}
									</Text>
								</Group>
								<Group
									id="component1657044473884"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('orders.openOrderIdTitle')}
									</Text>
									<Text
										id="openOrderIdValue"
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('orders.openOrderIdValue')}
									</Text>
								</Group>
							</Group>
							<Group
								id="openOrderCardContent"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="openOrderCardCart"
									direction="vertical"
									className="min-w-300px w-fill h-auto position-relative" >
									<List
										id="openOrderCartList"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRowInput"
												direction="horizontal"
												className="w-auto h-auto position-relative me-16px" >
												<Icon
													id="openOrderMinusButton"
													onClick={openOrderMinusButtonOnClick}
													src="minus-circle-outline"
													color="var(--primary-dark)"
													className="w-auto h-auto valign-center position-relative"
												/>
												<Text
													id="openOrderCartListRowUnits1657500083029"
													className="w-40px h-auto position-relative ff-label text-center" >
													{t('orders.openOrderCartListRowUnits1657500083029')}
												</Text>
												<Icon
													id="openOrderPlusButton"
													onClick={openOrderPlusButtonOnClick}
													src="plus-circle-outline"
													color="var(--primary-dark)"
													className="w-auto h-auto valign-center position-relative"
												/>
											</Group>
											<Text
												id="openOrderCartListRowName"
												src="name"
												className="w-fill h-auto position-relative ff-label" >
												{t('orders.openOrderCartListRowName')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="openOrderCardActions"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="openOrderActionConfirm"
										onClick={openOrderActionConfirmOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
										{t('orders.openOrderActionConfirm')}
									</Text>
									<Text
										id="openOrderActionEdit"
										onClick={openOrderActionEditOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('orders.openOrderActionEdit')}
									</Text>
									<Text
										id="openOrderActionChangeDelivery"
										onClick={openOrderActionChangeDeliveryOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('orders.openOrderActionChangeDelivery')}
									</Text>
									<Text
										id="openOrderActionClone"
										onClick={openOrderActionCloneOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('orders.openOrderActionClone')}
									</Text>
									<Text
										id="openOrderActionDelete"
										onClick={openOrderActionDeleteOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('orders.openOrderActionDelete')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
			<Group
				id="ordersCurtain"
				if={{"what":"@property.showCurtain","is":true}}
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-absolute bg-darken" >
				<Group
					id="group1665441280668"
					direction="horizontal"
					className="w-auto min-h-100 fg-100 align-center position-relative" >
					<Lottie
						id="lottie1665441228585"
						src={anim_wait_white}
						className="w-96px h-auto align-center valign-center position-relative" />
				</Group>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
