import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Sales_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, List, Card, Image } from '../components';
import ic_xlsx from '../assets/iconset/ic_xlsx.svg';
import { useTranslation } from 'react-i18next';

export const Sales_shippingsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const shippingIconDownloadOnClick = (options) => {
		run([{"function":"browser","url":"@field.doc_url","target":"_padownload"}], options)
	}
	const shippingsWaybillUploadButtonOnClick = (options) => {
		run([{"function":"file","into":"@property.tempFile","onsuccess":[{"function":"set","what":"@firebase.storage.waybills\/uploaded_(@cookie.userID).xlsx","value":"@property.tempFile"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/importWaybill","params":{"url":"@firebase.storage.waybills\/uploaded_(@cookie.userID).xlsx"},"response":"json-api","into":"@database.response","method":"post","onerror":[{"function":"set","what":"@property.msg","value":"Ha habido un error al importar el archivo"},{"function":"popup","dialog":"error_dialog"}],"onsuccess":[{"function":"set","what":"@property.msg","value":"Archivo importado con \u00e9xito. Puedes completar el albar\u00e1n en Holded."},{"function":"popup","dialog":"error_dialog"}]}],"multiple":true,"type":"application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}], options)
	}
	const component1654860440298OnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('sales_shippings.title')}</title>
      </Helmet>
      <Sales_wrapperWrapper>
          
			<Group
				id="adminShippingsBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="adminShippingsWrapper"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="shippingsHeader"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Icon
							id="component1654860440298"
							onClick={component1654860440298OnClick}
							src="arrow-left"
							className="w-auto h-auto valign-center position-relative me-16px"
						/>
						<Text
							id="ordersHeaderTitle1654854195127"
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-antonregular" >
							{t('sales_shippings.ordersHeaderTitle1654854195127')}
						</Text>
						<Icon
							id="shippingsWaybillUploadButton"
							onClick={shippingsWaybillUploadButtonOnClick}
							src="upload"
							className="w-auto h-auto valign-center position-relative"
						/>
					</Group>
					<List
						id="adminShippingList"
						direction="vertical"
						src="@firebase.firestore.shippings"
						order={{"type":"desc","field":"created_at"}}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="adminShippingCard"
							className="w-100 h-auto position-relative m-4px" >
							<Card.Body>
									<Group
										id="adminShippingCardHeader"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Group
											id="adminShippingCardHeaderInfo"
											direction="horizontal"
											className="w-fill h-auto position-relative" >
											<Text
												id="adminShippingCardHeaderText"
												format={{"type":"date","year":"numeric","month":"long","day":"numeric"}}
												src="created_at"
												className="w-auto h-auto position-relative ff-librebaskervillebold" >
												{t('sales_shippings.adminShippingCardHeaderText')}
											</Text>
											<Text
												id="adminShippingCardHeaderStatus"
												className="w-auto h-auto position-relative ps-16px ff-librebaskervilleregular" >
												{t('sales_shippings.adminShippingCardHeaderStatus')}
											</Text>
										</Group>
										<Group
											id="orderStatusGroup1654854195127"
											direction="vertical"
											className="w-auto h-auto position-relative" >
											<Image
												id="shippingIconDownload"
												if={{"what":"@field.doc_url","is_not":null}}
												onClick={shippingIconDownloadOnClick}
												src={ic_xlsx}
												className="w-auto min-h-32px h-32px valign-center position-relative" />
										</Group>
									</Group>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </Sales_wrapperWrapper>
    </>
  );
};
