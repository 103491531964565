import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const A_newcustomersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const companyGotoLocationButton1664144915191OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":true},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}
	const userCompaniesHeader1664144915191OnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_newcustomers.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="customerAdminBody1664144771138"
				direction="auto"
				className="w-100 h-fill position-relative bg-admin_background p-32px" >
				<Group
					id="locationsArea1664144915191"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="userCompaniesArea1664144915191"
						direction="auto"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="locationsAreaHeader1664144915191"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Text
								id="companyLocationsTitle1664144915191"
								tag="b"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
								{t('a_newcustomers.companyLocationsTitle1664144915191')}
							</Text>
							<Group
								id="locationsListSearchBox1664144915191"
								direction="vertical"
								className="max-w-200px w-auto h-auto valign-center position-relative" >
							</Group>
						</Group>
					</Group>
					<Group
						id="userCompaniesHeader1664144915191"
						onClick={userCompaniesHeader1664144915191OnClick}
						direction="horizontal"
						className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
						<Text
							id="userCompanyListHeaderName1664144915191"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_newcustomers.userCompanyListHeaderName1664144915191')}
						</Text>
						<Text
							id="userCompanyListHeaderCif1664144915191"
							className="w-300px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_newcustomers.userCompanyListHeaderCif1664144915191')}
						</Text>
						<Text
							id="userListHeaderCompany1664144915191"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_newcustomers.userListHeaderCompany1664144915191')}
						</Text>
						<Text
							id="userListHeaderCompany1664144915191"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_newcustomers.userListHeaderCompany1664144915191')}
						</Text>
					</Group>
					<List
						id="newApplicationList"
						direction="vertical"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"sent"}}
						className="w-fill h-auto position-relative" >
						<Group
							id="salesCustomerName1664144915191"
							direction="horizontal"
							className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
							<Text
								id="userCompanyListName1664144915191"
								tag="small"
								src="sales_name"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-librebaskervillebold" >
								{t('a_newcustomers.userCompanyListName1664144915191')}
							</Text>
							<Text
								id="userCompanyListCif1664144915191"
								src="billing_city"
								className="w-300px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
								{t('a_newcustomers.userCompanyListCif1664144915191')}
							</Text>
							<Text
								id="userListCompany1664144915191"
								src="cif"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
								{t('a_newcustomers.userListCompany1664144915191')}
							</Text>
							<Text
								id="locationListHoldedID1664144915191"
								src="legal_name"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
								{t('a_newcustomers.locationListHoldedID1664144915191')}
							</Text>
							<Group
								id="userListRowSpacer1664144915191"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="userListRowActions1664144915191"
								direction="horizontal"
								className="w-auto h-auto position-relative" >
								<Icon
									id="companyGotoLocationButton1664144915191"
									onClick={companyGotoLocationButton1664144915191OnClick}
									src="arrow-right-bold"
									color="var(--gray)"
									className="w-auto h-auto position-relative ms-16px"
								/>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
