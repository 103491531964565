import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const HistoryView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const historyParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( historyParams, [])
	const historyOnLoaded = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/getOrders\n","params":{"userID":"@cookie.userID"},"into":"@database.orders","response":"json-api","method":"post"}], options)
	}
	useEffect(historyOnLoaded, [])
	const historyOrderActionDeleteOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.historyOrderList.selected"},{"function":"set","what":"@property.status","value":"@firebase.firestore.orders.(@cookie.orderID).order_status"},{"function":"popup","dialog":"order_delete","if":{"what":"@property.status","is":"confirmed","else":[{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"@property.status"},{"function":"set","what":"@property.error","value":"El pedido ya no se puede eliminar"},{"function":"popup","dialog":"error_dialog"}]}}], options)
	}
	const historyOrderActionCloneOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.historyOrderList.selected"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/cloneOrder","method":"post","response":"json-api","into":"@database.response","params":{"orderID":"@cookie.orderID"},"onerror":[{"function":"set","what":"@property.error","value":"No se ha podido duplicar el pedido. Int\u00e9ntalo m\u00e1s tarde."},{"function":"popup","dialog":"error_dialog"}],"onsuccess":[{"function":"set","what":"@cookie.orderID","value":"@database.response.orderID"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const historyOrderActionOpenOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.historyOrderList.selected"},{"function":"set","what":"@property.status","value":"@firebase.firestore.orders.(@cookie.orderID).order_status"},{"function":"do","what":[{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"},{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"open"},{"function":"goto","view":"orders"}],"if":{"what":"@property.status","is":"confirmed","else":[{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"@property.status"},{"function":"set","what":"@property.error","value":"El pedido ya no se puede reabrir, pues est\u00e1 siendo procesado."},{"function":"popup","dialog":"error_dialog"}]}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('history.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="historyWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="ordersHistory"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="ordersHistoryHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Text
							id="ordersHistoryHeaderTitle"
							if={{"what":"@element.historyOrderList.count","more_than":0}}
							tag="h3"
							className="w-auto h-auto valign-center position-relative me-24px ff-title" >
							{t('history.ordersHistoryHeaderTitle')}
						</Text>
						<Text
							id="ordersHistoryHeaderNoResults"
							if={{"what":"@element.historyOrderList.count","is":0}}
							tag="h3"
							className="w-auto h-auto valign-center position-relative ff-title" >
							{t('history.ordersHistoryHeaderNoResults')}
						</Text>
					</Group>
					<List
						id="historyOrderList"
						src="@database.orders"
						filter={{"what":"order_status","is":"confirmed","or":{"what":"order_status","is":"processed"}}}
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="historyOrderCard"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
							<Group
								id="historyOrderCardHeader"
								direction="auto"
								className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
								<Group
									id="openOrderCreationGroup1657396417895"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('history.openOrderCreationTitle1657396417895')}
									</Text>
									<Text
										id="openOrderCreationValue1657396417895"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup1657396417895"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('history.openOrderDeliveryTitle1657396417895')}
									</Text>
									<Text
										id="openOrderDeliveryValue1657396417895"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup1657396417895"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('history.openOrderBilledTitle1657396417895')}
									</Text>
									<Text
										id="openOrderBilledValue1657396417895"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('history.openOrderBilledValue1657396417895')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup1657396417895"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('history.openOrderLocationTitle1657396417895')}
									</Text>
									<Text
										id="openOrderLocationValue1657396417895"
										tag="small"
										src="location_name"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('history.openOrderLocationValue1657396417895')}
									</Text>
								</Group>
								<Group
									id="component1657396417895"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup1657396417895"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('history.openOrderIdTitle1657396417895')}
									</Text>
									<Text
										id="openOrderIdValue1657396417895"
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('history.openOrderIdValue1657396417895')}
									</Text>
								</Group>
							</Group>
							<Group
								id="historyOrderCardContent"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="historyOrderCardCart"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<List
										id="openOrderCartList1657396417895"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow1657396417895"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Text
												id="openOrderCartListRowUnits1657396417895"
												className="w-50px h-auto position-relative me-12px ff-label" >
												{t('history.openOrderCartListRowUnits1657396417895')}
											</Text>
											<Text
												id="openOrderCartListRowName1657396417895"
												src="name"
												className="w-fill h-auto position-relative ff-label" >
												{t('history.openOrderCartListRowName1657396417895')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="historyOrderCardActions"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="historyOrderActionOpen"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={historyOrderActionOpenOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
										{t('history.historyOrderActionOpen')}
									</Text>
									<Text
										id="historyOrderActionClone"
										onClick={historyOrderActionCloneOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('history.historyOrderActionClone')}
									</Text>
									<Text
										id="historyOrderActionDelete"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={historyOrderActionDeleteOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
										{t('history.historyOrderActionDelete')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
