import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Customer_accessView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const customer_accessParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( customer_accessParams, [])
	const customer_accessOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@firebase.firestore.customers.(@cookie.userID).current_company"},{"function":"set","what":"@cookie.companyName","value":"@firebase.firestore.companies.(@cookie.companyID).name"},{"function":"set","what":"@cookie.locationID","value":"@firebase.firestore.customers.(@cookie.userID).current_location"},{"function":"set","what":"@cookie.locationName","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).name"},{"function":"set","what":"@cookie.rate","value":"@firebase.firestore.companies.(@cookie.companyID).rate"},{"function":"set","what":"@cookie.n_companies","value":"@firebase.firestore.customers.(@firebase.user.id).n_companies"},{"function":"set","what":"@cookie.n_locations","value":"@firebase.firestore.customers.(@firebase.user.id).n_locations"},{"function":"set","what":"@cookie.status","value":"@firebase.firestore.customers.(@firebase.user.id).status"},{"function":"set","what":"@cookie.owner","value":"@firebase.firestore.companies.(@cookie.companyID).owner"},{"function":"set","what":"@cookie.relation","value":"@firebase.firestore.companies.(@cookie.companyID).users.(@firebase.user.id).relation"},{"function":"goto","view":"profile","if":{"what":"@cookie.status","is":"uncompanied"}},{"function":"goto","view":"customer_unverified","if":{"what":"@cookie.status","is":"must_be_accepted_by_admin"}},{"function":"goto","view":"customer_unverified_invitation","if":{"what":"@cookie.status","is":"must_be_accepted_by_company"}},{"function":"goto","view":"customer_application_form","if":{"what":"@cookie.status","is":"pending_form"}},{"function":"do","what":[{"function":"goto","view":"orders","if":{"what":"@cookie.status","is":"companied"}}],"if":{"what":"@cookie.status","is":"companied"}}], options)
	}
	useEffect(customer_accessOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('customer_access.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="customerAccessBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px" >
			</Group>
      </FullscreenWrapper>
    </>
  );
};
