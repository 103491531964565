import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const A_userView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const userCompaniesGoOnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@field.company"},{"function":"set","what":"@database.object","value":"@firebase.firestore.companies.(@cookie.companyID)"},{"function":"goto","view":"a_company"}], options)
	}
	const icon1664058578092OnClick = (options) => {
		run([{"function":"set","what":"@property.customer_company","value":"@element.customerCompanyList.selected"},{"function":"set","what":"@property.companyCIF","value":"@field.company"},{"function":"set","what":"@property.action","value":"delete_customer_company"},{"function":"popup","dialog":"confirmation"}], options)
	}
	const userCompaniesHeaderOnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const text1664058089052OnClick = (options) => {
		run([{"function":"popup","dialog":"a_select_company"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_user.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="userBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="userHeader"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="userTitle"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_user.userTitle')}
					</Text>
					<Group
						id="userListSearchBox1664057783855"
						direction="vertical"
						className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
					</Group>
				</Group>
				<Group
					id="userInfo"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="userInfo1664057950140"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="userTitle1664057950140"
							className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
							{t('a_user.userTitle1664057950140')}
						</Text>
						<Group
							id="userListSearchBox1664057950140"
							direction="vertical"
							className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
						</Group>
					</Group>
				</Group>
				<Group
					id="userCompaniesArea"
					direction="auto"
					className="w-100 h-auto position-relative mt-32px" >
					<Group
						id="userInfo1664058025689"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="userTitle1664058025689"
							className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
							{t('a_user.userTitle1664058025689')}
						</Text>
						<Group
							id="userListSearchBox1664058025689"
							direction="vertical"
							className="max-w-200px w-auto h-auto valign-center position-relative" >
							<Text
								id="text1664058089052"
								onClick={text1664058089052OnClick}
								className="w-auto h-auto border-radius-32px position-relative bg-accent px-12px py-6px ff-admin_regular text-center" >
								{t('a_user.text1664058089052')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="userCompaniesHeader"
					onClick={userCompaniesHeaderOnClick}
					direction="horizontal"
					className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
					<Text
						id="userCompanyListHeaderCif"
						className="w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_user.userCompanyListHeaderCif')}
					</Text>
					<Text
						id="userCompanyListHeaderName"
						className="w-400px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_user.userCompanyListHeaderName')}
					</Text>
					<Text
						id="userListHeaderCompany1664057783855"
						className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_user.userListHeaderCompany1664057783855')}
					</Text>
				</Group>
				<List
					id="customerCompanyList"
					direction="vertical"
					src="@firebase.firestore.customers_companies"
					filter={{"what":"customer","is":"@cookie.customerID"}}
					className="w-fill h-auto position-relative" >
					<Group
						id="salesCustomerName1664057783855"
						direction="horizontal"
						className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
						<Text
							id="userCompanyListCif"
							src="company"
							className="w-200px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
							{t('a_user.userCompanyListCif')}
						</Text>
						<Text
							id="userCompanyListName"
							src="name"
							className="w-400px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_user.userCompanyListName')}
						</Text>
						<Text
							id="userListCompany1664057783855"
							src="current_company"
							className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_user.userListCompany1664057783855')}
						</Text>
						<Group
							id="userListRowSpacer1664057783855"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Group
							id="userListRowActions1664057783855"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Icon
								id="icon1664058578092"
								onClick={icon1664058578092OnClick}
								src="trash-can"
								color="var(--gray)"
								className="w-auto h-auto position-relative"
							/>
							<Icon
								id="userCompaniesGo"
								onClick={userCompaniesGoOnClick}
								src="arrow-right-bold"
								color="var(--gray)"
								className="w-auto h-auto position-relative ms-16px"
							/>
						</Group>
					</Group>
				</List>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
