import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Order_acceptedDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const order_accepted_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"order_accepted"}], options)
	}

  return (
    
    
			<Modal id="order_accepted" size="md">
					<Modal.Body>
						<Text
							id="orderThanksText"
							tag="h1"
							className="w-100 h-auto position-relative text-primary ff-antonregular" >
							{t('order_accepted.orderThanksText')}
						</Text>
						<Text
							id="cartConfirmCompany1649014153777"
							className="w-100 h-auto position-relative mt-48px ff-librebaskervillebold" >
							{t('order_accepted.cartConfirmCompany1649014153777')}
						</Text>
						<Text
							id="orderDoneAddress"
							className="w-100 h-auto position-relative mt-24px ff-librebaskervillebold" >
							{t('order_accepted.orderDoneAddress')}
						</Text>
						<Text
							id="orderDoneCity"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_accepted.orderDoneCity')}
						</Text>
						<Text
							id="orderDoneProvince"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_accepted.orderDoneProvince')}
						</Text>
						<Text
							id="orderConfirmedText"
							src="@firebase.firestore.data.texts.order_done"
							className="w-100 h-auto position-relative mt-24px ff-librebaskervilleregular" >
							{t('order_accepted.orderConfirmedText')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="order_accepted_buttons_positive"
							onClick={order_accepted_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('order_accepted.order_accepted_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Order_acceptedDialog;
