import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Icon, Button, List } from '../components';
import { useTranslation } from 'react-i18next';

export const A_locationView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminOrderActionDelete1667213245045OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationConfirmedOrdersList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const adminOrderActionReopen1667213245045OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationConfirmedOrdersList.selected"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"}], options)
	}
	const openOrderActionConfirm1667213245045OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationConfirmedOrdersList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderActionEditDate1667213245045OnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@property.order","value":"@element.locationConfirmedOrdersList.value"},{"function":"set","what":"@cookie.userID","value":"@property.order.customer"},{"function":"set","what":"@cookie.orderID","value":"@element.locationConfirmedOrdersList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const adminOrderActionDelete1667212911468OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const adminOrderActionReopen1667212911468OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"}], options)
	}
	const openOrderActionConfirm1667212911468OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderActionEditDate1667212911468OnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@property.order","value":"@element.locationOpenOrdersList.value"},{"function":"set","what":"@cookie.userID","value":"@property.order.customer"},{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const openOrderActionEditOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"id":"popup1667402616907","function":"popup","dialog":"a_edit_order"}], options)
	}
	const openOrderActionAddProductOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.locationOpenOrdersList.selected"},{"id":"popup1667333131347","function":"popup","dialog":"a_add_product"}], options)
	}
	const newPurchaseButton1667213828207OnClick = (options) => {
		run([{"function":"popup","dialog":"admin_new_order"}], options)
	}
	const icon1667418603030OnClick = (options) => {
		run([{"id":"browser1667418661845","function":"browser","url":"https:\/\/app.holded.com\/contacts\/(@database.objectlocation.holded_id)","target":"_pinko_holded"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_location.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="userBody1664132533543"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="userHeader1664132533543"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="companyTitle1664132533543"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_location.companyTitle1664132533543')}
					</Text>
					<Group
						id="userListSearchBox1664132533543"
						direction="vertical"
						className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
					</Group>
				</Group>
				<Group
					id="companyInfo1664132533543"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="userInfo1664132533543"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
					</Group>
					<Group
						id="companyDataFormGroup1664132533543"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Group
							id="component1664132533543"
							direction="auto"
							className="w-100 h-auto position-relative mt-24px" >
							<Group
								id="locationInfoGroupHolded"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="locationInfoLabelHolded"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelHolded')}
								</Text>
								<Text
									id="locationInfoValueName1664132856086"
									src="@database.objectlocation.holded_id"
									className="w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValueName1664132856086')}
								</Text>
								<Icon
									id="icon1667418603030"
									onClick={icon1667418603030OnClick}
									src="open-in-new"
									className="w-auto h-auto position-relative ms-16px"
								/>
							</Group>
							<Group
								id="locationInfoGroupPinko"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelPinko"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelPinko')}
								</Text>
								<Text
									id="locationInfoValuePink"
									src="@cookie.locationID"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValuePink')}
								</Text>
							</Group>
							<Group
								id="locationInfoGroupName"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelName"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelName')}
								</Text>
								<Text
									id="locationInfoValueName"
									src="@database.objectlocation.name"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValueName')}
								</Text>
							</Group>
							<Group
								id="locationInfoAddress"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelAddress"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelAddress')}
								</Text>
								<Text
									id="locationInfoValueAddress"
									src="@database.objectlocation.address"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValueAddress')}
								</Text>
							</Group>
							<Group
								id="locationInfoCPGroup"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelCP"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelCP')}
								</Text>
								<Text
									id="locationInfoValueCP"
									src="@database.objectlocation.cp"
									className="min-w-150px w-fill h-auto position-relative ff-label" >
									{t('a_location.locationInfoValueCP')}
								</Text>
							</Group>
							<Group
								id="locationInfoGroupCity"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelCity"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelCity')}
								</Text>
								<Text
									id="locationInfoValueCity"
									src="@database.objectlocation.city"
									className="min-w-120px w-fill h-auto position-relative ff-label" >
									{t('a_location.locationInfoValueCity')}
								</Text>
							</Group>
							<Group
								id="locationInfoGroupProvince"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelProvince"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelProvince')}
								</Text>
								<Text
									id="locationInfoValueProvince"
									src="@database.objectlocation.province"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValueProvince')}
								</Text>
							</Group>
							<Group
								id="locationInfoGroupCountry"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationInfoLabelCountry"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoLabelCountry')}
								</Text>
								<Text
									id="locationInfoValueCountry"
									src="@database.objectlocation.country"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_location.locationInfoValueCountry')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="locationOrdersGroup"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Group
						id="ordersOpenHeader1667213828207"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Text
							id="companyTitle1667213836207"
							tag="h2"
							className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
							{t('a_location.companyTitle1667213836207')}
						</Text>
						<Button
							id="newPurchaseButton1667213828207"
							onClick={newPurchaseButton1667213828207OnClick}
							variant="accent"
							className="w-auto h-auto border-radius-64px valign-center position-relative" >
							{t('a_location.newPurchaseButton1667213828207')}
						</Button>
					</Group>
					<List
						id="locationOpenOrdersList"
						src="@firebase.firestore.orders"
						filter={{"what":"order_status","is":"open","and":{"what":"location_id","is":"@cookie.locationID"}}}
						className="w-100 h-auto position-relative" >
						<Group
							id="historyOrderCard1667212911468"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end position-relative bg-card_bg mb-24px" >
							<Group
								id="historyOrderCardHeader1667212911468"
								direction="auto"
								className="w-100 h-auto position-relative bg-primary-dark" >
								<Group
									id="openOrderCreationGroup1667212911468"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderCreationTitle1667212911468')}
									</Text>
									<Text
										id="openOrderCreationValue1667212911468"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup1667212911468"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderDeliveryTitle1667212911468')}
									</Text>
									<Text
										id="openOrderDeliveryValue1667212911468"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup1667212911468"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderBilledTitle1667212911468')}
									</Text>
									<Text
										id="openOrderBilledValue1667212911468"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_location.openOrderBilledValue1667212911468')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup1667212911468"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_location.openOrderLocationTitle1667212911468')}
									</Text>
									<Text
										id="openOrderLocationValue1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_location.openOrderLocationValue1667212911468')}
									</Text>
								</Group>
								<Group
									id="component1667212911468"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup1667212911468"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_location.openOrderIdTitle1667212911468')}
									</Text>
									<Text
										id="openOrderIdValue1667212911468"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-label" >
										{t('a_location.openOrderIdValue1667212911468')}
									</Text>
								</Group>
							</Group>
							<Group
								id="historyOrderCardContent1667212911468"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="historyOrderCardCart1667212911468"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<List
										id="locationOpenOrderCartList"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow1667212911468"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRow1667213152662"
												direction="horizontal"
												className="w-auto h-auto position-relative" >
												<Group
													id="openOrderCartListRowInput1667213152662"
													direction="horizontal"
													className="w-auto h-auto position-relative me-16px" >
													<Text
														id="openOrderCartListRowUnits1667213152662"
														className="w-40px h-auto position-relative ff-label text-center" >
														{t('a_location.openOrderCartListRowUnits1667213152662')}
													</Text>
												</Group>
											</Group>
											<Text
												id="openOrderCartListRowName1667212911468"
												src="name"
												className="w-fill h-auto position-relative ps-24px ff-label" >
												{t('a_location.openOrderCartListRowName1667212911468')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="locationOpenOrderCardActions"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="openOrderActionAddProduct"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionAddProductOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionAddProduct')}
									</Text>
									<Text
										id="openOrderActionEdit"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionEditOnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionEdit')}
									</Text>
									<Text
										id="openOrderActionEditDate1667212911468"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionEditDate1667212911468OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionEditDate1667212911468')}
									</Text>
									<Text
										id="openOrderActionConfirm1667212911468"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionConfirm1667212911468OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionConfirm1667212911468')}
									</Text>
									<Text
										id="adminOrderActionReopen1667212911468"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={adminOrderActionReopen1667212911468OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.adminOrderActionReopen1667212911468')}
									</Text>
									<Text
										id="adminOrderActionDelete1667212911468"
										if={{"what":"@field.order_status","is":"open","or":{"what":"@field.order_status","is":"confirmed"}}}
										onClick={adminOrderActionDelete1667212911468OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mb-8px text-center" >
										{t('a_location.adminOrderActionDelete1667212911468')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
				<Group
					id="locationOrdersGroup1667213245045"
					direction="vertical"
					className="w-100 h-auto position-relative mt-48px" >
					<Text
						id="companyTitle1667213245045"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_location.companyTitle1667213245045')}
					</Text>
					<List
						id="locationConfirmedOrdersList"
						src="@firebase.firestore.orders"
						filter={{"what":"order_status","is":"confirmed","and":{"what":"location_id","is":"@cookie.locationID"}}}
						className="w-100 h-auto position-relative" >
						<Group
							id="historyOrderCard1667213245045"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end position-relative bg-card_bg mb-24px" >
							<Group
								id="historyOrderCardHeader1667213245045"
								direction="auto"
								className="w-100 h-auto position-relative bg-primary-dark" >
								<Group
									id="openOrderCreationGroup1667213245045"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderCreationTitle1667213245045')}
									</Text>
									<Text
										id="openOrderCreationValue1667213245045"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup1667213245045"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderDeliveryTitle1667213245045')}
									</Text>
									<Text
										id="openOrderDeliveryValue1667213245045"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup1667213245045"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_location.openOrderBilledTitle1667213245045')}
									</Text>
									<Text
										id="openOrderBilledValue1667213245045"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_location.openOrderBilledValue1667213245045')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup1667213245045"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_location.openOrderLocationTitle1667213245045')}
									</Text>
									<Text
										id="openOrderLocationValue1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_location.openOrderLocationValue1667213245045')}
									</Text>
								</Group>
								<Group
									id="component1667213245045"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup1667213245045"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_location.openOrderIdTitle1667213245045')}
									</Text>
									<Text
										id="openOrderIdValue1667213245045"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-label" >
										{t('a_location.openOrderIdValue1667213245045')}
									</Text>
								</Group>
							</Group>
							<Group
								id="historyOrderCardContent1667213245045"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="historyOrderCardCart1667213245045"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<List
										id="openOrderCartList1667213245045"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow1667213245045"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRow1667213245045"
												direction="horizontal"
												className="w-auto h-auto position-relative" >
												<Group
													id="openOrderCartListRowInput1667213245045"
													direction="horizontal"
													className="w-auto h-auto position-relative me-16px" >
													<Text
														id="openOrderCartListRowUnits1667213245045"
														className="w-40px h-auto position-relative ff-label text-center" >
														{t('a_location.openOrderCartListRowUnits1667213245045')}
													</Text>
												</Group>
												<Text
													id="openOrderCartListRowName1667213245045"
													src="name"
													className="w-fill h-auto position-relative ff-label" >
													{t('a_location.openOrderCartListRowName1667213245045')}
												</Text>
											</Group>
											<Text
												id="openOrderCartListRowName1667213245045"
												src="name"
												className="w-fill h-auto position-relative ps-24px ff-label" >
												{t('a_location.openOrderCartListRowName1667213245045')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="historyOrderCardActions1667213245045"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="openOrderActionEditDate1667213245045"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionEditDate1667213245045OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionEditDate1667213245045')}
									</Text>
									<Text
										id="openOrderActionConfirm1667213245045"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionConfirm1667213245045OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.openOrderActionConfirm1667213245045')}
									</Text>
									<Text
										id="adminOrderActionReopen1667213245045"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={adminOrderActionReopen1667213245045OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_location.adminOrderActionReopen1667213245045')}
									</Text>
									<Text
										id="adminOrderActionDelete1667213245045"
										if={{"what":"@field.order_status","is":"open","or":{"what":"@field.order_status","is":"confirmed"}}}
										onClick={adminOrderActionDelete1667213245045OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mb-8px text-center" >
										{t('a_location.adminOrderActionDelete1667213245045')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
