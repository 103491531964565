import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Member_deleteDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const member_delete_buttons_positiveOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.companies.(@property.deletedCompanyID).users.(@property.memberID)"},{"function":"delete","what":"@firebase.firestore.customers.(@property.memberID).companies.(@cookie.companyID)"},{"function":"set","what":"@firebase.firestore.customers.(@property.memberID).n_companies","value":"@cookie.n_companies.-1"},{"function":"set","what":"@firebase.firestore.customers.(@property.memberID).current_company","value":null,"if":{"what":"@firebase.firestore.customers.(@property.memberID).current_company","is":"@cookie.companyID"}},{"function":"hide","dialog":"member_delete"}], options)
	}
	const member_delete_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"member_delete"}], options)
	}

  return (
    
    
			<Modal id="member_delete">
					<Modal.Body>
						<Text
							id="memberDeleteText"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('member_delete.memberDeleteText')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="member_delete_buttons_negative"
							onClick={member_delete_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('member_delete.member_delete_buttons_negative')}
						</Button>
						<Button
							id="member_delete_buttons_positive"
							onClick={member_delete_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('member_delete.member_delete_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Member_deleteDialog;
