import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Sales_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Lottie } from '../components';
import { useEffect } from 'react';
import lottie_loading from '../assets/animation/lottie_loading.json';
import { useTranslation } from 'react-i18next';

export const Sales_loading_cartView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const sales_loading_cartParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( sales_loading_cartParams, [])
	const sales_loading_cartOnLoaded = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/getOrderCart","into":"@database.order","method":"post","response":"json-api","params":{"orderID":"@cookie.salesLocationID"},"onsuccess":[{"function":"goto","view":"sales_products"}]}], options)
	}
	useEffect(sales_loading_cartOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('sales_loading_cart.title')}</title>
      </Helmet>
      <Sales_wrapperWrapper>
          
			<Group
				id="productsWrapper1649069289850"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="productsHeader1649069289850"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Text
						id="salesProductsTitle1649069289850"
						tag="h3"
						className="w-100 h-auto position-relative ff-librebaskervilleregular" >
						{t('sales_loading_cart.salesProductsTitle1649069289850')}
					</Text>
					<Text
						id="salesProductsTitle1649069357472"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('sales_loading_cart.salesProductsTitle1649069357472')}
					</Text>
				</Group>
				<Lottie
					id="component1649069381929"
					src={lottie_loading}
					className="w-250px h-auto align-center valign-center position-absolute" />
			</Group>
      </Sales_wrapperWrapper>
    </>
  );
};
