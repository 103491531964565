import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Order_deleteDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const order_delete_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"archived"},{"function":"delete","what":"@database.orders.(@cookie.orderID)"},{"function":"hide","dialog":"order_delete"}], options)
	}
	const order_delete_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"order_delete"}], options)
	}

  return (
    
    
			<Modal id="order_delete" centered>
					<Modal.Body>
						<Text
							id="cartCancelText1657101325426"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_delete.cartCancelText1657101325426')}
						</Text>
						<Text
							id="cartCancelText1657101325426"
							className="w-100 h-auto position-relative mt-16px ff-librebaskervilleregular" >
							{t('order_delete.cartCancelText1657101325426')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="order_delete_buttons_negative"
							onClick={order_delete_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('order_delete.order_delete_buttons_negative')}
						</Button>
						<Button
							id="order_delete_buttons_positive"
							onClick={order_delete_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('order_delete.order_delete_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Order_deleteDialog;
