import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List, Icon, Lottie } from '../components';
import { useEffect } from 'react';
import anim_wait_white from '../assets/animation/anim_wait_white.json';
import { useTranslation } from 'react-i18next';

export const A_companyView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const a_companyParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( a_companyParams, [])
	const a_companyOnLoaded = (options) => {
		run([{"function":"set","what":"@property.companyCIF","value":"@cookie.companyID"}], options)
	}
	useEffect(a_companyOnLoaded, [])
	const companyGotoLocationButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.locationID","value":"@element.companyLocationList.selected"},{"function":"set","what":"@database.objectlocation","value":"@element.companyLocationList.value"},{"function":"goto","view":"a_location"}], options)
	}
	const icon1664059298819OnClick = (options) => {
		run([{"function":"set","what":"@property.holded_id","value":null},{"function":"set","what":"@property.locationID","value":"@element.companyLocationList.selected"},{"function":"set","what":"@property.location","value":"@element.companyLocationList.value"},{"function":"set","what":"@property.holded_id","value":"@property.location.holded_id"},{"function":"set","what":"@property.action","value":"delete_location"},{"function":"popup","dialog":"confirmation"}], options)
	}
	const userCompaniesHeader1664059298819OnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const importHoldedLocationsButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.loading","value":true},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/syncHoldedCompany\n","params":{"companyID":"@cookie.companyID"},"method":"post","response":"json-api","into":"@database.response","onsuccess":[{"function":"set","what":"@property.loading","value":false}],"onerror":[{"function":"set","what":"@property.loading","value":false}]}], options)
	}
	const addLocationButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"holded_import_location"}], options)
	}
	const adminEditCompanyCIF1664059582587OnLostFocus = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@property.checkCIF","value":"@firebase.firestore.companies.(@property.companyCIF)"},{"function":"do","what":[{"function":"set","what":"@property.companyCIF","value":null},{"function":"set","what":"@property.msg","value":"company_already_exists"},{"function":"popup","dialog":"error_dialog"}],"if":{"what":"@property.checkCIF","is_not":null},"comments":"If this CIF already exists"},{"function":"do","what":[{"function":"add","what":{"cif":"@property.companyCIF","created_at":"@database.object.created_at","created_by":"@database.object.created_by","owner":"@database.object.owner"},"to":"@firebase.firestore.companies","key":"@property.companyCIF"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/changeLocationsCompany\n","method":"post","params":{"old":"@cookie.companyID","new":"@property.companyCIF"},"response":"json-api"},{"function":"delete","what":"@firebase.firestore.companies.(@cookie.companyID)"},{"function":"set","what":"@cookie.companyID","value":"@property.companyCIF"}],"if":{"what":"@property.checkCIF","is":null},"comments":"If DIDN'T exist"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_company.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="userBody1664059298819"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="userHeader1664059298819"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="companyTitle"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_company.companyTitle')}
					</Text>
					<Group
						id="userListSearchBox1664059298819"
						direction="vertical"
						className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
					</Group>
				</Group>
				<Group
					id="companyInfo"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Group
						id="userInfo1664059298819"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
					</Group>
					<Group
						id="companyDataFormGroup1664059582587"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Group
							id="locationDataFormHeader1664059582587"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Text
								id="companyCIFLabel"
								className="w-auto h-auto position-relative py-6px ff-admin_regular" >
								{t('a_company.companyCIFLabel')}
							</Text>
							<Input.Text
								id="adminEditCompanyCIF1664059582587"
								name="text"
								src="@property.companyCIF"
							autoComplete="off"
							onBlur={adminEditCompanyCIF1664059582587OnLostFocus}
								className="max-w-500px min-w-300px w-auto h-auto position-relative mx-16px mb-8px" />
							<Group
								id="component1664059582587"
								visible={false}
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
						</Group>
						<Group
							id="component1664059582587"
							direction="auto"
							className="w-100 h-auto position-relative mt-24px" >
							<Group
								id="component1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Text
									id="locationFormLabelName1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelName1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@database.object.name"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
							<Group
								id="locationFormInfoAddress1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationFormLabelAddress1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelAddress1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@database.object.address"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
							<Group
								id="locationFormInfoCP1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationFormLabelCP1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelCP1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@database.object.cp"
									className="min-w-150px w-fill h-auto position-relative ff-label" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
							<Group
								id="locationFormInfoCity1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationFormLabelCity1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelCity1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@database.object.city"
									className="min-w-120px w-fill h-auto position-relative ff-label" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
							<Group
								id="locationFormInfoProvince1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationFormLabelProvince1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelProvince1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@cookie.location.province"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
							<Group
								id="locationFormInfoCountry1664059582587"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-12px" >
								<Text
									id="locationFormLabelCountry1664059582587"
									className="min-w-150px w-auto h-auto position-relative ff-admin_regular" >
									{t('a_company.locationFormLabelCountry1664059582587')}
								</Text>
								<Text
									id="component1664059582587"
									src="@cookie.location.country"
									className="min-w-120px w-fill h-auto position-relative ff-admin_regular" >
									{t('a_company.component1664059582587')}
								</Text>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="locationsArea"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="userCompaniesArea1664059298819"
						direction="auto"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="locationsAreaHeader"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Text
								id="companyLocationsTitle"
								tag="b"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
								{t('a_company.companyLocationsTitle')}
							</Text>
							<Group
								id="locationsListSearchBox1664059298819"
								direction="horizontal"
								className="w-auto h-auto valign-center position-relative" >
								<Text
									id="addLocationButton"
									onClick={addLocationButtonOnClick}
									className="w-auto h-auto border-radius-32px position-relative bg-accent px-12px py-6px ff-admin_regular text-center" >
									{t('a_company.addLocationButton')}
								</Text>
								<Text
									id="importHoldedLocationsButton"
									onClick={importHoldedLocationsButtonOnClick}
									className="w-auto h-auto border-radius-32px position-relative bg-accent px-12px py-6px ms-16px ff-admin_regular text-center" >
									{t('a_company.importHoldedLocationsButton')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="userCompaniesHeader1664059298819"
						onClick={userCompaniesHeader1664059298819OnClick}
						direction="horizontal"
						className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
						<Text
							id="userCompanyListHeaderName1664059298819"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_company.userCompanyListHeaderName1664059298819')}
						</Text>
						<Text
							id="userCompanyListHeaderCif1664059298819"
							className="w-300px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_company.userCompanyListHeaderCif1664059298819')}
						</Text>
						<Text
							id="userListHeaderCompany1664059298819"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_company.userListHeaderCompany1664059298819')}
						</Text>
						<Text
							id="userListHeaderCompany1664131411568"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_company.userListHeaderCompany1664131411568')}
						</Text>
					</Group>
					<List
						id="companyLocationList"
						direction="vertical"
						src="@firebase.firestore.locations"
						filter={{"what":"company","is":"@cookie.companyID"}}
						className="w-fill h-auto position-relative" >
						<Group
							id="salesCustomerName1664059298819"
							direction="horizontal"
							className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
							<Text
								id="userCompanyListName1664059298819"
								tag="small"
								src="name"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-librebaskervillebold" >
								{t('a_company.userCompanyListName1664059298819')}
							</Text>
							<Text
								id="userCompanyListCif1664059298819"
								src="address"
								className="w-300px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
								{t('a_company.userCompanyListCif1664059298819')}
							</Text>
							<Text
								id="userListCompany1664059298819"
								src="city"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
								{t('a_company.userListCompany1664059298819')}
							</Text>
							<Text
								id="locationListHoldedID"
								src="holded_id"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
								{t('a_company.locationListHoldedID')}
							</Text>
							<Group
								id="userListRowSpacer1664059298819"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="userListRowActions1664059298819"
								direction="horizontal"
								className="w-auto h-auto position-relative" >
								<Icon
									id="icon1664059298819"
									onClick={icon1664059298819OnClick}
									src="trash-can"
									color="var(--gray)"
									className="w-auto h-auto position-relative"
								/>
								<Icon
									id="companyGotoLocationButton"
									onClick={companyGotoLocationButtonOnClick}
									src="arrow-right-bold"
									color="var(--gray)"
									className="w-auto h-auto position-relative ms-16px"
								/>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
			<Group
				id="aCompanyCurtain"
				if={{"what":"@property.loading","is":true}}
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-absolute bg-darken" >
				<Lottie
					id="companyProfileCurtainLottie1664205690659"
					src={anim_wait_white}
					className="w-114px h-auto align-center valign-center position-absolute" />
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
