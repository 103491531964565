// Custom imports:
import users from '../../assets/data/users.json';
import delivery from '../../assets/data/delivery.json';
import country from '../../assets/data/country.json';
import cart from '../../assets/cart.json';
import objectuser from '../../assets/data/objectuser.json';
import objectlocation from '../../assets/data/objectlocation.json';
import object from '../../assets/data/object.json';
import orders from '../../assets/data/orders.json';
import order from '../../assets/data/order.json';
import i18n from '../../assets/data/i18n.json';
import companies from '../../assets/data/companies.json';
import customers from '../../assets/data/customers.json';
import response from '../../assets/data/response.json';
import contact from '../../assets/data/contact.json';
import products from '../../assets/data/products.json';

export const IS_READONLY = {
  // Readonly databases:
	i18n: true,
};

export const DATABASES = {
  INITIAL_STATE: {
    database: {
      // Databases:
			users,
			delivery,
			country,
			cart,
			objectuser,
			objectlocation,
			object,
			orders,
			order,
			i18n,
			companies,
			customers,
			response,
			contact,
			products,
    },
  },

  SET: 'DATABASES_SET',
};

export const DatabasesReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case DATABASES.SET:
      draft.database = {
        ...draft.database,
        ...payload,
      };
      break;

    default:
      return draft;
  }
};
