import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Lottie, Text, Input, Button } from '../components';
import anim_wait from '../assets/animation/anim_wait.json';
import { useTranslation } from 'react-i18next';

const Admin_new_orderDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const component1667214913395OnClick = (options) => {
		run([{"function":"set","what":"@property.orderStep","value":"wait"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/newOrder","params":{"userID":"@cookie.customerID","companyID":"@cookie.companyID","locationID":"@cookie.locationID","minDate":"@property.deliveryDate"},"method":"post","into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@cookie.orderID","value":"@database.response.orderID"},{"function":"hide","dialog":"admin_new_order"}]}], options)
	}
	const admin_new_orderOnLoaded = (options) => {
		run([{"function":"set","what":"@property.orderStep","value":"wait"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/preOrder\n","params":{"userID":"@cookie.customerID"},"method":"post","into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@property.orderStep","value":"date"},{"function":"set","what":"@property.minDate","value":"@database.response.companies.(@cookie.companyID).locations.(@cookie.locationID).minDate","if":{"what":"@cookie.locationID","is_not":null,"and":{"what":"@cookie.companyID","is_not":null}}},{"function":"set","what":"@property.deliveryDate","value":"@property.minDate"}]}], options)
	}

  return (
    
    
			<Modal id="admin_new_order" centered size="md" onShow={admin_new_orderOnLoaded}>
					<Modal.Header>
						<Modal.Title>{t('admin_new_order.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="newOrder_wait1667214913395"
							if={{"what":"@property.orderStep","is":"wait"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Lottie
								id="component1667214913395"
								src={anim_wait}
								className="w-96px h-auto align-center valign-center position-absolute" />
						</Group>
						<Group
							id="newOrder_date1667214913395"
							visible={false}
							if={{"what":"@property.orderStep","is":"date"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="newOrder_date_content1667214913395"
								direction="vertical"
								className="w-100 h-fill position-relative scrollable" >
								<Text
									id="newOrder_date_label1667214913395"
									className="w-100 h-auto position-relative" >
									{t('admin_new_order.newOrder_date_label1667214913395')}
								</Text>
								<Text
									id="newOrder_date_label1667214913395"
									tag="small"
									className="w-100 h-auto position-relative text-primary-light" >
									{t('admin_new_order.newOrder_date_label1667214913395')}
								</Text>
								<Input.Date
									id="newOrder_date_input1667214913395"
									name="date"
									src="@property.deliveryDate"
	min="@property.minDate"
									className="w-auto h-auto position-relative mt-32px" />
							</Group>
							<Button
								id="component1667214913395"
								onClick={component1667214913395OnClick}
								variant="primary"
								className="w-auto h-auto align-end position-relative" >
								{t('admin_new_order.component1667214913395')}
							</Button>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default Admin_new_orderDialog;
