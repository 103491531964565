import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Company_application_cancelDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const company_application_cancel_buttons_positiveOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.customers.(@cookie.userID).companies.(@property.deletedCompany.cif)"},{"function":"delete","what":"@firebase.firestore.applications.(@property.deletedCompany.cif)"},{"function":"set","what":"@cookie.n_companies","value":"@firebase.firestore.customers.(@cookie.userID).n_companies"},{"function":"set","what":"@cookie.n_locations","value":"@firebase.firestore.customers.(@cookie.userID).n_locations"},{"function":"set","what":"@cookie.n_companies","value":"@cookie.n_companies.-1"},{"function":"set","what":"@cookie.n_locations","value":"@cookie.n_locations.-1"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).n_companies","value":"@cookie.n_companies"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).n_locations","value":"@cookie.n_locations"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).status","value":"uncompanied","if":{"what":"@cookie.n_companies","is":0}},{"function":"hide","dialog":"company_application_cancel"}], options)
	}
	const company_application_cancel_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"company_application_cancel"}], options)
	}

  return (
    
    
			<Modal id="company_application_cancel">
					<Modal.Body>
						<Text
							id="companyAppCancelText"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('company_application_cancel.companyAppCancelText')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="company_application_cancel_buttons_negative"
							onClick={company_application_cancel_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('company_application_cancel.company_application_cancel_buttons_negative')}
						</Button>
						<Button
							id="company_application_cancel_buttons_positive"
							onClick={company_application_cancel_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('company_application_cancel.company_application_cancel_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Company_application_cancelDialog;
