import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const Sales_infoView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const optDeliveryGroupOnClick = (options) => {
		run([{"function":"goto","view":"sales_shippings"}], options)
	}
	const optCurrentOrdersGroupOnClick = (options) => {
		run([{"function":"set","what":"@cookie.order_status","value":"confirmed"},{"function":"goto","view":"sales_orders"}], options)
	}
	const optOpenOrdersGroupOnClick = (options) => {
		run([{"function":"set","what":"@cookie.order_status","value":"open"},{"function":"goto","view":"sales_orders"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('sales_info.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="adminOrdersBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="adminOrdersBody"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="ordersHeader"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="ordersHeaderTitle"
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-antonregular" >
							{t('sales_info.ordersHeaderTitle')}
						</Text>
					</Group>
					<Group
						id="salesListsOptions"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="optOpenOrdersGroup"
							onClick={optOpenOrdersGroupOnClick}
							direction="horizontal"
							className="w-100 h-auto border-radius-15px position-relative bg-primary p-16px" >
							<Text
								id="optCurrentOrdersLabel1654853904121"
								className="w-fill h-auto position-relative ff-librebaskervilleregular" >
								{t('sales_info.optCurrentOrdersLabel1654853904121')}
							</Text>
							<Icon
								id="optCurrentOrdersGoto1654853904121"
								src="greater-than"
								color="var(--primary-dark)"
								className="w-auto h-auto position-relative"
							/>
						</Group>
						<Group
							id="optCurrentOrdersGroup"
							onClick={optCurrentOrdersGroupOnClick}
							direction="horizontal"
							className="w-100 h-auto border-radius-15px position-relative bg-primary p-16px mt-24px" >
							<Text
								id="optCurrentOrdersLabel"
								className="w-fill h-auto position-relative ff-librebaskervilleregular" >
								{t('sales_info.optCurrentOrdersLabel')}
							</Text>
							<Icon
								id="optCurrentOrdersGoto"
								src="greater-than"
								color="var(--primary-dark)"
								className="w-auto h-auto position-relative"
							/>
						</Group>
						<Group
							id="optDeliveryGroup"
							onClick={optDeliveryGroupOnClick}
							direction="horizontal"
							className="w-100 h-auto border-radius-15px position-relative bg-primary p-16px mt-24px" >
							<Text
								id="optCurrentOrdersLabel1654854118621"
								className="w-fill h-auto position-relative ff-librebaskervilleregular" >
								{t('sales_info.optCurrentOrdersLabel1654854118621')}
							</Text>
							<Icon
								id="optCurrentOrdersGoto1654854118622"
								src="greater-than"
								color="var(--primary-dark)"
								className="w-auto h-auto position-relative"
							/>
						</Group>
					</Group>
				</Group>
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
