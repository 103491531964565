import React from 'react';

// Custom imports:
import A_add_productDialog from './A_add_productDialog';
import A_delete_companyDialog from './A_delete_companyDialog';
import A_delete_customerDialog from './A_delete_customerDialog';
import A_edit_orderDialog from './A_edit_orderDialog';
import A_select_companyDialog from './A_select_companyDialog';
import Admin_new_company_reviewDialog from './Admin_new_company_reviewDialog';
import Admin_new_orderDialog from './Admin_new_orderDialog';
import Admin_validate_companyDialog from './Admin_validate_companyDialog';
import CartDialog from './CartDialog';
import Company_application_cancelDialog from './Company_application_cancelDialog';
import Company_leaveDialog from './Company_leaveDialog';
import ConfirmationDialog from './ConfirmationDialog';
import Customer_add_companyDialog from './Customer_add_companyDialog';
import Customer_add_locationDialog from './Customer_add_locationDialog';
import Customer_company_existsDialog from './Customer_company_existsDialog';
import Customer_edit_locationDialog from './Customer_edit_locationDialog';
import Customer_edit_orderDialog from './Customer_edit_orderDialog';
import Customer_must_companyDialog from './Customer_must_companyDialog';
import Customer_new_company_reviewDialog from './Customer_new_company_reviewDialog';
import Customer_new_orderDialog from './Customer_new_orderDialog';
import Error_dialogDialog from './Error_dialogDialog';
import Holded_import_locationDialog from './Holded_import_locationDialog';
import Input_dialogDialog from './Input_dialogDialog';
import Location_deleteDialog from './Location_deleteDialog';
import Main_dialogDialog from './Main_dialogDialog';
import Member_deleteDialog from './Member_deleteDialog';
import Msg_dialogDialog from './Msg_dialogDialog';
import Order_acceptedDialog from './Order_acceptedDialog';
import Order_cancelationDialog from './Order_cancelationDialog';
import Order_canceledDialog from './Order_canceledDialog';
import Order_confirmationDialog from './Order_confirmationDialog';
import Order_deleteDialog from './Order_deleteDialog';
import Order_minimumDialog from './Order_minimumDialog';
import Order_repeatDialog from './Order_repeatDialog';
import Product_addDialog from './Product_addDialog';
import Product_buyDialog from './Product_buyDialog';
import Product_deleteDialog from './Product_deleteDialog';
import Product_editDialog from './Product_editDialog';
import Product_shopDialog from './Product_shopDialog';
import Sales_order_confirmationDialog from './Sales_order_confirmationDialog';
import Toast_candidate_errorDialog from './Toast_candidate_errorDialog';
import Toast_candidate_verifiedDialog from './Toast_candidate_verifiedDialog';
import ToastContainer from 'react-bootstrap/ToastContainer';



export const Dialogs = () => {
  return (
    <>
      
			<A_add_productDialog />
			<A_delete_companyDialog />
			<A_delete_customerDialog />
			<A_edit_orderDialog />
			<A_select_companyDialog />
			<Admin_new_company_reviewDialog />
			<Admin_new_orderDialog />
			<Admin_validate_companyDialog />
			<CartDialog />
			<Company_application_cancelDialog />
			<Company_leaveDialog />
			<ConfirmationDialog />
			<Customer_add_companyDialog />
			<Customer_add_locationDialog />
			<Customer_company_existsDialog />
			<Customer_edit_locationDialog />
			<Customer_edit_orderDialog />
			<Customer_must_companyDialog />
			<Customer_new_company_reviewDialog />
			<Customer_new_orderDialog />
			<Error_dialogDialog />
			<Holded_import_locationDialog />
			<Input_dialogDialog />
			<Location_deleteDialog />
			<Main_dialogDialog />
			<Member_deleteDialog />
			<Msg_dialogDialog />
			<Order_acceptedDialog />
			<Order_cancelationDialog />
			<Order_canceledDialog />
			<Order_confirmationDialog />
			<Order_deleteDialog />
			<Order_minimumDialog />
			<Order_repeatDialog />
			<Product_addDialog />
			<Product_buyDialog />
			<Product_deleteDialog />
			<Product_editDialog />
			<Product_shopDialog />
			<Sales_order_confirmationDialog />
			<ToastContainer className="p-3 position-fixed" position="bottom-end">
				<Toast_candidate_errorDialog />
				<Toast_candidate_verifiedDialog />
			</ToastContainer>
      
    </>
  );
};

export default Dialogs;
