import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input, Button } from '../components';
import { useEffect } from 'react';
import retrato from '../assets/logos/retrato.png';
import { useTranslation } from 'react-i18next';

export const LoginView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const loginParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( loginParams, [])
	const loginOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.projectID","value":"customers-8d060"},{"function":"set","what":"@cookie.lang","value":"es"}], options)
	}
	useEffect(loginOnLoaded, [])
	const gotoSignupOnClick = (options) => {
		run([{"function":"goto","view":"signup"}], options)
	}
	const loginButtonOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","email":"@property.email","pass":"@property.password","onvalid":[{"function":"set","what":"@cookie.type","value":"@firebase.firestore.users.(@firebase.user.id).type"},{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"goto","view":"access"}],"oninvalid":[{"function":"set","what":"@property.error","value":"Los datos introducidos no son correctos"},{"function":"popup","dialog":"error_dialog"}]}], options)
	}
	const component1646385145914OnClick = (options) => {
		run([{"function":"goto","view":"recover_password"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('login.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="loginWrapper"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative bg-white" >
				<Group
					id="loginHeader"
					direction="vertical"
					className="w-100 min-h-300px h-300px align-start position-relative bg-pink" >
					<Image
						id="loginLogo"
						src={retrato}
						className="w-auto min-h-300px h-300px align-center valign-end position-absolute-bottom mb--120px" />
				</Group>
				<Group
					id="loginBody"
					direction="vertical"
					className="w-100 h-auto align-start valign-center position-relative px-32px pt-150px pb-32px" >
					<Text
						id="loginTitle"
						className="w-100 h-auto position-relative my-32px f-54px ff-antonregular text-center" >
						{t('login.loginTitle')}
					</Text>
					<Group
						id="emailWrapper"
						direction="vertical"
						className="w-250px h-auto align-center position-relative" >
						<Text
							id="emailLabel"
							className="w-100 h-auto position-relative ff-antonregular" >
							{t('login.emailLabel')}
						</Text>
						<Input.Email
							id="emailInput"
							name="text"
							src="@property.email"
						placeholder={t('login.emailInput.hint')}
						autoComplete="off"
							className="w-100 h-auto align-center position-relative bg-pink" />
					</Group>
					<Group
						id="passwordWrapper"
						direction="vertical"
						className="w-250px h-auto align-center position-relative mt-16px" >
						<Text
							id="passwordLabel"
							className="w-100 h-auto position-relative ff-antonregular" >
							{t('login.passwordLabel')}
						</Text>
						<Input.Password
							id="passwordInput"
							name="text"
							src="@property.password"
						placeholder={t('login.passwordInput.hint')}
						autoComplete="off"
							className="w-100 h-auto align-center position-relative bg-pink" />
						<Text
							id="component1646385145914"
							onClick={component1646385145914OnClick}
							className="w-auto h-auto align-end position-relative text-purple" >
							{t('login.component1646385145914')}
						</Text>
					</Group>
					<Button
						id="loginButton"
						onClick={loginButtonOnClick}
						variant="text-on-accent"
						className="w-250px h-auto border-radius-100px align-center position-relative bg-accent mt-48px" >
						{t('login.loginButton')}
					</Button>
					<Text
						id="gotoSignup"
						onClick={gotoSignupOnClick}
						className="w-auto h-auto border-radius-100px align-center position-relative px-72px py-12px mt-8px text-primary-dark" >
						{t('login.gotoSignup')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
