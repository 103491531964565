import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List, Card, Image, Button } from '../components';
import { useEffect } from 'react';
import avellana from '../assets/helados/avellana.png';
import { useTranslation } from 'react-i18next';

export const Customer_productsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const customer_productsParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( customer_productsParams, [])
	const customer_productsOnLoaded = (options) => {
		run([{"function":"goto","if":{"what":"@firebase.firestore.customers.(@cookie.userID).status","is":"uncompanied"},"view":"customer_unverified"},{"function":"set","what":"@property.size","value":480,"if":{"what":"@property.size","is":null}},{"function":"popup","dialog":"order_accepted","if":{"what":"@property.sayThanks","is":true}},{"function":"set","what":"@property.sayThanks","value":false},{"function":"popup","dialog":"order_canceled","if":{"what":"@cookie.orderCanceled","is":true}},{"function":"set","what":"@cookie.orderCanceled","value":false}], options)
	}
	useEffect(customer_productsOnLoaded, [])
	const reviewButtonOnClick = (options) => {
		run([{"function":"goto","view":"customer_cart"}], options)
	}
	const productAddButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.product","value":"@element.productsList.value"},{"function":"set","what":"@cookie.productID","value":"@element.productsList.selected"},{"function":"set","what":"@cookie.shopButtons","value":"shop"},{"function":"set","what":"@property.itemUnits","value":"@firebase.firestore.orders.(@cookie.locationID).cart.(@cookie.productID).units","if":{"what":"@firebase.firestore.orders.(@cookie.locationID).cart.(@cookie.productID).added","else":[{"function":"set","what":"@property.itemUnits","value":0}],"is":true}},{"function":"set","what":"@property.itemUnits","value":"@property.itemUnits.+1"},{"function":"add","what":{"units":"@property.itemUnits","name":"@cookie.product.name","description":"@cookie.product.description","id":"@cookie.productID","added":true,"image":"@cookie.product.image","sku":"@cookie.product.sku"},"to":"@firebase.firestore.orders.(@cookie.locationID).cart","key":"@cookie.productID"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).company","value":"@cookie.companyID"},{"function":"popup","dialog":"product_buy"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('customer_products.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="productsWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="productsHeader"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Text
						id="productsTitle"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('customer_products.productsTitle')}
					</Text>
				</Group>
				<Input.Select
					id="sizeFilter"
					name="select"
					src="@property.size"
					dataSrc="@firebase.firestore.data.sizes.values"
					mapSrc={{"label":"label","value":"value"}}
					order={{"type":"asc","field":"position"}}
					className="w-100px h-auto position-relative" >
				</Input.Select>
				<List
					id="productsList"
					direction="horizontal"
					src="@firebase.firestore.products"
					filter={{"what":"size","is":"@property.size","and":{"what":"available","is":true}}}
					order={{"type":"asc","field":"name"}}
					columns={"auto"}
					className="w-100 h-auto position-relative mt-16px" >
					<Card
						id="productCard"
						className="max-w-400px min-w-300px w-fill h-auto position-relative m-4px" >
						<Card.Body>
								<Group
									id="productCardContent"
									direction="vertical"
									className="w-100 min-h-170px h-170px position-relative pb-24px scrollable" >
									<Image
										id="productImage"
										fallback={avellana}
										src="image"
										className="w-96px h-auto align-center position-relative" />
									<Text
										id="productName"
										tag="b"
										src="name"
										className="w-100 h-auto position-relative text-center" >
										{t('customer_products.productName')}
									</Text>
								</Group>
								<Button
									id="productAddButton"
									onClick={productAddButtonOnClick}
									variant="green"
									className="w-100 h-auto border-radius-32px align-center position-relative mt-16px" >
									{t('customer_products.productAddButton')}
								</Button>
						</Card.Body>
					</Card>
				</List>
			</Group>
			<Group
				id="productsFooter"
				direction="vertical"
				className="w-100 h-auto position-relative bg-white p-16px" >
				<Button
					id="reviewButton"
					onClick={reviewButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative" >
					{t('customer_products.reviewButton')}
				</Button>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
