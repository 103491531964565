import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, Icon, List, Card } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const SalesView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const salesParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( salesParams, [])
	const salesOnLoaded = (options) => {
		run([{"function":"goto","view":"customer_access","if":{"what":"@cookie.type","is":"customer","else":[{"function":"clone","what":"@firebase.firestore.companies","into":"@database.companies","order":{"type":"asc","field":"name"}}]}}], options)
	}
	useEffect(salesOnLoaded, [])
	const customerListTitle1661553341397OnClick = (options) => {
		run([{"function":"goto","view":"admin_locations_errors"}], options)
	}
	const salesCustomerCardOnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@element.companyList.selected"},{"function":"set","what":"@cookie.company","value":"@element.companyList.value"},{"function":"goto","view":"admin_company_profile"}], options)
	}
	const customerListSearchInputOnChanged = (options) => {
		run([{"function":"set","what":"@property.keywordlower","value":"@property.keyword.lower"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('sales.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="salesWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="salesBody"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="customerListHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Text
							id="customerListTitle"
							tag="h2"
							className="min-w-300px w-fill h-auto position-relative py-6px ff-antonregular" >
							{t('sales.customerListTitle')}
						</Text>
						<Group
							id="customerListSearchBox"
							direction="vertical"
							className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
							<Input.Text
								id="customerListSearchInput"
								name="text"
								src="@property.keyword"
							autoComplete="off"
							onChange={customerListSearchInputOnChanged}
								className="w-auto h-auto border-radius-5px position-relative" />
							<Icon
								id="customerListSearchIcon"
								src="magnify"
								color="var(--secondary-text)"
								className="w-auto h-auto align-end valign-center position-absolute p-4px"
							/>
						</Group>
					</Group>
					<List
						id="companyList"
						direction="auto"
						src="@database.companies"
						filter={{"what":"keywords","includes":"@property.keywordlower"}}
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="salesCustomerCard"
							onClick={salesCustomerCardOnClick}
							className="min-w-300px w-fill h-auto border-radius-10px position-relative m-4px" >
							<Card.Body>
									<Group
										id="salesCustomerName"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="salesCustomerNameLabel"
											src="name"
											className="w-fill h-auto position-relative text-primary-text ff-librebaskervillebold" >
											{t('sales.salesCustomerNameLabel')}
										</Text>
										<Text
											id="salesCustomerDescriptionLabel"
											tag="small"
											src="cif"
											className="w-fill h-auto position-relative text-secondary-text ff-librebaskervillebold" >
											{t('sales.salesCustomerDescriptionLabel')}
										</Text>
									</Group>
							</Card.Body>
						</Card>
					</List>
					<Group
						id="customerErrorLinks"
						direction="auto"
						className="w-100 h-auto position-relative mt-24px" >
						<Text
							id="customerListTitle1661553341397"
							onClick={customerListTitle1661553341397OnClick}
							className="min-w-300px w-auto h-auto position-relative py-6px text-accent ff-label" >
							{t('sales.customerListTitle1661553341397')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
