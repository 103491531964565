import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const A_delete_customerDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const a_delete_customer_buttons_positiveOnClick = (options) => {
		run([{"function":"delete","what":"@database.users.(@cookie.customerID)"},{"function":"delete","what":"@firebase.firestore.customers.(@cookie.customerID)"},{"function":"delete","what":"@firebase.firestore.users.(@cookie.customerID)"},{"function":"hide","dialog":"a_delete_customer"}], options)
	}
	const a_delete_customer_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"a_delete_customer"}], options)
	}

  return (
    
    
			<Modal id="a_delete_customer">
					<Modal.Header>
						<Modal.Title>{t('a_delete_customer.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1664139412719"
							className="w-100 h-auto position-relative" >
							{t('a_delete_customer.text1664139412719')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="a_delete_customer_buttons_negative"
							onClick={a_delete_customer_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('a_delete_customer.a_delete_customer_buttons_negative')}
						</Button>
						<Button
							id="a_delete_customer_buttons_positive"
							onClick={a_delete_customer_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('a_delete_customer.a_delete_customer_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default A_delete_customerDialog;
