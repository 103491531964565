import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Holded_import_locationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const holded_import_location_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@property.loading","value":true},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/syncHoldedLocation","params":{"companyID":"@cookie.companyID","contactId":"@property.contactID"},"method":"post","response":"json-api","into":"@database.response","onsuccess":[{"function":"set","what":"@property.loading","value":false}],"onerror":[{"function":"set","what":"@property.loading","value":false}]},{"function":"hide","dialog":"holded_import_location"}], options)
	}
	const holded_import_location_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"holded_import_location"}], options)
	}

  return (
    
    
			<Modal id="holded_import_location">
					<Modal.Body>
						<Text
							id="component1660210149835"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('holded_import_location.component1660210149835')}
						</Text>
						<Input.Text
							id="locationHoldedID"
							name="text"
							src="@property.contactID"
						autoComplete="off"
							className="w-auto h-auto position-relative mt-16px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="holded_import_location_buttons_negative"
							onClick={holded_import_location_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('holded_import_location.holded_import_location_buttons_negative')}
						</Button>
						<Button
							id="holded_import_location_buttons_positive"
							onClick={holded_import_location_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('holded_import_location.holded_import_location_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Holded_import_locationDialog;
