import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Customer_add_locationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const customer_add_location_buttons_positiveOnClick = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-(@cookie.projectID).cloudfunctions.net\/applyLocation","method":"post","params":{"userID":"@firebase.user.id","cif":"@cookie.companyID","comments":"@property.applicationComments","name":"@property.applicationName","street":"@property.applicationStreet","street_number":"@property.applicationStreetNumber","city":"@property.applicationCity","cp":"@property.applicationCP"},"into":"@property.response","response":"json-api","onsuccess":[]},{"function":"hide","dialog":"customer_add_location"}], options)
	}
	const customer_add_location_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"customer_add_location"}], options)
	}

  return (
    
    
			<Modal id="customer_add_location" size="lg">
					<Modal.Body>
						<Group
							id="component1647388522947"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="addCompanyInstructions1647388522947"
								className="w-100 h-auto position-relative ff-librebaskervillebold" >
								{t('customer_add_location.addCompanyInstructions1647388522947')}
							</Text>
							<Text
								id="addCompanyInstructions1647388522947"
								tag="small"
								className="w-100 h-auto position-relative text-gray ff-librebaskervilleregular" >
								{t('customer_add_location.addCompanyInstructions1647388522947')}
							</Text>
							<Input.Text
								id="addCompanyApplicationName1647388522947"
								label={t('customer_add_location.addCompanyApplicationName1647388522947.label')}
								name="text"
								src="@property.applicationName"
							placeholder={t('customer_add_location.addCompanyApplicationName1647388522947.hint')}
							autoComplete="off"
								className="w-100 h-auto position-relative mt-16px" />
							<Group
								id="addCompanyAddress1647388522947"
								direction="horizontal"
								className="w-100 h-auto position-relative mt-16px" >
								<Input.Text
									id="addCompanyApplicationStreet1647388522947"
									label={t('customer_add_location.addCompanyApplicationStreet1647388522947.label')}
									name="text"
									src="@property.applicationStreet"
								autoComplete="off"
									className="min-w-500px w-fill h-auto position-relative" />
								<Input.Text
									id="addCompanyApplicationStreetNumber1647388522947"
									label={t('customer_add_location.addCompanyApplicationStreetNumber1647388522947.label')}
									name="text"
									src="@property.applicationStreetNumber"
								autoComplete="off"
									className="w-fill h-auto position-relative ms-16px" />
							</Group>
							<Group
								id="addCompanyCityCP1647388522947"
								direction="horizontal"
								className="w-fill h-auto position-relative mt-16px" >
								<Input.Text
									id="addCompanyApplicationCP1647388522947"
									label={t('customer_add_location.addCompanyApplicationCP1647388522947.label')}
									name="text"
									src="@property.applicationCP"
								autoComplete="off"
									className="max-w-150px w-fill h-auto position-relative" />
								<Input.Text
									id="addCompanyApplicationCity1647388522947"
									label={t('customer_add_location.addCompanyApplicationCity1647388522947.label')}
									name="text"
									src="@property.applicationCity"
								autoComplete="off"
									className="w-fill h-auto position-relative ms-16px" />
							</Group>
							<Input.TextArea
								id="addCompanyApplicationComments1647388522947"
								label={t('customer_add_location.addCompanyApplicationComments1647388522947.label')}
								name="text"
								src="@property.applicationComments"
							placeholder={t('customer_add_location.addCompanyApplicationComments1647388522947.hint')}
							autoComplete="off"
								className="min-w-600px w-100 h-auto position-relative me-16px mt-16px" />
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="customer_add_location_buttons_negative"
							onClick={customer_add_location_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('customer_add_location.customer_add_location_buttons_negative')}
						</Button>
						<Button
							id="customer_add_location_buttons_positive"
							onClick={customer_add_location_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('customer_add_location.customer_add_location_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Customer_add_locationDialog;
