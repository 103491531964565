import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Text, Input, Icon, List } from '../components';
import { useTranslation } from 'react-i18next';

export const A_select_companyDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const salesCustomerName1664095492198OnClick = (options) => {
		run([{"function":"set","what":"@property.companyCIF","value":"@element.companyListSelector.value"},{"function":"add","what":{"company":"@element.companyListSelector.selected","relation":"owner","customer":"@cookie.customerID","status":"verified","name":"@property.companyCIF.name"},"to":"@firebase.firestore.customers_companies"},{"function":"hide","dialog":"a_select_company"}], options)
	}
	const companyListSearchInput1664095650325OnChanged = (options) => {
		run([{"function":"set","what":"@property.keywordlower","value":"@property.keyword.lower"}], options)
	}
	const a_select_companyOnLoaded = (options) => {
		run([{"function":"clone","what":"@firebase.firestore.companies","into":"@database.companies"},{"function":"set","what":"@property.keyword","value":null}], options)
	}

  return (
    
    
			<Offcanvas id="a_select_company" placement="end" scrollable onShow={a_select_companyOnLoaded}>
					<Offcanvas.Body>
						<Group
							id="group1664095489355"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="selectCompanyInstructions"
								className="w-100 h-auto position-relative" >
								{t('a_select_company.selectCompanyInstructions')}
							</Text>
							<Group
								id="companyListSearchBox1664095650325"
								direction="vertical"
								className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
								<Input.Text
									id="companyListSearchInput1664095650325"
									name="text"
									src="@property.keyword"
								autoComplete="off"
								onChange={companyListSearchInput1664095650325OnChanged}
									className="w-auto h-auto border-radius-5px position-relative" />
								<Icon
									id="companyListSearchIcon1664095650325"
									src="magnify"
									color="var(--secondary-text)"
									className="w-auto h-auto align-end valign-center position-absolute p-4px"
								/>
							</Group>
							<List
								id="companyListSelector"
								direction="vertical"
								src="@database.companies"
								filter={{"what":"keywords","includes":"@property.keywordlower"}}
								order={{"type":"asc","field":"name"}}
								className="w-100 h-auto position-relative" >
								<Group
									id="salesCustomerName1664095492198"
									onClick={salesCustomerName1664095492198OnClick}
									direction="horizontal"
									className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
									<Text
										id="userListCompany1664095492198"
										tag="small"
										className="w-100 h-auto valign-center position-relative py-8px text-secondary-text ff-admin_regular" >
										{t('a_select_company.userListCompany1664095492198')}
									</Text>
								</Group>
							</List>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default A_select_companyDialog;
