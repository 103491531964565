import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Order_cancelationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const order_cancelation_buttons_positiveOnClick = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/onOrderDeleted","method":"post","params":{"orderID":"@cookie.locationID"},"response":"json-api","onsuccess":[{"function":"delete","what":"@firebase.firestore.orders.(@cookie.locationID)"},{"function":"set","what":"@cookie.orderStatus","value":"open"},{"function":"popup","dialog":"order_canceled"},{"function":"set","what":"@cookie.orderCanceled","value":true},{"function":"goto","view":"customer_products"}],"if":{"what":"@property.flag","is":"NeverDoThis"}},{"function":"hide","dialog":"order_cancelation"}], options)
	}
	const order_cancelation_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"order_cancelation"}], options)
	}

  return (
    
    
			<Modal id="order_cancelation" centered>
					<Modal.Body>
						<Text
							id="cartCancelText1"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_cancelation.cartCancelText1')}
						</Text>
						<Text
							id="cartCancelText2"
							className="w-100 h-auto position-relative mt-16px ff-librebaskervilleregular" >
							{t('order_cancelation.cartCancelText2')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="order_cancelation_buttons_negative"
							onClick={order_cancelation_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('order_cancelation.order_cancelation_buttons_negative')}
						</Button>
						<Button
							id="order_cancelation_buttons_positive"
							onClick={order_cancelation_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('order_cancelation.order_cancelation_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Order_cancelationDialog;
