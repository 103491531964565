import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const Admin_new_company_reviewDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const newCompanyButtonAcceptOnClick = (options) => {
		run([{"function":"hide","dialog":"admin_new_company_review"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/addContactToHolded","into":"@database.response","method":"post","response":"json-api","params":{"applicationID":"@cookie.applicationID"},"onsuccess":[{"function":"set","what":"@property.error","value":"Cliente a\u00f1adido correctamente","if":{"what":"@database.response.status","is":"complete","else":[{"function":"set","what":"@property.error","value":"Se ha producido alg\u00fan error al intentar a\u00f1adir este cliente"}]}},{"function":"popup","dialog":"error_dialog"}],"onerror":[{"function":"set","what":"@property.error","value":"Se ha producido alg\u00fan error al intentar a\u00f1adir este cliente"},{"function":"popup","dialog":"error_dialog"}]}], options)
	}

  return (
    
    
			<Offcanvas id="admin_new_company_review" placement="end">
					<Offcanvas.Body>
						<Group
							id="reviewNewCompanyBody1652090751273"
							direction="vertical"
							className="w-100 h-auto position-relative scrollable" >
							<Text
								id="newCompanyInstructions1652090751273"
								className="w-100 h-auto position-relative mb-16px text-primary ff-librebaskervillebold" >
								{t('admin_new_company_review.newCompanyInstructions1652090751273')}
							</Text>
							<Group
								id="newCompanyNamingGroup1652090751273"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyNamingLabel1652090751273"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyNamingLabel1652090751273')}
								</Text>
								<Group
									id="newCompanyLegalName1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyLegalNameLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyLegalNameLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyLegalNameValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).legal_name"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyLegalNameValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanySalesName1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanySalesNameLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanySalesNameLabel1652090751273')}
									</Text>
									<Text
										id="newCompanySalesNameValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).sales_name"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanySalesNameValue1652090751273')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyContactGroup1652090751273"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyContactLabel1652090751273"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyContactLabel1652090751273')}
								</Text>
								<Group
									id="newCompanyContactName1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactNameLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactNameLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactNameValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).contact_name"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactNameValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactNIF1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactNIFLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactNIFLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactNIFValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).contact_nif"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactNIFValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactEmail1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactEmailLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactEmailLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactEmailValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).contact_email"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactEmailValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactPhone1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactPhoneLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactPhoneLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactPhoneValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).phone"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactPhoneValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactMobile1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactMobileLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactMobileLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactMobileValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).mobile"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactMobileValue1652090751273')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyBillingGroup1652090751273"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyBillingLabel1652090751273"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyBillingLabel1652090751273')}
								</Text>
								<Group
									id="newCompanyContacBillingAddress1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingAddressLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingAddressLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingAddressValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_adress"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingAddressValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCity1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCityLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingCityLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingCityValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_city"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingCityValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCP1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCPLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingCPLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingCPValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_cp"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingCPValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingProvince1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingProvinceLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingProvinceLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingProvineValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_province"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingProvineValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCountry1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCountryLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingCountryLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingCountryValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_country"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingCountryValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacIban1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactIbanLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactIbanLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingIbanValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_iban"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingIbanValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacSwift1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactSwiftLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactSwiftLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingSwiftValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_swift"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingSwiftValue1652090751273')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingRef1652090751273"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingRefLabel1652090751273"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_new_company_review.newCompanyContactBillingRefLabel1652090751273')}
									</Text>
									<Text
										id="newCompanyContactBillingRefValue1652090751273"
										src="@firebase.firestore.new_customers.(@cookie.applicationID).billing_ref"
										className="w-fill h-auto position-relative ff-librebaskervillebold" >
										{t('admin_new_company_review.newCompanyContactBillingRefValue1652090751273')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyDeliveryGroup1652090751273"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyDelivery1652090751273"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyDelivery1652090751273')}
								</Text>
								<Group
									id="newCompanyDeliveryGroup1652090751273"
									direction="vertical"
									className="w-100 h-auto border-radius-5px position-relative bg-light p-8px mb-8px" >
									<Group
										id="newCompanyDeliveryName1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryNameLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryNameLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryNameValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_name"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryNameValue1652090751273')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCity1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCityLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryCityLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryCityValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_city"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryCityValue1652090751273')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCP1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCPLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryCPLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryCPValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_cp"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryCPValue1652090751273')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryProvince1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryProvinceLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryProvinceLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryProvinceValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_province"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryProvinceValue1652090751273')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCountry1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCountryLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryCountryLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryCountryValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_country"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryCountryValue1652090751273')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryComments1652090751273"
										direction="vertical"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCommentsLabel1652090751273"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('admin_new_company_review.newCompanyDeliveryCommentsLabel1652090751273')}
										</Text>
										<Text
											id="newCompanyDeliveryCommentsValue1652090751273"
											src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery_comments"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('admin_new_company_review.newCompanyDeliveryCommentsValue1652090751273')}
										</Text>
									</Group>
								</Group>
								<List
									id="newCompanyDeliveryList1652090751273"
									src="@firebase.firestore.new_customers.(@cookie.applicationID).delivery"
									className="w-100 h-auto position-relative" >
									<Group
										id="newCompanyDeliveryCard1652090751273"
										direction="vertical"
										className="w-100 h-auto border-radius-5px position-relative bg-light p-8px mb-8px" >
										<Group
											id="newCompanyDeliveryListName1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListNameLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListNameLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListNameValue1652090751273"
												src="delivery_name"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListNameValue1652090751273')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCity1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCityLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListCityLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListCityValue1652090751273"
												src="delivery_city"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListCityValue1652090751273')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCP1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCPLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListCPLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListCPValue1652090751273"
												src="delivery_cp"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListCPValue1652090751273')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListProvince1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListProvinceLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListProvinceLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListProvinceValue1652090751273"
												src="delivery_province"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListProvinceValue1652090751273')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCountry1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCountryLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListCountryLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListCountryValue1652090751273"
												src="delivery_country"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListCountryValue1652090751273')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListComments1652090751273"
											direction="vertical"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCommentsLabel1652090751273"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('admin_new_company_review.newCompanyDeliveryListCommentsLabel1652090751273')}
											</Text>
											<Text
												id="newCompanyDeliveryListCommentsValue1652090751273"
												src="delivery_comments"
												className="w-fill h-auto position-relative ff-librebaskervillebold" >
												{t('admin_new_company_review.newCompanyDeliveryListCommentsValue1652090751273')}
											</Text>
										</Group>
									</Group>
								</List>
							</Group>
							<Group
								id="newCompanyCommentsGroup1652090751273"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyCommentsLabel1652090751273"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyCommentsLabel1652090751273')}
								</Text>
								<Text
									id="newCompanyLegalCommentsValue1652090751273"
									src="@firebase.firestore.new_customers.(@cookie.applicationID).comments"
									className="w-fill h-auto position-relative ff-librebaskervillebold" >
									{t('admin_new_company_review.newCompanyLegalCommentsValue1652090751273')}
								</Text>
							</Group>
							<Text
								id="newCompanyButtonAccept"
								if={{"what":"@property.allowValidation","is":true}}
								onClick={newCompanyButtonAcceptOnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent p-12px my-24px text-text-on-accent ff-librebaskervilleregular text-center" >
								{t('admin_new_company_review.newCompanyButtonAccept')}
							</Text>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Admin_new_company_reviewDialog;
