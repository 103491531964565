import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, Icon, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const A_companiesView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const a_companiesParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( a_companiesParams, [])
	const a_companiesOnLoaded = (options) => {
		run([{"function":"clone","what":"@firebase.firestore.companies","into":"@database.companies"},{"function":"set","what":"@property.keyword","value":null}], options)
	}
	useEffect(a_companiesOnLoaded, [])
	const userListGotoUserButton1664141790816OnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@element.companyList.selected"},{"function":"set","what":"@database.object","value":"@element.companyList.value"},{"function":"goto","view":"a_company"}], options)
	}
	const icon1664141790816OnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@element.companyList.selected"},{"function":"set","what":"@database.object","value":"@element.companyList.value"},{"function":"popup","dialog":"a_delete_company"}], options)
	}
	const userListHeaders1664047858237OnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const companyListSearchInputOnChanged = (options) => {
		run([{"function":"set","what":"@property.keywordlower","value":"@property.keyword.lower"},{"function":"set","what":"@property.keywordupper","value":"@property.keyword.upper"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_companies.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="companiesBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="companyListHeader"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="companyListTitle"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_companies.companyListTitle')}
					</Text>
					<Group
						id="companyListSearchBox"
						direction="vertical"
						className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
						<Input.Text
							id="companyListSearchInput"
							name="text"
							src="@property.keyword"
						autoComplete="off"
						onChange={companyListSearchInputOnChanged}
							className="w-auto h-auto border-radius-5px position-relative" />
						<Icon
							id="companyListSearchIcon"
							src="magnify"
							color="var(--secondary-text)"
							className="w-auto h-auto align-end valign-center position-absolute p-4px"
						/>
					</Group>
				</Group>
				<Group
					id="userListHeaders1664047858237"
					onClick={userListHeaders1664047858237OnClick}
					direction="horizontal"
					className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
					<Text
						id="userListHeaderCompany1664047858237"
						tag="small"
						className="w-250px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_companies.userListHeaderCompany1664047858237')}
					</Text>
					<Text
						id="userListHeaderEmail1664047858237"
						className="w-300px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_companies.userListHeaderEmail1664047858237')}
					</Text>
					<Text
						id="userListHeaderID1664047858237"
						className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_companies.userListHeaderID1664047858237')}
					</Text>
				</Group>
				<List
					id="companyList"
					direction="vertical"
					src="@database.companies"
					filter={{"what":"keywords","includes":"@property.keywordlower","or":{"what":"cif","includes":"@property.keywordlower","or":{"what":"cif","includes":"@property.keywordupper"}}}}
					order={{"type":"asc","field":"name"}}
					className="w-fill h-auto position-relative" >
					<Group
						id="salesCustomerName1664047858237"
						direction="horizontal"
						className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
						<Text
							id="userListCompany1664047858237"
							src="cif"
							className="w-250px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_companies.userListCompany1664047858237')}
						</Text>
						<Text
							id="companyListName"
							src="name"
							className="w-300px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
							{t('a_companies.companyListName')}
						</Text>
						<Text
							id="userListID1664047858237"
							src="owner"
							className="w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_companies.userListID1664047858237')}
						</Text>
						<Group
							id="userListRowSpacer1664047858237"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Group
							id="companyListRowActions"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Icon
								id="icon1664141790816"
								onClick={icon1664141790816OnClick}
								src="trash-can"
								color="var(--gray)"
								className="w-auto h-auto position-relative"
							/>
							<Icon
								id="userListGotoUserButton1664141790816"
								onClick={userListGotoUserButton1664141790816OnClick}
								src="arrow-right-bold"
								color="var(--gray)"
								className="w-auto h-auto position-relative ms-16px"
							/>
						</Group>
					</Group>
				</List>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
