import React, { useEffect, useState } from 'react';

export const DoyoBadge = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { config = {} } = await (
          await fetch(
            `https://brain.doyo.tech/api/accounts/155199/config?host=${window.location.host}`
          )
        ).json();
        const { ads = {} } = config;
        const { enabled = true } = ads;
        setVisible(enabled);
      } catch (error) {
        setVisible(true);
      }
    })();
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <>
      <style>
        {`
        .doyo-badge{
          position: fixed;
          bottom: 100px;
          padding: 0.5rem 0.25rem;
          background-color: #ffffff;
          border-radius: 0 1rem 1rem 0;
          box-shadow: 1px 2px 8px -2px rgba(0,0,0,0.15);
          width: 32px;
          z-index: 999;
        }
        `}
      </style>
      <div className="doyo-badge">
        <a href="https://doyo.tech/signup?badge=left" target="_self">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 47 332"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="matrix(1,0,0,1,6.66134e-16,-30)">
              <g>
                <g transform="matrix(-0.211845,2.58971e-17,-2.58971e-17,-0.211087,86.0516,257.019)">
                  <path
                    d="M216.334,122.082L216.233,118.095C216.233,74.75 251.424,39.56 294.768,39.56C338.113,39.56 373.303,74.75 373.303,118.095L373.202,122.082C352.266,109.91 324.811,102.539 294.768,102.539C264.726,102.539 237.27,109.91 216.334,122.082Z"
                    fill="rgb(250,203,60)"
                  />
                </g>
                <g transform="matrix(1.29253e-17,0.211087,-0.211845,1.29717e-17,45.4349,136.846)">
                  <path
                    d="M437.816,25.672C446.223,40.452 452.255,58.391 455.018,78.136C451.307,75.379 446.907,73.8 442.199,73.8C428.64,73.8 417.632,86.902 417.632,103.039C417.632,119.177 428.64,132.279 442.199,132.279C446.907,132.279 451.307,130.7 455.018,127.943C452.255,147.69 446.226,165.632 437.816,180.407C400.831,174.056 372.676,141.81 372.676,103.039C372.676,64.269 400.831,32.022 437.816,25.672Z"
                    fill="rgb(153,204,102)"
                  />
                </g>
              </g>
              <g>
                <g transform="matrix(2.39669e-15,39.141,-39.2816,2.4053e-15,13.9829,263.064)">
                  <path
                    d="M0.537,-0.688L0.387,-0.688L0.387,-0.419C0.356,-0.459 0.308,-0.479 0.245,-0.479C0.18,-0.479 0.127,-0.456 0.086,-0.409C0.046,-0.363 0.026,-0.304 0.026,-0.233C0.026,-0.162 0.046,-0.104 0.086,-0.058C0.127,-0.013 0.18,0.01 0.245,0.01C0.304,0.01 0.355,-0.008 0.383,-0.036L0.388,0L0.537,0L0.537,-0.688ZM0.285,-0.341C0.342,-0.341 0.385,-0.297 0.385,-0.234C0.385,-0.171 0.342,-0.128 0.285,-0.128C0.225,-0.128 0.181,-0.173 0.181,-0.234C0.181,-0.298 0.225,-0.341 0.285,-0.341Z"
                    fill="rgb(36,36,36)"
                  />
                </g>
                <g transform="matrix(2.39669e-15,39.141,-39.2816,2.4053e-15,13.9829,285.884)">
                  <path
                    d="M0.097,-0.409C0.049,-0.362 0.025,-0.304 0.025,-0.234C0.025,-0.164 0.049,-0.106 0.097,-0.059C0.145,-0.013 0.205,0.01 0.277,0.01C0.35,0.01 0.41,-0.013 0.457,-0.059C0.505,-0.106 0.529,-0.164 0.529,-0.234C0.529,-0.304 0.505,-0.362 0.457,-0.409C0.41,-0.456 0.35,-0.479 0.277,-0.479C0.205,-0.479 0.145,-0.456 0.097,-0.409ZM0.349,-0.312C0.368,-0.292 0.377,-0.266 0.377,-0.234C0.377,-0.203 0.368,-0.177 0.349,-0.156C0.33,-0.136 0.306,-0.126 0.277,-0.126C0.248,-0.126 0.224,-0.136 0.205,-0.156C0.187,-0.177 0.178,-0.203 0.178,-0.234C0.178,-0.266 0.187,-0.292 0.205,-0.312C0.224,-0.332 0.248,-0.342 0.277,-0.342C0.306,-0.342 0.33,-0.332 0.349,-0.312Z"
                    fill="rgb(36,36,36)"
                  />
                </g>
                <g transform="matrix(2.39669e-15,39.141,-39.2816,2.4053e-15,13.9829,306.394)">
                  <path
                    d="M0.197,0C0.184,0.048 0.15,0.072 0.106,0.072C0.089,0.072 0.07,0.068 0.051,0.06L0.03,0.181C0.063,0.197 0.097,0.205 0.131,0.205C0.222,0.205 0.294,0.16 0.331,0.069L0.548,-0.469L0.376,-0.469L0.279,-0.155L0.18,-0.469L0.009,-0.469L0.197,0Z"
                    fill="rgb(36,36,36)"
                  />
                </g>
                <g transform="matrix(2.39669e-15,39.141,-39.2816,2.4053e-15,13.9829,326.786)">
                  <path
                    d="M0.097,-0.409C0.049,-0.362 0.025,-0.304 0.025,-0.234C0.025,-0.164 0.049,-0.106 0.097,-0.059C0.145,-0.013 0.205,0.01 0.277,0.01C0.35,0.01 0.41,-0.013 0.457,-0.059C0.505,-0.106 0.529,-0.164 0.529,-0.234C0.529,-0.304 0.505,-0.362 0.457,-0.409C0.41,-0.456 0.35,-0.479 0.277,-0.479C0.205,-0.479 0.145,-0.456 0.097,-0.409ZM0.349,-0.312C0.368,-0.292 0.377,-0.266 0.377,-0.234C0.377,-0.203 0.368,-0.177 0.349,-0.156C0.33,-0.136 0.306,-0.126 0.277,-0.126C0.248,-0.126 0.224,-0.136 0.205,-0.156C0.187,-0.177 0.178,-0.203 0.178,-0.234C0.178,-0.266 0.187,-0.292 0.205,-0.312C0.224,-0.332 0.248,-0.342 0.277,-0.342C0.306,-0.342 0.33,-0.332 0.349,-0.312Z"
                    fill="rgb(36,36,36)"
                  />
                </g>
              </g>
            </g>
            <g transform="matrix(1.4429e-16,2.35643,-2.35643,1.4429e-16,207.656,-1.75228)">
              <path d="M12.638,79.446L13.775,79.734C13.537,80.667 13.108,81.379 12.489,81.869C11.87,82.36 11.113,82.605 10.218,82.605C9.292,82.605 8.54,82.416 7.959,82.039C7.379,81.662 6.938,81.116 6.635,80.402C6.332,79.687 6.181,78.919 6.181,78.099C6.181,77.204 6.352,76.424 6.694,75.758C7.036,75.092 7.522,74.586 8.153,74.24C8.784,73.895 9.478,73.722 10.236,73.722C11.095,73.722 11.818,73.941 12.404,74.378C12.99,74.816 13.398,75.431 13.628,76.224L12.509,76.487C12.31,75.862 12.021,75.407 11.642,75.122C11.263,74.837 10.787,74.695 10.212,74.695C9.552,74.695 9,74.853 8.557,75.169C8.114,75.486 7.802,75.91 7.623,76.444C7.443,76.977 7.353,77.527 7.353,78.093C7.353,78.823 7.459,79.461 7.672,80.006C7.885,80.551 8.216,80.958 8.665,81.228C9.115,81.497 9.601,81.632 10.124,81.632C10.761,81.632 11.3,81.448 11.742,81.081C12.183,80.714 12.482,80.169 12.638,79.446Z" />
              <path d="M15.029,82.458L15.029,76.236L15.978,76.236L15.978,77.179C16.22,76.737 16.444,76.446 16.649,76.306C16.854,76.165 17.08,76.095 17.326,76.095C17.681,76.095 18.042,76.208 18.41,76.435L18.046,77.413C17.789,77.261 17.531,77.185 17.273,77.185C17.042,77.185 16.835,77.254 16.652,77.393C16.468,77.531 16.337,77.724 16.259,77.97C16.142,78.345 16.083,78.755 16.083,79.2L16.083,82.458L15.029,82.458Z" />
              <path d="M23.296,80.454L24.386,80.589C24.214,81.226 23.896,81.72 23.431,82.071C22.966,82.423 22.373,82.599 21.65,82.599C20.74,82.599 20.018,82.319 19.485,81.758C18.952,81.197 18.685,80.411 18.685,79.4C18.685,78.353 18.955,77.54 19.494,76.962C20.033,76.384 20.732,76.095 21.591,76.095C22.423,76.095 23.103,76.378 23.63,76.945C24.158,77.511 24.421,78.308 24.421,79.335C24.421,79.398 24.419,79.491 24.415,79.616L19.775,79.616C19.814,80.3 20.007,80.823 20.355,81.187C20.703,81.55 21.136,81.732 21.656,81.732C22.042,81.732 22.373,81.63 22.646,81.427C22.919,81.224 23.136,80.9 23.296,80.454ZM19.833,78.749L23.308,78.749C23.261,78.226 23.128,77.833 22.91,77.571C22.574,77.165 22.138,76.962 21.603,76.962C21.119,76.962 20.711,77.124 20.381,77.448C20.051,77.773 19.869,78.206 19.833,78.749Z" />
              <path d="M29.771,81.691C29.38,82.023 29.004,82.257 28.643,82.394C28.282,82.53 27.894,82.599 27.48,82.599C26.796,82.599 26.271,82.432 25.904,82.098C25.537,81.764 25.353,81.337 25.353,80.818C25.353,80.513 25.422,80.235 25.561,79.983C25.7,79.731 25.881,79.529 26.106,79.376C26.331,79.224 26.583,79.109 26.865,79.03C27.072,78.976 27.384,78.923 27.802,78.872C28.654,78.771 29.281,78.65 29.683,78.509C29.687,78.364 29.689,78.273 29.689,78.234C29.689,77.804 29.589,77.501 29.39,77.325C29.121,77.087 28.72,76.968 28.189,76.968C27.693,76.968 27.327,77.055 27.09,77.229C26.854,77.403 26.679,77.71 26.566,78.152L25.535,78.011C25.628,77.57 25.783,77.213 25.998,76.942C26.212,76.67 26.523,76.461 26.929,76.315C27.335,76.168 27.806,76.095 28.341,76.095C28.873,76.095 29.304,76.157 29.636,76.282C29.968,76.407 30.212,76.565 30.369,76.754C30.525,76.944 30.634,77.183 30.697,77.472C30.732,77.652 30.749,77.976 30.749,78.445L30.749,79.851C30.749,80.831 30.772,81.451 30.817,81.711C30.862,81.971 30.951,82.22 31.083,82.458L29.982,82.458C29.873,82.239 29.802,81.984 29.771,81.691ZM29.683,79.335C29.3,79.491 28.726,79.624 27.96,79.734C27.527,79.796 27.22,79.866 27.04,79.945C26.861,80.023 26.722,80.137 26.624,80.287C26.527,80.438 26.478,80.605 26.478,80.788C26.478,81.07 26.584,81.304 26.797,81.491C27.01,81.679 27.322,81.773 27.732,81.773C28.138,81.773 28.499,81.684 28.816,81.506C29.132,81.328 29.365,81.085 29.513,80.777C29.626,80.538 29.683,80.187 29.683,79.722L29.683,79.335Z" />
              <path d="M34.687,81.515L34.839,82.446C34.542,82.509 34.277,82.54 34.042,82.54C33.66,82.54 33.363,82.48 33.152,82.359C32.941,82.237 32.792,82.078 32.707,81.881C32.621,81.684 32.578,81.269 32.578,80.636L32.578,77.056L31.804,77.056L31.804,76.236L32.578,76.236L32.578,74.695L33.626,74.062L33.626,76.236L34.687,76.236L34.687,77.056L33.626,77.056L33.626,80.695C33.626,80.995 33.645,81.189 33.682,81.275C33.719,81.361 33.78,81.429 33.864,81.48C33.948,81.53 34.068,81.556 34.224,81.556C34.341,81.556 34.496,81.542 34.687,81.515Z" />
              <path d="M39.978,80.454L41.068,80.589C40.896,81.226 40.578,81.72 40.113,82.071C39.648,82.423 39.054,82.599 38.332,82.599C37.421,82.599 36.7,82.319 36.166,81.758C35.633,81.197 35.367,80.411 35.367,79.4C35.367,78.353 35.636,77.54 36.175,76.962C36.714,76.384 37.414,76.095 38.273,76.095C39.105,76.095 39.785,76.378 40.312,76.945C40.839,77.511 41.103,78.308 41.103,79.335C41.103,79.398 41.101,79.491 41.097,79.616L36.457,79.616C36.496,80.3 36.689,80.823 37.037,81.187C37.384,81.55 37.818,81.732 38.337,81.732C38.724,81.732 39.054,81.63 39.328,81.427C39.601,81.224 39.818,80.9 39.978,80.454ZM36.515,78.749L39.99,78.749C39.943,78.226 39.81,77.833 39.591,77.571C39.255,77.165 38.82,76.962 38.285,76.962C37.8,76.962 37.393,77.124 37.063,77.448C36.733,77.773 36.55,78.206 36.515,78.749Z" />
              <path d="M46.429,82.458L46.429,81.673C46.035,82.29 45.455,82.599 44.689,82.599C44.193,82.599 43.737,82.462 43.321,82.189C42.905,81.915 42.582,81.533 42.354,81.043C42.125,80.553 42.011,79.989 42.011,79.353C42.011,78.732 42.115,78.168 42.322,77.662C42.529,77.156 42.839,76.769 43.253,76.499C43.667,76.23 44.13,76.095 44.642,76.095C45.017,76.095 45.351,76.174 45.644,76.332C45.937,76.49 46.175,76.696 46.359,76.95L46.359,73.868L47.408,73.868L47.408,82.458L46.429,82.458ZM43.095,79.353C43.095,80.15 43.263,80.745 43.599,81.14C43.935,81.534 44.332,81.732 44.789,81.732C45.249,81.732 45.641,81.543 45.963,81.166C46.286,80.789 46.447,80.214 46.447,79.441C46.447,78.589 46.283,77.964 45.955,77.566C45.626,77.167 45.222,76.968 44.742,76.968C44.273,76.968 43.881,77.159 43.567,77.542C43.252,77.925 43.095,78.529 43.095,79.353Z" />
              <path d="M53.548,82.458L51.644,76.236L52.734,76.236L53.724,79.827L54.093,81.163C54.109,81.097 54.216,80.669 54.415,79.88L55.406,76.236L56.49,76.236L57.421,79.845L57.732,81.034L58.089,79.833L59.156,76.236L60.181,76.236L58.236,82.458L57.14,82.458L56.15,78.732L55.91,77.671L54.65,82.458L53.548,82.458Z" />
              <path d="M61.072,75.081L61.072,73.868L62.126,73.868L62.126,75.081L61.072,75.081ZM61.072,82.458L61.072,76.236L62.126,76.236L62.126,82.458L61.072,82.458Z" />
              <path d="M66.035,81.515L66.187,82.446C65.89,82.509 65.624,82.54 65.39,82.54C65.007,82.54 64.71,82.48 64.499,82.359C64.289,82.237 64.14,82.078 64.054,81.881C63.968,81.684 63.925,81.269 63.925,80.636L63.925,77.056L63.152,77.056L63.152,76.236L63.925,76.236L63.925,74.695L64.974,74.062L64.974,76.236L66.035,76.236L66.035,77.056L64.974,77.056L64.974,80.695C64.974,80.995 64.993,81.189 65.03,81.275C65.067,81.361 65.127,81.429 65.211,81.48C65.295,81.53 65.415,81.556 65.572,81.556C65.689,81.556 65.843,81.542 66.035,81.515Z" />
              <path d="M67.066,82.458L67.066,73.868L68.121,73.868L68.121,76.95C68.613,76.38 69.234,76.095 69.984,76.095C70.445,76.095 70.845,76.186 71.185,76.367C71.525,76.549 71.768,76.8 71.915,77.12C72.061,77.441 72.134,77.905 72.134,78.515L72.134,82.458L71.08,82.458L71.08,78.515C71.08,77.987 70.965,77.604 70.737,77.363C70.508,77.123 70.185,77.003 69.767,77.003C69.455,77.003 69.161,77.084 68.885,77.246C68.61,77.408 68.414,77.628 68.296,77.905C68.179,78.183 68.121,78.566 68.121,79.054L68.121,82.458L67.066,82.458Z" />
            </g>
          </svg>
        </a>
      </div>
    </>
  );
};
export default DoyoBadge;
