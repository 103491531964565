import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Image } from '../components';
import horizontal from '../assets/logos/horizontal.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const CustomerwrapperWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const Main_menuItemsOptCustomerLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Main_menuItemsOptProfileOnClick = (options) => {
		run([{"function":"goto","view":"profile"}], options)
	}
	const Main_menuItemsOptHistoryOnClick = (options) => {
		run([{"function":"goto","view":"history"}], options)
	}
	const Main_menuItemsOptPedidoOnClick = (options) => {
		run([{"function":"goto","view":"orders"}], options)
	}

  return (
    <main id="CustomerwrapperWrapper" className="view h-fill">
        
				<Navbar bg="primary" expand="lg" sticky="top" className="z-index-10 px-3">
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="customerwrapper_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('main_menu.items.optPedido.title')} onClick={Main_menuItemsOptPedidoOnClick} href="orders" />
							<Nav.Item title={t('main_menu.items.optHistory.title')} onClick={Main_menuItemsOptHistoryOnClick} href="history" />
							<Nav.Item title={t('main_menu.items.optProfile.title')} onClick={Main_menuItemsOptProfileOnClick} href="profile" />
							<Nav.Item title={t('main_menu.items.optCustomerLogout.title')} onClick={Main_menuItemsOptCustomerLogoutOnClick} href="logout" />
						</Nav>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="customerwrapper_actionbar_logo"
									src={horizontal}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="customerwrapper_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('main_menu.items.optPedido.title')} onClick={Main_menuItemsOptPedidoOnClick} href="orders" />
							<Nav.Item title={t('main_menu.items.optHistory.title')} onClick={Main_menuItemsOptHistoryOnClick} href="history" />
							<Nav.Item title={t('main_menu.items.optProfile.title')} onClick={Main_menuItemsOptProfileOnClick} href="profile" />
							<Nav.Item title={t('main_menu.items.optCustomerLogout.title')} onClick={Main_menuItemsOptCustomerLogoutOnClick} href="logout" />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
