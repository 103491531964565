import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Toast, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const Toast_candidate_errorDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:


  return (
    
    
			<Toast id="toast_candidate_error" autohide delay={5}>
					{t('toast_candidate_error.text')}
			</Toast>
    
  );
};

export default Toast_candidate_errorDialog;
