import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Icon, List, Image } from '../components';
import placeholder_cup from '../assets/image/placeholder_cup.png';
import { useTranslation } from 'react-i18next';

export const Admin_productsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminProductCardContentOnClick = (options) => {
		run([{"function":"set","what":"@cookie.product","value":"@element.adminProductList.value"},{"function":"set","what":"@cookie.productID","value":"@element.adminProductList.selected"},{"function":"set","what":"@property.productName","value":"@cookie.product.name"},{"function":"set","what":"@property.productDescription","value":"@cookie.product.description"},{"function":"set","what":"@property.productSize","value":"@cookie.product.size"},{"function":"set","what":"@property.productSku","value":"@cookie.product.sku"},{"function":"set","what":"@property.productImage","value":"@cookie.product.image"},{"function":"set","what":"@property.productHoldedId","value":"@cookie.product.holded_id"},{"function":"popup","dialog":"product_edit"}], options)
	}
	const productAddButtonOnClick = (options) => {
		run([{"function":"delete","what":"@property.name"},{"function":"popup","dialog":"product_add"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_products.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="productsAdminBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-admin_background p-32px" >
				<Group
					id="productsWrapper"
					direction="vertical"
					className="w-100 h-fill position-relative scrollable" >
					<Group
						id="productsHeader"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="productsTitle"
							tag="h2"
							className="w-auto h-auto position-relative pe-24px text-primary ff-admin_title" >
							{t('admin_products.productsTitle')}
						</Text>
						<Icon
							id="productAddButton"
							onClick={productAddButtonOnClick}
							src="plus-circle"
							color="var(--accent)"
							className="w-auto h-auto valign-center position-relative"
						/>
					</Group>
					<List
						id="adminProductList"
						direction="auto"
						src="@firebase.firestore.products"
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Group
							id="adminProductCardContent"
							onClick={adminProductCardContentOnClick}
							direction="vertical"
							className="max-w-400px min-w-300px w-fill h-auto border-primary-dark border-top border-bottom border-start border-end position-relative me-16px mb-16px" >
							<Image
								id="adminProductImage"
								fallback={placeholder_cup}
								src="image"
								className="w-164px h-auto align-center position-relative p-16px" />
							<Group
								id="productCardInfoWrapper"
								direction="horizontal"
								className="w-100 min-h-100px h-100px position-relative bg-primary-dark p-8px" >
								<Group
									id="productCardInfo"
									direction="vertical"
									className="w-100 h-auto valign-center position-relative" >
									<Text
										id="adminProductName"
										tag="b"
										src="name"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular text-center" >
										{t('admin_products.adminProductName')}
									</Text>
									<Text
										id="adminProductSize"
										className="w-100 h-auto position-relative text-primary ff-admin_regular text-center" >
										{t('admin_products.adminProductSize')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
