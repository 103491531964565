import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Image } from '../components';
import horizontal from '../assets/logos/horizontal.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Wrapper_adminWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const Menu_adminItemsOptLogoutOnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Menu_adminItemsOptSolicitudesItemsOptRegistroErroneoOnClick = (options) => {
		run([{"function":"goto","view":"a_signuperror"}], options)
	}
	const Menu_adminItemsOptSolicitudesItemsOptRegistroPendienteOnClick = (options) => {
		run([{"function":"goto","view":"a_signuppending"}], options)
	}
	const Menu_adminItemsOptSolicitudesItemsOptViejosClientesOnClick = (options) => {
		run([{"function":"goto","view":"a_oldcustomers"}], options)
	}
	const Menu_adminItemsOptSolicitudesItemsOptNuevosClientesOnClick = (options) => {
		run([{"function":"goto","view":"a_newcustomers"}], options)
	}
	const Menu_adminItemsOptProductsOnClick = (options) => {
		run([{"function":"goto","view":"admin_products"}], options)
	}
	const Menu_adminItemsOptOrdersItemsOptOrdersProcessedOnClick = (options) => {
		run([{"function":"goto","view":"a_shippings"}], options)
	}
	const Menu_adminItemsOptOrdersItemsOptOrdersConfirmedOnClick = (options) => {
		run([{"function":"set","what":"@cookie.order_status","value":"confirmed"},{"function":"goto","view":"a_orders"}], options)
	}
	const Menu_adminItemsOptOrdersItemsOptOrdersOpenOnClick = (options) => {
		run([{"function":"set","what":"@cookie.order_status","value":"open"},{"function":"goto","view":"a_orders"}], options)
	}
	const Menu_adminItemsOptContactsItemsOptCompaniesOnClick = (options) => {
		run([{"function":"goto","view":"a_companies"}], options)
	}
	const Menu_adminItemsOptContactsItemsOptUsersOnClick = (options) => {
		run([{"function":"goto","view":"a_users"}], options)
	}

  return (
    <main id="Wrapper_adminWrapper" className="view h-fill">
        
				<Navbar bg="primary" expand="lg" sticky="top" className="z-index-10 px-3">
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="wrapper_admin_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('menu_admin.items.optContacts.title')}>
								<Nav.Item title={t('menu_admin.items.optContacts.items.optUsers.title')} onClick={Menu_adminItemsOptContactsItemsOptUsersOnClick} href="a_users" />
								<Nav.Item title={t('menu_admin.items.optContacts.items.optCompanies.title')} onClick={Menu_adminItemsOptContactsItemsOptCompaniesOnClick} href="a_companies" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optOrders.title')}>
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersOpen.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersOpenOnClick} href="a_orders" />
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersConfirmed.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersConfirmedOnClick} href="a_orders" />
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersProcessed.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersProcessedOnClick} href="a_shippings" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optProducts.title')} onClick={Menu_adminItemsOptProductsOnClick} href="admin_products" />
							<Nav.Item title={t('menu_admin.items.optSolicitudes.title')}>
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optNuevosClientes.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptNuevosClientesOnClick} href="a_newcustomers" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optViejosClientes.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptViejosClientesOnClick} href="a_oldcustomers" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optRegistroPendiente.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptRegistroPendienteOnClick} href="a_signuppending" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optRegistroErroneo.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptRegistroErroneoOnClick} href="a_signuperror" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optLogout.title')} onClick={Menu_adminItemsOptLogoutOnClick} href="logout" />
						</Nav>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="wrapper_admin_actionbar_logo"
									src={horizontal}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="wrapper_admin_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('menu_admin.items.optContacts.title')}>
								<Nav.Item title={t('menu_admin.items.optContacts.items.optUsers.title')} onClick={Menu_adminItemsOptContactsItemsOptUsersOnClick} href="a_users" />
								<Nav.Item title={t('menu_admin.items.optContacts.items.optCompanies.title')} onClick={Menu_adminItemsOptContactsItemsOptCompaniesOnClick} href="a_companies" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optOrders.title')}>
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersOpen.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersOpenOnClick} href="a_orders" />
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersConfirmed.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersConfirmedOnClick} href="a_orders" />
								<Nav.Item title={t('menu_admin.items.optOrders.items.optOrdersProcessed.title')} onClick={Menu_adminItemsOptOrdersItemsOptOrdersProcessedOnClick} href="a_shippings" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optProducts.title')} onClick={Menu_adminItemsOptProductsOnClick} href="admin_products" />
							<Nav.Item title={t('menu_admin.items.optSolicitudes.title')}>
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optNuevosClientes.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptNuevosClientesOnClick} href="a_newcustomers" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optViejosClientes.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptViejosClientesOnClick} href="a_oldcustomers" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optRegistroPendiente.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptRegistroPendienteOnClick} href="a_signuppending" />
								<Nav.Item title={t('menu_admin.items.optSolicitudes.items.optRegistroErroneo.title')} onClick={Menu_adminItemsOptSolicitudesItemsOptRegistroErroneoOnClick} href="a_signuperror" />
							</Nav.Item>
							<Nav.Item title={t('menu_admin.items.optLogout.title')} onClick={Menu_adminItemsOptLogoutOnClick} href="logout" />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
