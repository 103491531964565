import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Sales_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Card, Image, Icon, Button } from '../components';
import { useEffect } from 'react';
import avellana from '../assets/helados/avellana.png';
import { useTranslation } from 'react-i18next';

export const Sales_productsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const sales_productsParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( sales_productsParams, [])
	const sales_productsOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.orderStatus","value":"@firebase.firestore.orders.(@cookie.salesLocationID).order_status"}], options)
	}
	useEffect(sales_productsOnLoaded, [])
	const salesCancelOrderButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderStatus","value":"open"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.salesLocationID).order_status","value":"open"}], options)
	}
	const salesConfirmOrderButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.comments","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).comments"},{"function":"popup","dialog":"sales_order_confirmation"}], options)
	}
	const salesProductUnitsAddOnClick = (options) => {
		run([{"function":"set","what":"@property.value","value":"@database.order.(@element.salesProductsList.selected).units"},{"function":"add","what":{"units":"@property.value.+1","name":"@field.name"},"to":"@firebase.firestore.orders.(@cookie.salesLocationID).cart","key":"@element.salesProductsList.selected"},{"function":"set","what":"@database.order.(@element.salesProductsList.selected).units","value":"@property.value.+1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.salesLocationID).order_status","value":"open","if":{"what":"@cookie.orderStatus","is":"confirmed"}},{"function":"set","what":"@cookie.orderStatus","value":"open"}], options)
	}
	const salesProductUnitsMinusOnClick = (options) => {
		run([{"function":"set","what":"@property.value","value":"@database.order.(@element.salesProductsList.selected).units"},{"function":"add","what":{"units":"@property.value.-1","name":"@field.name"},"if":{"what":"@property.value","more_than":0},"to":"@firebase.firestore.orders.(@cookie.salesLocationID).cart","key":"@element.salesProductsList.selected"},{"function":"set","what":"@database.order.(@element.salesProductsList.selected).units","value":"@property.value.-1","if":{"what":"@property.value","more_than":0}},{"function":"set","what":"@firebase.firestore.orders.(@cookie.salesLocationID).order_status","value":"open","if":{"what":"@cookie.orderStatus","is":"confirmed"}},{"function":"set","what":"@cookie.orderStatus","value":"open"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('sales_products.title')}</title>
      </Helmet>
      <Sales_wrapperWrapper>
          
			<Group
				id="productsWrapper1649064627959"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="productsHeader1649064627959"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Text
						id="salesProductsTitle"
						if={{"what":"@cookie.orderStatus","is_not":"confirmed"}}
						tag="h3"
						className="w-100 h-auto position-relative ff-librebaskervilleregular" >
						{t('sales_products.salesProductsTitle')}
					</Text>
					<Text
						id="salesProductsConfirmedTitle"
						if={{"what":"@cookie.orderStatus","is":"confirmed"}}
						tag="h3"
						className="w-100 h-auto position-relative ff-librebaskervilleregular" >
						{t('sales_products.salesProductsConfirmedTitle')}
					</Text>
					<Text
						id="salesProductsTitle1649069497886"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('sales_products.salesProductsTitle1649069497886')}
					</Text>
				</Group>
				<List
					id="salesProductsList"
					direction="vertical"
					src="@database.order"
					filter={{"what":"available","is":true}}
					order={{"type":"asc","field":"sku"}}
					columns={1}
					className="w-100 h-auto position-relative mt-16px" >
					<Card
						id="productCard1649064627959"
						className="w-100 h-auto position-relative m-4px" >
						<Card.Body>
								<Group
									id="productCardContent1649064627959"
									direction="horizontal"
									className="w-100 h-auto position-relative scrollable" >
									<Image
										id="salesProductImage"
										fallback={avellana}
										src="image"
										className="w-48px h-auto align-center position-relative" />
									<Text
										id="salesProductName"
										tag="b"
										className="w-fill h-auto valign-center position-relative ms-24px text-start" >
										{t('sales_products.salesProductName')}
									</Text>
									<Group
										id="salesProductUnitsSelector"
										direction="horizontal"
										className="w-auto h-auto position-relative" >
										<Icon
											id="salesProductUnitsMinus"
											onClick={salesProductUnitsMinusOnClick}
											src="minus-box"
											color="var(--gray)"
											className="w-auto h-auto valign-center position-relative"
										/>
										<Text
											id="salesProductUnitsValue"
											tag="h3"
											src="@database.order.(@field.id).units"
											className="w-auto h-auto position-relative px-16px ff-librebaskervillebold" >
											{t('')}
										</Text>
										<Icon
											id="salesProductUnitsAdd"
											onClick={salesProductUnitsAddOnClick}
											src="plus-box"
											color="var(--gray)"
											className="w-auto h-auto valign-center position-relative"
										/>
									</Group>
								</Group>
						</Card.Body>
					</Card>
				</List>
			</Group>
			<Group
				id="productsFooter1649064627959"
				direction="vertical"
				className="w-100 h-auto position-relative bg-white p-16px" >
				<Button
					id="salesConfirmOrderButton"
					if={{"what":"@cookie.orderStatus","is_not":"confirmed"}}
					onClick={salesConfirmOrderButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative" >
					{t('sales_products.salesConfirmOrderButton')}
				</Button>
				<Button
					id="salesCancelOrderButton"
					if={{"what":"@cookie.orderStatus","is":"confirmed"}}
					onClick={salesCancelOrderButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative" >
					{t('sales_products.salesCancelOrderButton')}
				</Button>
			</Group>
      </Sales_wrapperWrapper>
    </>
  );
};
