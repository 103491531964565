import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Order_repeatDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const order_repeat_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).order_status","value":"confirmed"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).holded_id","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).holded_id"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).location_id","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).id"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).is_shipping_address","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).is_shipping_address"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).notes","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).notes"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.locationID).comments","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).comments"},{"function":"set","what":"@cookie.orderStatus","value":"confirmed"},{"function":"hide","dialog":"order_repeat"}], options)
	}
	const order_repeat_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"order_repeat"}], options)
	}

  return (
    
    
			<Modal id="order_repeat" centered>
					<Modal.Body>
						<Text
							id="cartConfirmText1648807222797"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_repeat.cartConfirmText1648807222797')}
						</Text>
						<Text
							id="cartConfirmText1648807222797"
							src="@firebase.firestore.data.texts.order_confirmation"
							className="w-100 h-auto position-relative mt-16px ff-librebaskervilleregular" >
							{t('order_repeat.cartConfirmText1648807222797')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="order_repeat_buttons_negative"
							onClick={order_repeat_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('order_repeat.order_repeat_buttons_negative')}
						</Button>
						<Button
							id="order_repeat_buttons_positive"
							onClick={order_repeat_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('order_repeat.order_repeat_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Order_repeatDialog;
