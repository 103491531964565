import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Sales_order_confirmationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const sales_order_confirmation_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderStatus","value":"confirmed"},{"function":"add","what":{"company_name":"@cookie.companyName","location_name":"@cookie.locationName","order_status":"confirmed","company":"@cookie.companyID","holded_id":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).holded_id","location_id":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).id","is_shipping_address":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).is_shipping_address","notes":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).notes","custom_notes":"@property.notes","comments":"@property.comments"},"to":"@firebase.firestore.orders","key":"@cookie.salesLocationID"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/confirmOrder","params":{"orderID":"@cookie.salesLocationID"},"method":"post","response":"json-api"},{"function":"goto","view":"sales"},{"function":"hide","dialog":"sales_order_confirmation"}], options)
	}
	const sales_order_confirmation_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"sales_order_confirmation"}], options)
	}

  return (
    
    
			<Modal id="sales_order_confirmation" centered>
					<Modal.Body>
						<Text
							id="salesOrderConfirmText"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('sales_order_confirmation.salesOrderConfirmText')}
						</Text>
						<Text
							id="cartConfirmCompany1649072579262"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('sales_order_confirmation.cartConfirmCompany1649072579262')}
						</Text>
						<Text
							id="cartConfirmLocation1649072579262"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('sales_order_confirmation.cartConfirmLocation1649072579262')}
						</Text>
						<Text
							id="cartConfirmText1649072579262"
							src="@firebase.firestore.data.texts.order_confirmation"
							className="w-100 h-auto position-relative mt-24px ff-librebaskervilleregular" >
							{t('sales_order_confirmation.cartConfirmText1649072579262')}
						</Text>
						<Input.TextArea
							id="cartConfirmNotes1649072579262"
							label={t('sales_order_confirmation.cartConfirmNotes1649072579262.label')}
							name="text"
							src="@property.comments"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="sales_order_confirmation_buttons_negative"
							onClick={sales_order_confirmation_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('sales_order_confirmation.sales_order_confirmation_buttons_negative')}
						</Button>
						<Button
							id="sales_order_confirmation_buttons_positive"
							onClick={sales_order_confirmation_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('sales_order_confirmation.sales_order_confirmation_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Sales_order_confirmationDialog;
