import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const confirmation_buttons_positiveOnClick = (options) => {
		run([{"function":"do","what":[{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/prepareShippingForced"}],"if":{"what":"@property.action","is":"force_shipping"},"comments":"force_shipping"},{"function":"do","what":[{"function":"delete","what":"@firebase.firestore.customers_companies.(@property.customer_company)"}],"if":{"what":"@property.action","is":"delete_customer_company"},"comments":"delete_customer_company"},{"function":"do","what":[{"function":"delete","what":"@firebase.firestore.locations.(@property.locationID)"},{"function":"browser","url":"https:\/\/app.holded.com\/contacts\/(@property.holded_id)","if":{"what":"@property.holded_id","is_not":null},"comments":"Open holded"}],"if":{"what":"@property.action","is":"delete_location"},"comments":"delete_location"},{"function":"hide","dialog":"confirmation"}], options)
	}
	const confirmation_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"confirmation"}], options)
	}

  return (
    
    
			<Modal id="confirmation">
					<Modal.Body>
						<Text
							id="textShippingForced"
							if={{"what":"@property.action","is":"force_shipping"}}
							className="w-100 h-auto position-relative" >
							{t('confirmation.textShippingForced')}
						</Text>
						<Text
							id="textCustomerCompany"
							if={{"what":"@property.action","is":"delete_customer_company"}}
							className="w-100 h-auto position-relative" >
							{t('confirmation.textCustomerCompany')}
						</Text>
						<Text
							id="textDeleteLocation"
							if={{"what":"@property.action","is":"delete_location"}}
							className="w-100 h-auto position-relative" >
							{t('confirmation.textDeleteLocation')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="confirmation_buttons_negative"
							onClick={confirmation_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_negative')}
						</Button>
						<Button
							id="confirmation_buttons_positive"
							onClick={confirmation_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('confirmation.confirmation_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default ConfirmationDialog;
