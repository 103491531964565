import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const A_oldcustomersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const companyGotoLocationButton1664145388229OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.candidateList.value"},{"function":"set","what":"@property.applicationID","value":"@element.candidateList.selected"},{"function":"popup","dialog":"admin_validate_company"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_oldcustomers.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="customerAdminBody1664145388229"
				direction="vertical"
				className="w-100 h-fill position-relative bg-admin_background p-32px" >
				<Group
					id="locationsArea1664145388229"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="userCompaniesArea1664145388229"
						direction="auto"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="locationsAreaHeader1664145388229"
							direction="horizontal"
							className="w-100 h-auto position-relative" >
							<Text
								id="companyLocationsTitle1664145388229"
								tag="b"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
								{t('a_oldcustomers.companyLocationsTitle1664145388229')}
							</Text>
							<Group
								id="locationsListSearchBox1664145388229"
								direction="vertical"
								className="max-w-200px w-auto h-auto valign-center position-relative" >
							</Group>
						</Group>
					</Group>
					<Group
						id="userCompaniesHeader1664145388229"
						direction="horizontal"
						className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
						<Text
							id="userCompanyListHeaderName1664145388229"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_oldcustomers.userCompanyListHeaderName1664145388229')}
						</Text>
						<Text
							id="userCompanyListHeaderCif1664145388229"
							className="w-300px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_oldcustomers.userCompanyListHeaderCif1664145388229')}
						</Text>
						<Text
							id="userListHeaderCompany1664145388229"
							tag="small"
							className="max-w-200px w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
							{t('a_oldcustomers.userListHeaderCompany1664145388229')}
						</Text>
					</Group>
					<List
						id="candidateList"
						direction="vertical"
						src="@firebase.firestore.applications"
						className="w-fill h-auto position-relative" >
						<Group
							id="salesCustomerName1664145388229"
							direction="horizontal"
							className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
							<Text
								id="userCompanyListName1664145388229"
								tag="small"
								src="company_name"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-librebaskervillebold" >
								{t('a_oldcustomers.userCompanyListName1664145388229')}
							</Text>
							<Text
								id="userCompanyListCif1664145388229"
								src="cif"
								className="w-300px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
								{t('a_oldcustomers.userCompanyListCif1664145388229')}
							</Text>
							<Text
								id="userListCompany1664145388229"
								src="email"
								className="max-w-200px w-200px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
								{t('a_oldcustomers.userListCompany1664145388229')}
							</Text>
							<Group
								id="userListRowSpacer1664145388229"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Group
								id="userListRowActions1664145388229"
								direction="horizontal"
								className="w-auto h-auto position-relative" >
								<Icon
									id="companyGotoLocationButton1664145388229"
									onClick={companyGotoLocationButton1664145388229OnClick}
									src="arrow-right-bold"
									color="var(--gray)"
									className="w-auto h-auto position-relative ms-16px"
								/>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
