import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Icon } from '../components';
import { useTranslation } from 'react-i18next';

export const A_shippingsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const shippingListRowDownloadButtonOnClick = (options) => {
		run([{"function":"browser","url":"@field.doc_url","target":"_padownload"}], options)
	}
	const shippingListRowDateOnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@property.companyCIF","value":"@field.cif"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const shippingsListHeaderOnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const uploadExcelButtonOnClick = (options) => {
		run([{"function":"file","into":"@property.tempFile","onsuccess":[{"function":"set","what":"@firebase.storage.waybills\/uploaded_(@cookie.userID).xlsx","value":"@property.tempFile"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/importWaybill","params":{"url":"@firebase.storage.waybills\/uploaded_(@cookie.userID).xlsx"},"response":"json-api","into":"@database.response","method":"post","onerror":[{"function":"set","what":"@property.msg","value":"Ha habido un error al importar el archivo"},{"function":"popup","dialog":"error_dialog"}],"onsuccess":[{"function":"set","what":"@property.msg","value":"Archivo importado con \u00e9xito. Puedes completar el albar\u00e1n en Holded."},{"function":"popup","dialog":"error_dialog"}]}],"multiple":true,"type":"application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_shippings.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="userBody1664181816721"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="userHeader1664181816721"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="shippingsTitle"
						tag="h2"
						className="w-fill h-auto position-relative py-6px text-primary ff-admin_title" >
						{t('a_shippings.shippingsTitle')}
					</Text>
					<Group
						id="shippingsOptions"
						direction="vertical"
						className="w-auto h-auto valign-center position-relative py-6px" >
						<Text
							id="uploadExcelButton"
							onClick={uploadExcelButtonOnClick}
							className="w-auto h-auto border-radius-32px position-relative bg-accent px-12px py-6px ff-admin_regular text-center" >
							{t('a_shippings.uploadExcelButton')}
						</Text>
					</Group>
				</Group>
				<Group
					id="shippingsListHeader"
					onClick={shippingsListHeaderOnClick}
					direction="horizontal"
					className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
					<Text
						id="shippingsListDate"
						className="w-200px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_shippings.shippingsListDate')}
					</Text>
					<Text
						id="shippingsListStatus"
						className="w-400px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_shippings.shippingsListStatus')}
					</Text>
				</Group>
				<List
					id="adminShippingList"
					direction="vertical"
					src="@firebase.firestore.shippings"
					order={{"type":"desc","field":"created_at"}}
					className="w-fill h-auto position-relative" >
					<Group
						id="shippingListRowDate"
						onClick={shippingListRowDateOnClick}
						direction="horizontal"
						className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
						<Text
							id="shippingListRowDateValue"
							format={{"type":"date","year":"numeric","month":"long","day":"numeric"}}
							src="created_at"
							className="w-200px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
							{t('a_shippings.shippingListRowDateValue')}
						</Text>
						<Text
							id="shippingListRowStatus"
							src="status"
							className="w-400px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_shippings.shippingListRowStatus')}
						</Text>
						<Group
							id="shippingListRowRowSpacer"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Group
							id="shippingListRowActions"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Icon
								id="shippingListRowDownloadButton"
								onClick={shippingListRowDownloadButtonOnClick}
								src="download"
								color="var(--gray)"
								className="w-auto h-auto position-relative ms-16px"
							/>
						</Group>
					</Group>
				</List>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
