import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Card, Button, Image } from '../components';
import placeholder_user from '../assets/image/placeholder_user.svg';
import { useTranslation } from 'react-i18next';

export const NewcustomersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const candidateValidateButton1664036811357OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.candidateList.value"},{"function":"set","what":"@property.applicationID","value":"@element.candidateList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"popup","dialog":"admin_validate_company"}], options)
	}
	const newApplicationReviewButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationPendingList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationPendingList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":false},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}
	const newApplicationValidateButton1664036811357OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationErrorList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationErrorList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":true},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}
	const newCustomerValidateButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":true},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('newcustomers.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="customerAdminBody1664036811358"
				direction="auto"
				className="w-100 h-fill position-relative bg-admin_background p-32px" >
				<Group
					id="newApplicationsWrapper1664036811357"
					direction="vertical"
					className="min-w-300px w-fill h-auto border-primary border-start border-end position-relative p-16px" >
					<Text
						id="newApplicationsTitle1664036811357"
						tag="h2"
						className="w-100 h-auto position-relative text-primary ff-renner" >
						{t('newcustomers.newApplicationsTitle1664036811357')}
					</Text>
					<List
						id="newApplicationList1664036811357"
						direction="vertical"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"sent"}}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newCustomersCard"
							className="w-100 h-auto border-radius-15px position-relative mb-16px" >
							<Card.Body>
									<Text
										id="newApplicationName1664036811357"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('newcustomers.newApplicationName1664036811357')}
									</Text>
									<Button
										id="newCustomerValidateButton"
										onClick={newCustomerValidateButtonOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px align-end position-relative mt-16px" >
										{t('newcustomers.newCustomerValidateButton')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="newApplicationErrorsWrapper1664036811357"
					direction="vertical"
					className="min-w-300px w-fill h-auto border-primary border-start border-end position-relative p-16px" >
					<Text
						id="newApplicationErrorsTitle"
						tag="h2"
						className="w-100 h-auto position-relative text-primary ff-renner" >
						{t('newcustomers.newApplicationErrorsTitle')}
					</Text>
					<List
						id="newApplicationErrorList"
						direction="vertical"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"error"}}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newApplicationErrorCard1664036811357"
							className="w-fill h-auto border-radius-15px position-relative mb-16px" >
							<Card.Body>
									<Text
										id="newApplicationErrorName"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('newcustomers.newApplicationErrorName')}
									</Text>
									<Button
										id="newApplicationValidateButton1664036811357"
										onClick={newApplicationValidateButton1664036811357OnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px align-end position-relative mt-16px" >
										{t('newcustomers.newApplicationValidateButton1664036811357')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="newApplicationsOpen"
					direction="vertical"
					className="min-w-300px w-fill h-auto position-relative p-16px" >
					<Text
						id="newApplicationsOpenTitle"
						tag="h2"
						className="w-100 h-auto position-relative text-primary ff-renner" >
						{t('newcustomers.newApplicationsOpenTitle')}
					</Text>
					<List
						id="newApplicationPendingList"
						direction="vertical"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"new"}}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newApplicationCard1664036811357"
							className="w-fill h-auto border-radius-15px position-relative mb-16px" >
							<Card.Body>
									<Text
										id="newApplicationName1664036811357"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('newcustomers.newApplicationName1664036811357')}
									</Text>
									<Button
										id="newApplicationReviewButton"
										onClick={newApplicationReviewButtonOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('newcustomers.newApplicationReviewButton')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="applicationsExistingWrapper"
					direction="vertical"
					className="min-w-300px w-fill h-auto border-primary border-start border-end position-relative p-16px" >
					<Text
						id="candidatesTitle"
						tag="h2"
						className="w-100 h-auto position-relative text-primary ff-renner" >
						{t('newcustomers.candidatesTitle')}
					</Text>
					<List
						id="candidateList1664036811358"
						direction="vertical"
						src="@firebase.firestore.applications"
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="candidateCard1664036811357"
							className="min-w-200px w-fill h-auto border-radius-15px position-relative mb-16px" >
							<Card.Body>
									<Image
										id="candidateGravatar1664036811357"
										fallback={placeholder_user}
										src="gravatar"
										className="w-64px h-auto position-relative" />
									<Text
										id="candidateCIF1664036811357"
										tag="b"
										className="w-100 h-auto position-relative" >
										{t('newcustomers.candidateCIF1664036811357')}
									</Text>
									<Text
										id="candidateEmail1664036811357"
										src="email"
										className="w-100 h-auto position-relative" >
										{t('newcustomers.candidateEmail1664036811357')}
									</Text>
									<Button
										id="candidateValidateButton1664036811357"
										onClick={candidateValidateButton1664036811357OnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('newcustomers.candidateValidateButton1664036811357')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
