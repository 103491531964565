import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Product_addDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const product_add_buttons_positiveOnClick = (options) => {
		run([{"function":"add","what":{"image":"https:\/\/firebasestorage.googleapis.com\/v0\/b\/customers-8d060.appspot.com\/o\/images%2Fplaceholder_cup.png?alt=media&token=61b351d5-2288-4254-8890-4f4a0530ada4","name":"@property.name","available":false,"size":0},"to":"@firebase.firestore.products"},{"function":"hide","dialog":"product_add"}], options)
	}
	const product_add_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"product_add"}], options)
	}

  return (
    
    
			<Modal id="product_add">
					<Modal.Body>
						<Text
							id="addProductInstructions"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('product_add.addProductInstructions')}
						</Text>
						<Input.Text
							id="addProductInput"
							name="text"
							src="@property.name"
						autoComplete="off"
							className="w-auto h-auto position-relative mt-16px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="product_add_buttons_negative"
							onClick={product_add_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('product_add.product_add_buttons_negative')}
						</Button>
						<Button
							id="product_add_buttons_positive"
							onClick={product_add_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('product_add.product_add_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Product_addDialog;
