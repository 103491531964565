import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { A_companiesView, A_companyView, A_locationView, A_newcustomersView, A_oldcustomersView, A_ordersView, A_shippingsView, A_signuperrorView, A_signuppendingView, A_userView, A_usersView, AccessView, AdminView, Admin_accessView, Admin_company_profileView, Admin_configView, Admin_locationView, Admin_locations_errorsView, Admin_productsView, Customer_accessView, Customer_application_formView, Customer_cartView, Customer_historyView, Customer_new_companyView, Customer_productsView, Customer_should_be_invitedView, Customer_unverifiedView, Customer_unverified_invitationView, DashboardView, HistoryView, LoginView, LogoutView, NewcustomersView, OrdersView, ProfileView, Recover_passwordView, SalesView, Sales_accessView, Sales_infoView, Sales_loading_cartView, Sales_ordersView, Sales_productsView, Sales_shippingsView, SignupView, StoreView, TempView } from './pages';
import PrivateRoute from './PrivateRoute';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={AccessView} />
						<Route exact path="/a_companies" component={A_companiesView} />
<Route exact path="/a_companies/:params(.+)" component={A_companiesView} />
						<Route exact path="/a_company" component={A_companyView} />
						<Route exact path="/a_company/:params(.+)" component={A_companyView} />
						<Route exact path="/a_location" component={A_locationView} />
						<Route exact path="/a_location/:params(.+)" component={A_locationView} />
						<PrivateRoute exact path="/a_newcustomers" component={A_newcustomersView} />
						<PrivateRoute exact path="/a_newcustomers/:params(.+)" component={A_newcustomersView} />
						<PrivateRoute exact path="/a_oldcustomers" component={A_oldcustomersView} />
						<PrivateRoute exact path="/a_oldcustomers/:params(.+)" component={A_oldcustomersView} />
						<PrivateRoute exact path="/a_orders" component={A_ordersView} />
						<PrivateRoute exact path="/a_orders/:params(.+)" component={A_ordersView} />
						<Route exact path="/a_shippings" component={A_shippingsView} />
						<Route exact path="/a_shippings/:params(.+)" component={A_shippingsView} />
						<PrivateRoute exact path="/a_signuperror" component={A_signuperrorView} />
						<PrivateRoute exact path="/a_signuperror/:params(.+)" component={A_signuperrorView} />
						<PrivateRoute exact path="/a_signuppending" component={A_signuppendingView} />
						<PrivateRoute exact path="/a_signuppending/:params(.+)" component={A_signuppendingView} />
						<Route exact path="/a_user" component={A_userView} />
						<Route exact path="/a_user/:params(.+)" component={A_userView} />
						<Route exact path="/a_users" component={A_usersView} />
						<Route exact path="/a_users/:params(.+)" component={A_usersView} />
						<Route exact path="/access" component={AccessView} />
						<Route exact path="/access/:params(.+)" component={AccessView} />
						<PrivateRoute exact path="/admin" component={AdminView} />
						<PrivateRoute exact path="/admin/:params(.+)" component={AdminView} />
						<PrivateRoute exact path="/admin_access" component={Admin_accessView} />
						<PrivateRoute exact path="/admin_access/:params(.+)" component={Admin_accessView} />
						<PrivateRoute exact path="/admin_company_profile" component={Admin_company_profileView} />
						<PrivateRoute exact path="/admin_company_profile/:params(.+)" component={Admin_company_profileView} />
						<PrivateRoute exact path="/admin_config" component={Admin_configView} />
						<PrivateRoute exact path="/admin_config/:params(.+)" component={Admin_configView} />
						<PrivateRoute exact path="/admin_location" component={Admin_locationView} />
						<PrivateRoute exact path="/admin_location/:params(.+)" component={Admin_locationView} />
						<PrivateRoute exact path="/admin_locations_errors" component={Admin_locations_errorsView} />
						<PrivateRoute exact path="/admin_locations_errors/:params(.+)" component={Admin_locations_errorsView} />
						<PrivateRoute exact path="/admin_products" component={Admin_productsView} />
						<PrivateRoute exact path="/admin_products/:params(.+)" component={Admin_productsView} />
						<PrivateRoute exact path="/customer_access" component={Customer_accessView} />
						<PrivateRoute exact path="/customer_access/:params(.+)" component={Customer_accessView} />
						<PrivateRoute exact path="/customer_application_form" component={Customer_application_formView} />
						<PrivateRoute exact path="/customer_application_form/:params(.+)" component={Customer_application_formView} />
						<PrivateRoute exact path="/customer_cart" component={Customer_cartView} />
						<PrivateRoute exact path="/customer_cart/:params(.+)" component={Customer_cartView} />
						<PrivateRoute exact path="/customer_history" component={Customer_historyView} />
						<PrivateRoute exact path="/customer_history/:params(.+)" component={Customer_historyView} />
						<Route exact path="/customer_new_company" component={Customer_new_companyView} />
						<Route exact path="/customer_new_company/:params(.+)" component={Customer_new_companyView} />
						<PrivateRoute exact path="/customer_products" component={Customer_productsView} />
						<PrivateRoute exact path="/customer_products/:params(.+)" component={Customer_productsView} />
						<PrivateRoute exact path="/customer_should_be_invited" component={Customer_should_be_invitedView} />
						<PrivateRoute exact path="/customer_should_be_invited/:params(.+)" component={Customer_should_be_invitedView} />
						<PrivateRoute exact path="/customer_unverified" component={Customer_unverifiedView} />
						<PrivateRoute exact path="/customer_unverified/:params(.+)" component={Customer_unverifiedView} />
						<PrivateRoute exact path="/customer_unverified_invitation" component={Customer_unverified_invitationView} />
						<PrivateRoute exact path="/customer_unverified_invitation/:params(.+)" component={Customer_unverified_invitationView} />
						<Route exact path="/dashboard" component={DashboardView} />
						<Route exact path="/dashboard/:params(.+)" component={DashboardView} />
						<PrivateRoute exact path="/history" component={HistoryView} />
						<PrivateRoute exact path="/history/:params(.+)" component={HistoryView} />
						<Route exact path="/login" component={LoginView} />
						<Route exact path="/login/:params(.+)" component={LoginView} />
						<PrivateRoute exact path="/logout" component={LogoutView} />
						<PrivateRoute exact path="/logout/:params(.+)" component={LogoutView} />
						<PrivateRoute exact path="/newcustomers" component={NewcustomersView} />
						<PrivateRoute exact path="/newcustomers/:params(.+)" component={NewcustomersView} />
						<PrivateRoute exact path="/orders" component={OrdersView} />
						<PrivateRoute exact path="/orders/:params(.+)" component={OrdersView} />
						<PrivateRoute exact path="/profile" component={ProfileView} />
						<PrivateRoute exact path="/profile/:params(.+)" component={ProfileView} />
						<Route exact path="/recover_password" component={Recover_passwordView} />
						<Route exact path="/recover_password/:params(.+)" component={Recover_passwordView} />
						<PrivateRoute exact path="/sales" component={SalesView} />
						<PrivateRoute exact path="/sales/:params(.+)" component={SalesView} />
						<PrivateRoute exact path="/sales_access" component={Sales_accessView} />
						<PrivateRoute exact path="/sales_access/:params(.+)" component={Sales_accessView} />
						<PrivateRoute exact path="/sales_info" component={Sales_infoView} />
						<PrivateRoute exact path="/sales_info/:params(.+)" component={Sales_infoView} />
						<PrivateRoute exact path="/sales_loading_cart" component={Sales_loading_cartView} />
						<PrivateRoute exact path="/sales_loading_cart/:params(.+)" component={Sales_loading_cartView} />
						<PrivateRoute exact path="/sales_orders" component={Sales_ordersView} />
						<PrivateRoute exact path="/sales_orders/:params(.+)" component={Sales_ordersView} />
						<PrivateRoute exact path="/sales_products" component={Sales_productsView} />
						<PrivateRoute exact path="/sales_products/:params(.+)" component={Sales_productsView} />
						<PrivateRoute exact path="/sales_shippings" component={Sales_shippingsView} />
						<PrivateRoute exact path="/sales_shippings/:params(.+)" component={Sales_shippingsView} />
						<Route exact path="/signup" component={SignupView} />
						<Route exact path="/signup/:params(.+)" component={SignupView} />
						<PrivateRoute exact path="/store" component={StoreView} />
						<PrivateRoute exact path="/store/:params(.+)" component={StoreView} />
						<Route exact path="/temp" component={TempView} />
						<Route exact path="/temp/:params(.+)" component={TempView} />

          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
