import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Location_deleteDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const location_delete_buttons_positiveOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.companies.(@property.companyID).locations.(@property.locationID)"},{"function":"set","what":"@firebase.firestore.companies.(@property.companyID).n_locations","value":"@cookie.n_locations.-1"},{"function":"hide","dialog":"location_delete"}], options)
	}
	const location_delete_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"location_delete"}], options)
	}

  return (
    
    
			<Modal id="location_delete">
					<Modal.Body>
						<Text
							id="locationDelete"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('location_delete.locationDelete')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="location_delete_buttons_negative"
							onClick={location_delete_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('location_delete.location_delete_buttons_negative')}
						</Button>
						<Button
							id="location_delete_buttons_positive"
							onClick={location_delete_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('location_delete.location_delete_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Location_deleteDialog;
