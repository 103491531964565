import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text } from '../components';
import { useTranslation } from 'react-i18next';

export const Admin_configView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('admin_config.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="customerConfigBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px" >
				<Text
					id="configTitle"
					tag="h2"
					className="w-100 h-auto position-relative ff-antonregular" >
					{t('admin_config.configTitle')}
				</Text>
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
