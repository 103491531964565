import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Button } from '../components';
import retrato from '../assets/logos/retrato.png';
import { useTranslation } from 'react-i18next';

export const Customer_should_be_invitedView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const unverifiedProfileButton1647258686571OnClick = (options) => {
		run([{"function":"goto","view":"profile"}], options)
	}
	const loginLogo1647258686571OnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('customer_should_be_invited.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="customerUnverifiedBody1647258686571"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white" >
				<Group
					id="unverifiedHeader1647258686571"
					direction="vertical"
					className="w-100 min-h-300px h-300px align-start position-relative bg-pink" >
					<Image
						id="loginLogo1647258686571"
						onClick={loginLogo1647258686571OnClick}
						src={retrato}
						className="w-auto min-h-300px h-300px align-center valign-end position-absolute-bottom mb--120px" />
				</Group>
				<Group
					id="unverifiedBody1647258686571"
					direction="vertical"
					className="w-100 h-auto align-start valign-center position-relative px-32px pt-150px pb-32px" >
					<Text
						id="unverifiedTitle1647258686571"
						className="w-100 h-auto position-relative my-32px f-54px ff-antonregular text-center" >
						{t('customer_should_be_invited.unverifiedTitle1647258686571')}
					</Text>
					<Text
						id="unverifiedSubtitle1647258686571"
						className="w-100 h-auto position-relative mb-32px f-32px ff-antonregular text-center" >
						{t('customer_should_be_invited.unverifiedSubtitle1647258686571')}
					</Text>
					<Button
						id="unverifiedProfileButton1647258686571"
						onClick={unverifiedProfileButton1647258686571OnClick}
						variant="text-on-accent"
						className="w-auto h-auto border-radius-250px align-center position-relative bg-accent mt-48px" >
						{t('customer_should_be_invited.unverifiedProfileButton1647258686571')}
					</Button>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
