import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Input_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const input_dialog_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@(@property.path)","value":"@property.value"},{"function":"hide","dialog":"input_dialog"}], options)
	}
	const input_dialog_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"input_dialog"}], options)
	}

  return (
    
    
			<Modal id="input_dialog">
					<Modal.Body>
						<Text
							id="inputDialogInstructions"
							src="@property.msg"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('input_dialog.inputDialogInstructions')}
						</Text>
						<Input.Text
							id="inputDialogInput"
							name="file"
							src="@property.value"
						autoComplete="off"
							className="w-100 h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="input_dialog_buttons_negative"
							onClick={input_dialog_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('input_dialog.input_dialog_buttons_negative')}
						</Button>
						<Button
							id="input_dialog_buttons_positive"
							onClick={input_dialog_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('input_dialog.input_dialog_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Input_dialogDialog;
