import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Company_leaveDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const company_leave_buttons_positiveOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.companies.(@property.deletedCompanyID).users.(@cookie.userID)"},{"function":"delete","what":"@firebase.firestore.customers.(@cookie.userID).companies.(@property.deletedCompany.cif)"},{"function":"set","what":"@cookie.n_companies","value":"@cookie.n_companies.-1"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).n_companies","value":"@cookie.n_companies"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).current_company","value":null,"if":{"what":"@firebase.firestore.customers.(@cookie.userID).current_company","is":"@property.deletedCompanyID"}},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).status","value":"uncompanied","if":{"what":"@cookie.n_companies","is":0}},{"function":"hide","dialog":"company_leave"}], options)
	}
	const company_leave_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"company_leave"}], options)
	}

  return (
    
    
			<Modal id="company_leave">
					<Modal.Body>
						<Text
							id="companyLeaveText"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('company_leave.companyLeaveText')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="company_leave_buttons_negative"
							onClick={company_leave_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('company_leave.company_leave_buttons_negative')}
						</Button>
						<Button
							id="company_leave_buttons_positive"
							onClick={company_leave_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('company_leave.company_leave_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Company_leaveDialog;
