import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, Image, Input, List, Card, Lottie } from '../components';
import { useEffect } from 'react';
import anim_wait_white from '../assets/animation/anim_wait_white.json';
import ic_edit_pinkgrey from '../assets/iconset/ic_edit_pinkgrey.svg';
import { useTranslation } from 'react-i18next';

export const Admin_company_profileView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_company_profileParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( admin_company_profileParams, [])
	const admin_company_profileOnLoaded = (options) => {
		run([{"function":"goto","view":"customer_access","if":{"what":"@cookie.type","is":"customer","else":[{"function":"set","what":"@property.company_name","value":"@cookie.company.name"}]}},{"function":"set","what":"@property.companyCIF","value":"@cookie.companyID"}], options)
	}
	useEffect(admin_company_profileOnLoaded, [])
	const salesCustomerCard1655421092110OnClick = (options) => {
		run([{"function":"set","what":"@cookie.salesLocationID","value":"@element.locationList.selected"},{"function":"set","what":"@cookie.locationID","value":"@element.locationList.selected"},{"function":"set","what":"@cookie.location","value":"@element.locationList.value"},{"function":"set","what":"@cookie.orderName","value":"(@cookie.company.name) - (@cookie.location.name), (@cookie.location.city)"},{"function":"goto","view":"admin_location"}], options)
	}
	const holdedImportSingleButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"holded_import_location"}], options)
	}
	const holdedSyncButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.loading","value":true},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/syncHoldedCompany\n","params":{"companyID":"@cookie.companyID"},"method":"post","response":"json-api","into":"@database.response","onsuccess":[{"function":"set","what":"@property.loading","value":false}],"onerror":[{"function":"set","what":"@property.loading","value":false}]}], options)
	}
	const adminEditCompanyCIFOnLostFocus = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@property.checkCIF","value":"@firebase.firestore.companies.(@property.companyCIF)"},{"function":"do","what":[{"function":"set","what":"@property.companyCIF","value":null},{"function":"set","what":"@property.msg","value":"company_already_exists"},{"function":"popup","dialog":"admin_new_company_review"}],"if":{"what":"@property.checkCIF","is_not":null},"comments":"If this CIF already exists"},{"function":"do","what":[{"function":"set","what":"@property.newCompany","value":"@firebase.firestore.companies.(@property.companyID)"},{"function":"add","what":{"cif":"@property.companyCIF","created_at":"@property.newCompany.created_at","created_by":"@property.newCompany.created_by"},"to":"@firebase.firestore.companies","key":"@property.companyCIF"}],"if":{"what":"@property.checkCIF","is":null},"comments":"If DIDN'T exist"}]}], options)
	}
	const companyProfileTitleEditOnClick = (options) => {
		run([{"function":"set","what":"@property.msg","value":"Introduce el nuevo nombre. Tal cual lo escribas, lo ver\u00e1 el usuario en su perfil."},{"function":"set","what":"@property.value","value":"@firebase.firestore.companies.(@cookie.companyID).name"},{"function":"set","what":"@property.path","value":"firebase.firestore.companies.(@cookie.companyID).name"},{"function":"popup","dialog":"input_dialog"}], options)
	}
	const companyProfileBackButtonOnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_company_profile.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="companyProfileBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="companyProfileWrapper"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="companyProfileHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Icon
							id="companyProfileBackButton"
							onClick={companyProfileBackButtonOnClick}
							src="arrow-left"
							className="w-auto h-auto valign-center position-relative me-16px"
						/>
						<Text
							id="companyProfileTitle"
							tag="h2"
							src="@firebase.firestore.companies.(@cookie.companyID).name"
							className="w-auto h-auto position-relative py-6px ff-antonregular" >
							{t('admin_company_profile.companyProfileTitle')}
						</Text>
						<Image
							id="companyProfileTitleEdit"
							onClick={companyProfileTitleEditOnClick}
							src={ic_edit_pinkgrey}
							className="w-24px h-auto valign-center position-relative ms-8px" />
					</Group>
					<Group
						id="companyDataFormWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="companyDataFormGroup"
							direction="auto"
							className="w-100 h-auto border-radius-5px position-relative p-16px" >
							<Group
								id="locationDataFormHeader1663153828367"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Input.Text
									id="adminEditCompanyCIF"
									label={t('admin_company_profile.adminEditCompanyCIF.label')}
									name="text"
									src="@property.companyCIF"
								autoComplete="off"
								onBlur={adminEditCompanyCIFOnLostFocus}
									className="max-w-500px min-w-300px w-auto h-auto position-relative me-16px mb-8px" />
								<Group
									id="component1663153828367"
									visible={false}
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
							</Group>
							<Group
								id="component1663153828368"
								direction="auto"
								className="w-100 h-auto border-primary border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mt-24px" >
								<Group
									id="component1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="locationFormLabelName1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelName1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.name"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoAddress1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelAddress1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelAddress1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.address"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCP1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCP1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelCP1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.cp"
										className="min-w-150px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCity1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCity1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelCity1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.city"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoProvince1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelProvince1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelProvince1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.province"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCountry1663153828367"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCountry1663153828367"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelCountry1663153828367')}
									</Text>
									<Text
										id="component1663153828367"
										src="@cookie.location.country"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828367')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoID1663153828368"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelID1663153828368"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_company_profile.locationFormLabelID1663153828368')}
									</Text>
									<Text
										id="component1663153828368"
										src="@cookie.locationID"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_company_profile.component1663153828368')}
									</Text>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="profileLocationsGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="profileLocationsHeader"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Text
								id="profileLocationsHeaderTitle"
								tag="h3"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-librebaskervillebold" >
								{t('admin_company_profile.profileLocationsHeaderTitle')}
							</Text>
							<Text
								id="holdedSyncButton"
								onClick={holdedSyncButtonOnClick}
								className="w-auto h-auto border-radius-50px valign-center position-relative bg-primary px-16px py-6px" >
								{t('admin_company_profile.holdedSyncButton')}
							</Text>
							<Text
								id="holdedImportSingleButton"
								onClick={holdedImportSingleButtonOnClick}
								className="w-auto h-auto border-radius-50px valign-center position-relative bg-primary px-16px py-6px ms-8px" >
								{t('admin_company_profile.holdedImportSingleButton')}
							</Text>
						</Group>
						<List
							id="locationList"
							direction="auto"
							src="@firebase.firestore.locations"
							filter={{"what":"company","is":"@cookie.companyID"}}
							columns={"auto"}
							className="w-fill h-auto position-relative mt-12px" >
							<Card
								id="salesCustomerCard1655421092110"
								onClick={salesCustomerCard1655421092110OnClick}
								className="min-w-300px w-fill h-auto border-radius-10px position-relative m-4px" >
								<Card.Body>
										<Group
											id="salesCustomerName1655421092110"
											direction="vertical"
											className="w-100 h-auto position-relative" >
											<Text
												id="salesCustomerNameLabel1655421092110"
												src="name"
												className="w-fill h-auto position-relative text-primary-text ff-librebaskervillebold" >
												{t('admin_company_profile.salesCustomerNameLabel1655421092110')}
											</Text>
											<Text
												id="salesCustomerDescriptionLabel1655421092110"
												tag="small"
												src="address"
												className="w-fill h-auto position-relative text-secondary-text ff-librebaskervillebold" >
												{t('admin_company_profile.salesCustomerDescriptionLabel1655421092110')}
											</Text>
											<Text
												id="salesCustomerDescriptionLabel1655421410356"
												tag="small"
												className="w-fill h-auto position-relative text-secondary-text ff-librebaskervillebold" >
												{t('admin_company_profile.salesCustomerDescriptionLabel1655421410356')}
											</Text>
										</Group>
								</Card.Body>
							</Card>
						</List>
					</Group>
				</Group>
			</Group>
			<Group
				id="companyProfileCurtain"
				if={{"what":"@property.loading","is":true}}
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-absolute bg-darken" >
				<Lottie
					id="companyProfileCurtainLottie"
					src={anim_wait_white}
					className="w-114px h-auto align-center valign-center position-absolute" />
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
