import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, Input, List, Lottie } from '../components';
import { useEffect } from 'react';
import anim_wait_white from '../assets/animation/anim_wait_white.json';
import { useTranslation } from 'react-i18next';

export const Admin_locationView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_locationParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( admin_locationParams, [])
	const admin_locationOnLoaded = (options) => {
		run([{"function":"goto","view":"customer_access","if":{"what":"@cookie.type","is":"customer","else":[{"function":"set","what":"@property.company_name","value":"@cookie.company.name"}]}},{"function":"set","what":"@cookie.location","value":"@firebase.firestore.locations.(@cookie.locationID)"}], options)
	}
	useEffect(admin_locationOnLoaded, [])
	const historyOrderActionDelete1659946899037OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.historyOrderList.selected"},{"function":"set","what":"@property.status","value":"@firebase.firestore.orders.(@cookie.orderID).order_status"},{"function":"popup","dialog":"order_delete","if":{"what":"@property.status","is":"confirmed","else":[{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"@property.status"},{"function":"set","what":"@property.error","value":"El pedido ya no se puede eliminar"},{"function":"popup","dialog":"error_dialog"}]}}], options)
	}
	const historyOrderActionOpen1659946899037OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.historyOrderList.selected"},{"function":"set","what":"@property.status","value":"@firebase.firestore.orders.(@cookie.orderID).order_status"},{"function":"do","what":[{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"},{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"open"},{"function":"goto","view":"orders"}],"if":{"what":"@property.status","is":"confirmed","else":[{"function":"set","what":"@database.orders.(@cookie.orderID).order_status","value":"@property.status"},{"function":"set","what":"@property.error","value":"El pedido ya no se puede reabrir, pues est\u00e1 siendo procesado."},{"function":"popup","dialog":"error_dialog"}]}}], options)
	}
	const openOrderActionDelete1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const openOrderActionChangeDelivery1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}], options)
	}
	const openOrderActionEdit1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"goto","view":"store"}], options)
	}
	const openOrderActionConfirm1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderPlusButton1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.+1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.+1"}]}], options)
	}
	const openOrderMinusButton1659946416749OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.openOrderList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.-1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id).units","value":"@property.units.-1"}],"if":{"what":"@field.units","more_than":1}},{"function":"do","what":[{"function":"delete","what":"@database.orders.(@cookie.orderID).cart.(@field.id)"},{"function":"delete","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@field.id)"}],"if":{"what":"@field.units","is":1}}], options)
	}
	const holdedSyncButton1659973269208OnClick = (options) => {
		run([{"function":"set","what":"@property.loading","value":true},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/syncHoldedLocation","params":{"locationID":"@cookie.locationID","contactId":"@firebase.firestore.locations.(@cookie.locationID).holded_id"},"method":"post","response":"json-api","into":"@database.response","onsuccess":[{"function":"set","what":"@property.loading","value":false},{"function":"set","what":"@cookie.location","value":"@firebase.firestore.locations.(@cookie.locationID)"}],"onerror":[{"function":"set","what":"@property.loading","value":false}]}], options)
	}
	const locationProfileBackButtonOnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_location.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="locationProfileBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="locationProfileWrapper"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="locationProfileHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Icon
							id="locationProfileBackButton"
							onClick={locationProfileBackButtonOnClick}
							src="arrow-left"
							className="w-auto h-auto valign-center position-relative me-16px"
						/>
						<Text
							id="locationProfileTitle"
							tag="h2"
							className="w-auto h-auto position-relative py-6px ff-antonregular" >
							{t('admin_location.locationProfileTitle')}
						</Text>
					</Group>
					<Group
						id="locationDataFormWrapper"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="locationDataFormGroup"
							direction="auto"
							className="w-100 h-auto border-radius-5px position-relative p-16px" >
							<Group
								id="locationDataFormHeader"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Input.Text
									id="adminEditLocationHoldedID"
									label={t('admin_location.adminEditLocationHoldedID.label')}
									name="text"
									src="@firebase.firestore.locations.(@cookie.locationID).holded_id"
								autoComplete="off"
									className="max-w-500px min-w-300px w-auto h-auto position-relative me-16px mb-8px" />
								<Group
									id="component1659973232022"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Text
									id="holdedSyncButton1659973269208"
									onClick={holdedSyncButton1659973269208OnClick}
									className="w-auto h-auto border-radius-50px valign-center position-relative bg-primary px-16px py-6px" >
									{t('admin_location.holdedSyncButton1659973269208')}
								</Text>
							</Group>
							<Group
								id="component1659973376121"
								direction="auto"
								className="w-100 h-auto border-primary border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mt-24px" >
								<Group
									id="component1659974343761"
									direction="horizontal"
									className="w-100 h-auto position-relative" >
									<Text
										id="locationFormLabelName"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelName')}
									</Text>
									<Text
										id="component1659974343761"
										src="@cookie.location.name"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659974343761')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoAddress"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelAddress"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelAddress')}
									</Text>
									<Text
										id="component1659973696106"
										src="@cookie.location.address"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659973696106')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCP"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCP"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelCP')}
									</Text>
									<Text
										id="component1659979587831"
										src="@cookie.location.cp"
										className="min-w-150px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659979587831')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCity"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCity"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelCity')}
									</Text>
									<Text
										id="component1659973753885"
										src="@cookie.location.city"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659973753885')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoProvince"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelProvince"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelProvince')}
									</Text>
									<Text
										id="component1659973790862"
										src="@cookie.location.province"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659973790862')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoCountry"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelCountry"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelCountry')}
									</Text>
									<Text
										id="component1659974289910"
										src="@cookie.location.country"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659974289910')}
									</Text>
								</Group>
								<Group
									id="locationFormInfoID"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="locationFormLabelID"
										tag="b"
										className="min-w-150px w-auto h-auto position-relative ff-label" >
										{t('admin_location.locationFormLabelID')}
									</Text>
									<Text
										id="component1659974369181"
										src="@cookie.locationID"
										className="min-w-120px w-fill h-auto position-relative ff-label" >
										{t('admin_location.component1659974369181')}
									</Text>
								</Group>
							</Group>
						</Group>
					</Group>
					<Group
						id="profileLocationsOpenOrdersGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="profileLocationOpenOrdersHeader"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Text
								id="profileLocationsHeaderTitle1659946314026"
								tag="h3"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-librebaskervillebold" >
								{t('admin_location.profileLocationsHeaderTitle1659946314026')}
							</Text>
						</Group>
						<List
							id="openOrderList1659946416749"
							src="@firebase.firestore.orders"
							filter={{"what":"order_status","is":"open","and":{"what":"location_id","is":"@cookie.locationID"}}}
							className="w-100 h-auto position-relative mt-32px" >
							<Group
								id="openOrderCard1659946416749"
								direction="vertical"
								className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
								<Group
									id="openOrderCardHeader1659946416749"
									direction="auto"
									className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
									<Group
										id="openOrderCreationGroup1659946416749"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderCreationTitle1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderCreationTitle1659946416749')}
										</Text>
										<Text
											id="openOrderCreationValue1659946416749"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="created_at"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderDeliveryGroup1659946416749"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderDeliveryTitle1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderDeliveryTitle1659946416749')}
										</Text>
										<Text
											id="openOrderDeliveryValue1659946416749"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="delivery_date"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderBilledGroup1659946416749"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderBilledTitle1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderBilledTitle1659946416749')}
										</Text>
										<Text
											id="openOrderBilledValue1659946416749"
											tag="small"
											src="company"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderBilledValue1659946416749')}
										</Text>
									</Group>
									<Group
										id="openOrderLocationGroup1659946416749"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderLocationTitle1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderLocationTitle1659946416749')}
										</Text>
										<Text
											id="openOrderLocationValue1659946416749"
											tag="small"
											src="location_name"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderLocationValue1659946416749')}
										</Text>
									</Group>
									<Group
										id="component1659946416749"
										direction="vertical"
										className="w-fill h-auto position-relative" >
									</Group>
									<Group
										id="openOrderIdGroup1659946416749"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderIdTitle1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderIdTitle1659946416749')}
										</Text>
										<Text
											id="openOrderIdValue1659946416749"
											tag="small"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderIdValue1659946416749')}
										</Text>
									</Group>
								</Group>
								<Group
									id="openOrderCardContent1659946416749"
									direction="auto"
									className="w-100 h-auto position-relative p-8px" >
									<Group
										id="openOrderCardCart1659946416749"
										direction="vertical"
										className="min-w-300px w-fill h-auto position-relative" >
										<List
											id="openOrderCartList1659946416749"
											src="@firebase.firestore.orders.(@field.id).cart"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRow1659946416749"
												direction="horizontal"
												className="w-100 h-auto position-relative" >
												<Group
													id="openOrderCartListRowInput1659946416749"
													direction="horizontal"
													className="w-auto h-auto position-relative me-16px" >
													<Icon
														id="openOrderMinusButton1659946416749"
														onClick={openOrderMinusButton1659946416749OnClick}
														src="minus-circle-outline"
														color="var(--primary-dark)"
														className="w-auto h-auto valign-center position-relative"
													/>
													<Text
														id="openOrderCartListRowUnits1659946416749"
														className="w-40px h-auto position-relative ff-label text-center" >
														{t('admin_location.openOrderCartListRowUnits1659946416749')}
													</Text>
													<Icon
														id="openOrderPlusButton1659946416749"
														onClick={openOrderPlusButton1659946416749OnClick}
														src="plus-circle-outline"
														color="var(--primary-dark)"
														className="w-auto h-auto valign-center position-relative"
													/>
												</Group>
												<Text
													id="openOrderCartListRowName1659946416749"
													src="name"
													className="w-fill h-auto position-relative ff-label" >
													{t('admin_location.openOrderCartListRowName1659946416749')}
												</Text>
											</Group>
										</List>
									</Group>
									<Group
										id="openOrderCardActions1659946416749"
										direction="vertical"
										className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
										<Text
											id="openOrderActionConfirm1659946416749"
											onClick={openOrderActionConfirm1659946416749OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
											{t('admin_location.openOrderActionConfirm1659946416749')}
										</Text>
										<Text
											id="openOrderActionEdit1659946416749"
											onClick={openOrderActionEdit1659946416749OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
											{t('admin_location.openOrderActionEdit1659946416749')}
										</Text>
										<Text
											id="openOrderActionChangeDelivery1659946416749"
											onClick={openOrderActionChangeDelivery1659946416749OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
											{t('admin_location.openOrderActionChangeDelivery1659946416749')}
										</Text>
										<Text
											id="openOrderActionDelete1659946416749"
											onClick={openOrderActionDelete1659946416749OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
											{t('admin_location.openOrderActionDelete1659946416749')}
										</Text>
									</Group>
								</Group>
							</Group>
						</List>
					</Group>
					<Group
						id="profileLocationsConfirmedOrdersGroup"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="profileLocationOpenOrdersHeader1659946852337"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Text
								id="profileLocationsHeaderTitle1659946852337"
								tag="h3"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-librebaskervillebold" >
								{t('admin_location.profileLocationsHeaderTitle1659946852337')}
							</Text>
						</Group>
						<List
							id="historyOrderList1659946899037"
							src="@firebase.firestore.orders"
							filter={{"what":"order_status","is":"confirmed","and":{"what":"location_id","is":"@cookie.locationID"}}}
							className="w-100 h-auto position-relative mt-32px" >
							<Group
								id="historyOrderCard1659946899037"
								direction="vertical"
								className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
								<Group
									id="historyOrderCardHeader1659946899037"
									direction="auto"
									className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
									<Group
										id="openOrderCreationGroup1659946899037"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderCreationTitle1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderCreationTitle1659946899037')}
										</Text>
										<Text
											id="openOrderCreationValue1659946899037"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="created_at"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderDeliveryGroup1659946899037"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderDeliveryTitle1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderDeliveryTitle1659946899037')}
										</Text>
										<Text
											id="openOrderDeliveryValue1659946899037"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="delivery_date"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderBilledGroup1659946899037"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderBilledTitle1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderBilledTitle1659946899037')}
										</Text>
										<Text
											id="openOrderBilledValue1659946899037"
											tag="small"
											src="company"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderBilledValue1659946899037')}
										</Text>
									</Group>
									<Group
										id="openOrderLocationGroup1659946899037"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderLocationTitle1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderLocationTitle1659946899037')}
										</Text>
										<Text
											id="openOrderLocationValue1659946899037"
											tag="small"
											src="location_name"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderLocationValue1659946899037')}
										</Text>
									</Group>
									<Group
										id="component1659946899037"
										direction="vertical"
										className="w-fill h-auto position-relative" >
									</Group>
									<Group
										id="openOrderIdGroup1659946899037"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderIdTitle1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderIdTitle1659946899037')}
										</Text>
										<Text
											id="openOrderIdValue1659946899037"
											tag="small"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderIdValue1659946899037')}
										</Text>
									</Group>
								</Group>
								<Group
									id="historyOrderCardContent1659946899037"
									direction="auto"
									className="w-100 h-auto position-relative p-8px" >
									<Group
										id="historyOrderCardCart1659946899037"
										direction="vertical"
										className="w-fill h-auto position-relative" >
										<List
											id="openOrderCartList1659946899037"
											src="@firebase.firestore.orders.(@field.id).cart"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRow1659946899037"
												direction="horizontal"
												className="w-100 h-auto position-relative" >
												<Text
													id="openOrderCartListRowUnits1659946899037"
													className="w-50px h-auto position-relative me-12px ff-label" >
													{t('admin_location.openOrderCartListRowUnits1659946899037')}
												</Text>
												<Text
													id="openOrderCartListRowName1659946899037"
													src="name"
													className="w-fill h-auto position-relative ff-label" >
													{t('admin_location.openOrderCartListRowName1659946899037')}
												</Text>
											</Group>
										</List>
									</Group>
									<Group
										id="historyOrderCardActions1659946899037"
										direction="vertical"
										className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
										<Text
											id="historyOrderActionOpen1659946899037"
											if={{"what":"@field.order_status","is":"confirmed"}}
											onClick={historyOrderActionOpen1659946899037OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px text-center" >
											{t('admin_location.historyOrderActionOpen1659946899037')}
										</Text>
										<Text
											id="historyOrderActionDelete1659946899037"
											if={{"what":"@field.order_status","is":"confirmed"}}
											onClick={historyOrderActionDelete1659946899037OnClick}
											tag="small"
											className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mt-8px text-center" >
											{t('admin_location.historyOrderActionDelete1659946899037')}
										</Text>
									</Group>
								</Group>
							</Group>
						</List>
					</Group>
					<Group
						id="profileLocationsConfirmedOrdersGroup1659946988344"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="profileLocationOpenOrdersHeader1659946988344"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Text
								id="profileLocationsHeaderTitle1659946988344"
								tag="h3"
								className="min-w-300px w-fill h-auto position-relative py-6px ff-librebaskervillebold" >
								{t('admin_location.profileLocationsHeaderTitle1659946988344')}
							</Text>
						</Group>
						<List
							id="historyOrderList1659946988344"
							src="@firebase.firestore.orders"
							filter={{"what":"order_status","is":"processed","and":{"what":"location_id","is":"@cookie.locationID"}}}
							className="w-100 h-auto position-relative mt-32px" >
							<Group
								id="historyOrderCard1659946988344"
								direction="vertical"
								className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end border-radius-15px position-relative bg-card_bg mb-24px" >
								<Group
									id="historyOrderCardHeader1659946988344"
									direction="auto"
									className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
									<Group
										id="openOrderCreationGroup1659946988344"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderCreationTitle1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderCreationTitle1659946988344')}
										</Text>
										<Text
											id="openOrderCreationValue1659946988344"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="created_at"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderDeliveryGroup1659946988344"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px me-24px" >
										<Text
											id="openOrderDeliveryTitle1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderDeliveryTitle1659946988344')}
										</Text>
										<Text
											id="openOrderDeliveryValue1659946988344"
											format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
											tag="small"
											src="delivery_date"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('')}
										</Text>
									</Group>
									<Group
										id="openOrderBilledGroup1659946988344"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderBilledTitle1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderBilledTitle1659946988344')}
										</Text>
										<Text
											id="openOrderBilledValue1659946988344"
											tag="small"
											src="company"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderBilledValue1659946988344')}
										</Text>
									</Group>
									<Group
										id="openOrderLocationGroup1659946988344"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderLocationTitle1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderLocationTitle1659946988344')}
										</Text>
										<Text
											id="openOrderLocationValue1659946988344"
											tag="small"
											src="location_name"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderLocationValue1659946988344')}
										</Text>
									</Group>
									<Group
										id="component1659946988344"
										direction="vertical"
										className="w-fill h-auto position-relative" >
									</Group>
									<Group
										id="openOrderIdGroup1659946988344"
										direction="vertical"
										className="w-auto h-auto position-relative p-8px" >
										<Text
											id="openOrderIdTitle1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-dark ff-label" >
											{t('admin_location.openOrderIdTitle1659946988344')}
										</Text>
										<Text
											id="openOrderIdValue1659946988344"
											tag="small"
											className="w-100 h-auto position-relative text-primary-text ff-label" >
											{t('admin_location.openOrderIdValue1659946988344')}
										</Text>
									</Group>
								</Group>
								<Group
									id="historyOrderCardContent1659946988344"
									direction="auto"
									className="w-100 h-auto position-relative p-8px" >
									<Group
										id="historyOrderCardCart1659946988344"
										direction="vertical"
										className="w-fill h-auto position-relative" >
										<List
											id="openOrderCartList1659946988344"
											src="@firebase.firestore.orders.(@field.id).cart"
											className="w-100 h-auto position-relative" >
											<Group
												id="openOrderCartListRow1659946988344"
												direction="horizontal"
												className="w-100 h-auto position-relative" >
												<Text
													id="openOrderCartListRowUnits1659946988344"
													className="w-50px h-auto position-relative me-12px ff-label" >
													{t('admin_location.openOrderCartListRowUnits1659946988344')}
												</Text>
												<Text
													id="openOrderCartListRowName1659946988344"
													src="name"
													className="w-fill h-auto position-relative ff-label" >
													{t('admin_location.openOrderCartListRowName1659946988344')}
												</Text>
											</Group>
										</List>
									</Group>
									<Group
										id="historyOrderCardActions1659946988344"
										direction="vertical"
										className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									</Group>
								</Group>
							</Group>
						</List>
					</Group>
				</Group>
			</Group>
			<Group
				id="companyProfileCurtain1659945988081"
				if={{"what":"@property.loading","is":true}}
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-absolute bg-darken" >
				<Lottie
					id="companyProfileCurtainLottie1659945988081"
					src={anim_wait_white}
					className="w-114px h-auto align-center valign-center position-absolute" />
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
