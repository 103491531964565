import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Text, List, Card, Lottie } from '../components';
import { useEffect } from 'react';
import anim_wait_white from '../assets/animation/anim_wait_white.json';
import { useTranslation } from 'react-i18next';

export const Admin_locations_errorsView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_locations_errorsParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( admin_locations_errorsParams, [])
	const admin_locations_errorsOnLoaded = (options) => {
		run([{"function":"goto","view":"customer_access","if":{"what":"@cookie.type","is":"customer","else":[{"function":"set","what":"@property.company_name","value":"@cookie.company.name"}]}}], options)
	}
	useEffect(admin_locations_errorsOnLoaded, [])
	const salesCustomerCard1661553450223OnClick = (options) => {
		run([{"function":"set","what":"@cookie.salesLocationID","value":"@element.locationList.selected"},{"function":"set","what":"@cookie.locationID","value":"@element.locationList.selected"},{"function":"set","what":"@cookie.location","value":"@element.locationList.value"},{"function":"set","what":"@cookie.orderName","value":"(@cookie.company.name) - (@cookie.location.name), (@cookie.location.city)"},{"function":"goto","view":"admin_location"}], options)
	}
	const companyProfileBackButton1661553450222OnClick = (options) => {
		run([{"function":"back"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin_locations_errors.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="locationErrorsBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="companyProfileWrapper1661553450223"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Group
						id="locationErrorsHeader"
						direction="auto"
						className="w-100 h-auto position-relative" >
						<Icon
							id="companyProfileBackButton1661553450222"
							onClick={companyProfileBackButton1661553450222OnClick}
							src="arrow-left"
							className="w-auto h-auto valign-center position-relative me-16px"
						/>
						<Text
							id="locationErrorsTitle"
							tag="h2"
							className="w-auto h-auto position-relative py-6px ff-antonregular" >
							{t('admin_locations_errors.locationErrorsTitle')}
						</Text>
					</Group>
					<Group
						id="profileLocationsGroup1661553450223"
						direction="vertical"
						className="w-100 h-auto position-relative mt-32px" >
						<List
							id="locationList1661553450223"
							direction="auto"
							src="@firebase.firestore.locations"
							filter={{"what":"holded_id","is":null}}
							columns={"auto"}
							className="w-fill h-auto position-relative mt-12px" >
							<Card
								id="salesCustomerCard1661553450223"
								onClick={salesCustomerCard1661553450223OnClick}
								className="min-w-300px w-fill h-auto border-radius-10px position-relative m-4px" >
								<Card.Body>
										<Group
											id="salesCustomerName1661553450223"
											direction="vertical"
											className="w-100 h-auto position-relative" >
											<Text
												id="salesCustomerNameLabel1661553450222"
												src="name"
												className="w-fill h-auto position-relative text-primary-text ff-librebaskervillebold" >
												{t('admin_locations_errors.salesCustomerNameLabel1661553450222')}
											</Text>
											<Text
												id="salesCustomerDescriptionLabel1661553450223"
												tag="small"
												src="address"
												className="w-fill h-auto position-relative text-secondary-text ff-librebaskervillebold" >
												{t('admin_locations_errors.salesCustomerDescriptionLabel1661553450223')}
											</Text>
											<Text
												id="salesCustomerDescriptionLabel1661553450223"
												tag="small"
												className="w-fill h-auto position-relative text-secondary-text ff-librebaskervillebold" >
												{t('admin_locations_errors.salesCustomerDescriptionLabel1661553450223')}
											</Text>
										</Group>
								</Card.Body>
							</Card>
						</List>
					</Group>
				</Group>
			</Group>
			<Group
				id="companyProfileCurtain1661553450223"
				if={{"what":"@property.loading","is":true}}
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-absolute bg-darken" >
				<Lottie
					id="companyProfileCurtainLottie1661553450223"
					src={anim_wait_white}
					className="w-114px h-auto align-center valign-center position-absolute" />
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
