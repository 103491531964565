import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFunctions } from '../hooks';
import DoyoBadge from '../components/DoyoBadge';

// Custom imports:
import { Group, Nav, Text, Image } from '../components';
import horizontal from '../assets/logos/horizontal.png';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export const Sales_wrapperWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { run } = useFunctions();
  
  // Custom consts:

  // Custom functions:
	const backToAdminButtonOnClick = (options) => {
		run([{"function":"goto","view":"admin"}], options)
	}
	const Sales_menuItemsOptAdminLogout1649061924240OnClick = (options) => {
		run([{"function":"goto","view":"logout"}], options)
	}
	const Sales_menuItemsOptOrders1649061924240OnClick = (options) => {
		run([{"function":"goto","view":"sales_info"}], options)
	}
	const Sales_menuItemsOptCustomersAdmin1649061924240OnClick = (options) => {
		run([{"function":"goto","view":"sales"}], options)
	}

  return (
    <main id="Sales_wrapperWrapper" className="view h-fill">
        
				<Navbar bg="primary" expand="lg" sticky="top" className="z-index-10 px-3">
					<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 rounded-circle me-3 p-1" />
					<Navbar.Offcanvas id="basic-navbar-nav" className="w-auto d-lg-none">
					<Offcanvas.Body className="p-0">
						<Nav
							id="sales_wrapper_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('sales_menu.items.optCustomersAdmin1649061924240.title')} onClick={Sales_menuItemsOptCustomersAdmin1649061924240OnClick} href="sales" />
							<Nav.Item title={t('sales_menu.items.optOrders1649061924240.title')} onClick={Sales_menuItemsOptOrders1649061924240OnClick} href="sales_info" />
							<Nav.Item title={t('sales_menu.items.optAdminLogout1649061924240.title')} onClick={Sales_menuItemsOptAdminLogout1649061924240OnClick} href="logout" />
						</Nav>
						<Group
							id="sales_wrapper_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Text
								id="backToAdminButton"
								if={{"what":"@cookie.type","is":"admin"}}
								onClick={backToAdminButtonOnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent px-8px py-4px" >
								{t('sales_wrapper.backToAdminButton')}
							</Text>
						</Group>
					</Offcanvas.Body>
					</Navbar.Offcanvas>
						<Navbar.Brand>
							<Link to="/">
								<Image
									id="sales_wrapper_actionbar_logo"
									src={horizontal}
									className="w-auto min-h-40px h-40px position-relative" />
							</Link>
						</Navbar.Brand>
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Nav
							id="sales_wrapper_actionbar_menu"
							className="w-auto h-auto align-end valign-center position-relative" >
							<Nav.Item title={t('sales_menu.items.optCustomersAdmin1649061924240.title')} onClick={Sales_menuItemsOptCustomersAdmin1649061924240OnClick} href="sales" />
							<Nav.Item title={t('sales_menu.items.optOrders1649061924240.title')} onClick={Sales_menuItemsOptOrders1649061924240OnClick} href="sales_info" />
							<Nav.Item title={t('sales_menu.items.optAdminLogout1649061924240.title')} onClick={Sales_menuItemsOptAdminLogout1649061924240OnClick} href="logout" />
						</Nav>
				</Group>
				<Group className="w-fill h-auto" />
				<Group className="w-auto h-auto d-none d-lg-flex">
						<Group
							id="sales_wrapper_actionbar_content"
							className="w-auto h-auto position-relative" >
							<Text
								id="backToAdminButton"
								if={{"what":"@cookie.type","is":"admin"}}
								onClick={backToAdminButtonOnClick}
								className="w-100 h-auto border-radius-50px position-relative bg-accent px-8px py-4px" >
								{t('sales_wrapper.backToAdminButton')}
							</Text>
						</Group>
				</Group>
				</Navbar>
		
				<Group className="flex-grow-1">{children}</Group>
        <DoyoBadge />
    </main>
  );
};
