import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Customer_must_companyDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const customer_must_company_buttons_positiveOnClick = (options) => {
		run([{"function":"goto","view":"profile"},{"function":"hide","dialog":"customer_must_company"}], options)
	}

  return (
    
    
			<Modal id="customer_must_company">
					<Modal.Body>
						{t('customer_must_company.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="customer_must_company_buttons_positive"
							onClick={customer_must_company_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('customer_must_company.customer_must_company_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Customer_must_companyDialog;
