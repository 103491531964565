import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Admin_accessView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const admin_accessParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( admin_accessParams, [])
	const admin_accessOnLoaded = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/getCompanies","into":"@database.companies","method":"post","onerror":[{"function":"goto","view":"admin"}],"response":"json-api","if":{"what":"@property.neverDoThis","is":true}},{"function":"goto","view":"dashboard"}], options)
	}
	useEffect(admin_accessOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('admin_access.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="salesAccessBody1649031097799"
				direction="vertical"
				className="w-100 h-fill position-relative bg-admin_background p-32px" >
			</Group>
      </FullscreenWrapper>
    </>
  );
};
