import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Lottie, Text, List, Card, Input, Button } from '../components';
import anim_wait from '../assets/animation/anim_wait.json';
import { useTranslation } from 'react-i18next';

const Customer_new_orderDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const component1657094253552OnClick = (options) => {
		run([{"function":"set","what":"@property.orderStep","value":"wait"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/newOrder","params":{"userID":"@cookie.userID","companyID":"@cookie.companyID","locationID":"@cookie.locationID","minDate":"@property.deliveryDate"},"method":"post","into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@cookie.orderID","value":"@database.response.orderID"},{"function":"goto","view":"store"},{"function":"hide","dialog":"customer_new_order"}]}], options)
	}
	const component1657057829132OnClick = (options) => {
		run([{"function":"set","what":"@cookie.locationID","value":"@element.newOrderLocationList.selected"},{"function":"set","what":"@property.minDate","value":"@database.response.companies.(@cookie.companyID).locations.(@cookie.locationID).minDate","if":{"what":"@cookie.locationID","is_not":null,"and":{"what":"@cookie.companyID","is_not":null}}},{"function":"set","what":"@property.orderStep","value":"date"}], options)
	}
	const component1657056936992OnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@element.newOrderCompanyList.selected"},{"function":"set","what":"@property.orderStep","value":"location"}], options)
	}
	const customer_new_orderOnLoaded = (options) => {
		run([{"function":"set","what":"@property.orderStep","value":"wait"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/preOrder\n","params":{"userID":"@cookie.userID"},"method":"post","into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@cookie.locationID","value":"@database.response.locationID"},{"function":"set","what":"@cookie.companyID","value":"@database.response.companyID"},{"function":"set","what":"@property.orderStep","value":"@database.response.step"},{"function":"set","what":"@property.minDate","value":"@database.response.companies.(@cookie.companyID).locations.(@cookie.locationID).minDate","if":{"what":"@cookie.locationID","is_not":null,"and":{"what":"@cookie.companyID","is_not":null}}},{"function":"set","what":"@property.deliveryDate","value":"@property.minDate"}]}], options)
	}

  return (
    
    
			<Modal id="customer_new_order" centered size="md" onShow={customer_new_orderOnLoaded}>
					<Modal.Header>
						<Modal.Title>{t('customer_new_order.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="newOrder_wait"
							if={{"what":"@property.orderStep","is":"wait"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Lottie
								id="component1657056657722"
								src={anim_wait}
								className="w-96px h-auto align-center valign-center position-absolute" />
						</Group>
						<Group
							id="newOrder_company"
							visible={false}
							if={{"what":"@property.orderStep","is":"company"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="newOrder_company_content"
								direction="vertical"
								className="w-100 min-h-100 fg-100 position-relative scrollable" >
								<Text
									id="newOrder_company_label"
									className="w-100 h-auto position-relative" >
									{t('customer_new_order.newOrder_company_label')}
								</Text>
								<List
									id="newOrderCompanyList"
									direction="auto"
									src="@database.response.companies"
									className="w-100 h-auto position-relative mt-32px" >
									<Card
										id="component1657056936992"
										onClick={component1657056936992OnClick}
										className="min-w-140px w-auto h-auto position-relative bg-primary me-8px mb-8px" >
										<Card.Body>
												<Text
													id="component1657056951163"
													src="name"
													className="w-100 h-auto position-relative" >
													{t('customer_new_order.component1657056951163')}
												</Text>
												<Text
													id="component1657057013492"
													src="company"
													className="w-100 h-auto position-relative" >
													{t('customer_new_order.component1657057013492')}
												</Text>
										</Card.Body>
									</Card>
								</List>
							</Group>
						</Group>
						<Group
							id="newOrder_location"
							visible={false}
							if={{"what":"@property.orderStep","is":"location"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="newOrder_location_content"
								direction="vertical"
								className="w-100 min-h-100 fg-100 position-relative scrollable" >
								<Text
									id="newOrder_location_label"
									className="w-100 h-auto position-relative" >
									{t('customer_new_order.newOrder_location_label')}
								</Text>
								<List
									id="newOrderLocationList"
									direction="auto"
									src="@database.response.companies.(@cookie.companyID).locations"
									order={{"type":"asc","field":"name"}}
									className="w-100 h-auto position-relative mt-32px" >
									<Card
										id="component1657057829132"
										onClick={component1657057829132OnClick}
										className="min-w-140px w-auto h-auto border-radius-15px position-relative bg-primary me-8px mb-8px" >
										<Card.Body>
												<Text
													id="component1657057829132"
													src="name"
													className="w-100 h-auto position-relative" >
													{t('customer_new_order.component1657057829132')}
												</Text>
												<Text
													id="component1657057829132vcv"
													src="company"
													className="w-100 h-auto position-relative" >
													{t('customer_new_order.component1657057829132vcv')}
												</Text>
										</Card.Body>
									</Card>
								</List>
							</Group>
						</Group>
						<Group
							id="newOrder_date"
							visible={false}
							if={{"what":"@property.orderStep","is":"date"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="newOrder_date_content"
								direction="vertical"
								className="w-100 h-fill position-relative scrollable" >
								<Text
									id="newOrder_date_label"
									className="w-100 h-auto position-relative" >
									{t('customer_new_order.newOrder_date_label')}
								</Text>
								<Text
									id="newOrder_date_label1657064211928"
									tag="small"
									className="w-100 h-auto position-relative text-primary-light" >
									{t('customer_new_order.newOrder_date_label1657064211928')}
								</Text>
								<Input.Date
									id="newOrder_date_input"
									name="date"
									src="@property.deliveryDate"
	min="@property.minDate"
									className="w-auto h-auto position-relative mt-32px" />
							</Group>
							<Button
								id="component1657094253552"
								onClick={component1657094253552OnClick}
								variant="primary"
								className="w-auto h-auto align-end position-relative" >
								{t('customer_new_order.component1657094253552')}
							</Button>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default Customer_new_orderDialog;
