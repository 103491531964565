import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Customerwrapper_simpleWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Web } from '../components';
import { useTranslation } from 'react-i18next';

export const Customer_new_companyView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('customer_new_company.title')}</title>
      </Helmet>
      <Customerwrapper_simpleWrapper>
          
			<Web
				id="addCompanyFormWeb"
				src="https://pedidos.pinkalbatross.com/customer_application_form"
				className="w-100 min-h-100 fg-100 position-relative" />
      </Customerwrapper_simpleWrapper>
    </>
  );
};
