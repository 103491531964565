import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Icon, List, Button, Card, Image, Lottie } from '../components';
import pink_loading from '../assets/animation/pink_loading.json';
import placeholder_user from '../assets/image/placeholder_user.svg';
import { useTranslation } from 'react-i18next';

export const ProfileView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const memberDeleteOnClick = (options) => {
		run([{"function":"set","what":"@property.memberID","value":"@element.membersList.selected"},{"function":"set","what":"@property.member","value":"@element.membersList.value"},{"function":"popup","dialog":"member_delete"}], options)
	}
	const companyAddCardButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"customer_add_company"}], options)
	}
	const companyCardDeleteButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.deletedCompanyID","value":"@element.companiesList.selected"},{"function":"set","what":"@property.deletedCompany","value":"@element.companiesList.value"},{"function":"popup","dialog":"company_leave","if":{"what":"@property.deletedCompany.status","is":"verified","else":[{"function":"popup","dialog":"company_application_cancel"}]}}], options)
	}
	const companyCardFillFormButtonOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).current_application","value":"@field.application"},{"function":"set","what":"@property.showBackBar","value":true},{"function":"goto","view":"customer_application_form"}], options)
	}
	const companyCardAddLocationButtonOnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@cookie.companyID","value":"@field.company"},{"function":"set","what":"@cookie.editLocationID","value":null},{"function":"set","what":"@property.delivery_name","value":null},{"function":"set","what":"@property.delivery_address","value":null},{"function":"set","what":"@property.delivery_city","value":null},{"function":"set","what":"@property.delivery_cp","value":null},{"function":"set","what":"@property.delivery_province","value":null},{"function":"set","what":"@property.delivery_country","value":"ES"},{"function":"set","what":"@property.delivery_comments","value":null},{"function":"set","what":"@property.mon","value":true},{"function":"set","what":"@property.tue","value":true},{"function":"set","what":"@property.wed","value":true},{"function":"set","what":"@property.thu","value":true},{"function":"set","what":"@property.fri","value":true},{"function":"set","what":"@property.holded_id","value":null},{"function":"popup","dialog":"customer_edit_location"}],"if":{"what":"@field.relation","is":"owner"}}], options)
	}
	const companiesAddButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"customer_add_company"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('profile.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="customerProfileBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="myCompanies"
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Group
						id="companiesHeader"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="companiesTitle"
							tag="h2"
							className="w-auto h-auto position-relative pe-24px ff-antonregular" >
							{t('profile.companiesTitle')}
						</Text>
						<Icon
							id="companiesAddButton"
							onClick={companiesAddButtonOnClick}
							src="plus-circle"
							color="var(--accent)"
							className="w-auto h-auto position-relative"
						/>
					</Group>
					<List
						id="companiesList"
						direction="vertical"
						src="@firebase.firestore.customers_companies"
						filter={{"what":"customer","is":"@cookie.userID"}}
						className="w-100 h-auto position-relative mt-12px" >
						<Group
							id="companyCard"
							direction="vertical"
							className="w-100 h-auto border-radius-15px position-relative bg-card_bg mb-24px" >
							<Group
								id="companyCardHeader"
								direction="auto"
								className="w-100 h-auto border-radius-tl15px-tr15px position-relative bg-primary" >
								<Group
									id="companyCardName"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="companyCardNameLabel"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('profile.companyCardNameLabel')}
									</Text>
									<Text
										id="companyCardNameValue"
										tag="small"
										src="name"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="companyCardCif"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="companyCardCifLabel"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('profile.companyCardCifLabel')}
									</Text>
									<Text
										id="companyCardCifValue"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="companyCardRol"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="companyCardRolLabel"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('profile.companyCardRolLabel')}
									</Text>
									<Text
										id="companyCardRolAdmin"
										if={{"what":"@field.relation","is":"owner"}}
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('profile.companyCardRolAdmin')}
									</Text>
									<Text
										id="companyCardRolUser"
										if={{"what":"@field.relation","is_not":"owner"}}
										tag="small"
										className="w-100 h-auto position-relative text-primary-text ff-label" >
										{t('profile.companyCardRolUser')}
									</Text>
								</Group>
								<Group
									id="companyCardHeaderSpacer"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="companyCardHeaderButtons"
									direction="horizontal"
									className="w-auto h-auto valign-center position-relative p-8px" >
									<Button
										id="companyCardAddLocationButton"
										visible={false}
										if={{"what":"@field.relation","is":"owner","and":{"what":"@field.status","is":"verified"}}}
										onClick={companyCardAddLocationButtonOnClick}
										variant="primary-dark"
										className="w-auto h-auto border-radius-32px valign-center position-relative ms-16px" >
										{t('profile.companyCardAddLocationButton')}
									</Button>
									<Button
										id="companyCardFillFormButton"
										if={{"what":"@field.status","is":"new"}}
										onClick={companyCardFillFormButtonOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px valign-center position-relative ms-16px" >
										{t('profile.companyCardFillFormButton')}
									</Button>
									<Button
										id="companyCardDeleteButton"
										visible={false}
										if={{"what":"@field.relation","is":"owner-never"}}
										onClick={companyCardDeleteButtonOnClick}
										variant="primary-dark"
										className="w-auto h-auto border-radius-32px valign-center position-relative ms-16px" >
										{t('profile.companyCardDeleteButton')}
									</Button>
								</Group>
							</Group>
							<Group
								id="companyCardContent"
								direction="vertical"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="companyCardLocations"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Text
										id="companyStatusPending1659274792729"
										if={{"what":"@field.status","is":"pending"}}
										className="w-auto h-auto position-relative p-16px text-primary-text" >
										{t('profile.companyStatusPending1659274792729')}
									</Text>
									<Text
										id="companyStatusPending1659274861444"
										if={{"what":"@field.status","is":"new"}}
										className="w-auto h-auto position-relative p-16px text-primary-text" >
										{t('profile.companyStatusPending1659274861444')}
									</Text>
									<List
										id="companyCardLocations"
										direction="auto"
										src="@firebase.firestore.locations"
										filter={{"what":"company","is":"@field.company"}}
										columns={"auto"}
										className="w-100 h-auto position-relative" >
										<Group
											id="companyCardLocationsListRow"
											direction="vertical"
											className="min-w-300px w-fill h-auto border-gray border-top border-bottom border-start border-end border-radius-5px position-relative p-16px m-8px" >
											<Text
												id="locationsName1659253376581"
												tag="b"
												src="name"
												className="w-100 h-auto position-relative ff-librebaskervillebold" >
												{t('profile.locationsName1659253376581')}
											</Text>
											<Text
												id="locationsStreet1659253388281"
												src="address"
												className="w-100 h-auto position-relative ff-librebaskervilleregular" >
												{t('profile.locationsStreet1659253388281')}
											</Text>
											<Text
												id="locationsCity1659253401939"
												src="city"
												className="w-100 h-auto position-relative ff-librebaskervilleregular" >
												{t('profile.locationsCity1659253401939')}
											</Text>
											<Text
												id="locationsCP1659253416756"
												src="cp"
												className="w-100 h-auto position-relative ff-librebaskervilleregular" >
												{t('profile.locationsCP1659253416756')}
											</Text>
										</Group>
									</List>
								</Group>
							</Group>
						</Group>
					</List>
					<Card
						id="companyAddCard"
						if={{"what":"@cookie.n_companies","is":"nevershowthis"}}
						className="min-w-200px w-fill h-auto position-relative m-4px" >
						<Card.Body>
								<Text
									id="companyAddCardInstructions"
									className="w-100 h-auto position-relative" >
									{t('profile.companyAddCardInstructions')}
								</Text>
								<Button
									id="companyAddCardButton"
									onClick={companyAddCardButtonOnClick}
									variant="green"
									className="w-auto h-auto border-radius-32px position-relative mt-16px" >
									{t('profile.companyAddCardButton')}
								</Button>
						</Card.Body>
					</Card>
				</Group>
				<Group
					id="membersProfile"
					if={{"what":"@cookie.status","is":"companied","and":{"what":"@property.nevertrue","is":true}}}
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Group
						id="membersHeader"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="membersTitle"
							tag="h2"
							className="w-auto h-auto position-relative pe-24px ff-antonregular" >
							{t('profile.membersTitle')}
						</Text>
						<Icon
							id="membersAddButton"
							if={{"what":"@cookie.owner","is":"@cookie.userID"}}
							src="plus-circle"
							color="var(--accent)"
							className="w-auto h-auto position-relative"
						/>
					</Group>
					<List
						id="membersList"
						direction="auto"
						src="@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).users"
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="memberCard"
							className="min-w-200px w-fill h-auto position-relative m-4px" >
							<Card.Body>
									<Image
										id="memberGravatar"
										fallback={placeholder_user}
										src="gravatar"
										className="w-64px h-auto position-relative" />
									<Text
										id="memberEmail"
										tag="b"
										src="email"
										className="w-100 h-auto position-relative" >
										{t('profile.memberEmail')}
									</Text>
									<Group
										id="memberTypeGroup"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="memberUser"
											if={{"what":"@field.relation","is":"user"}}
											className="w-100 h-auto position-relative" >
											{t('profile.memberUser')}
										</Text>
										<Text
											id="memberOwner"
											if={{"what":"@field.relation","is":"owner"}}
											className="w-100 h-auto position-relative" >
											{t('profile.memberOwner')}
										</Text>
									</Group>
									<Button
										id="memberDelete"
										if={{"what":"@cookie.relation","is":"owner"}}
										onClick={memberDeleteOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('profile.memberDelete')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
			<Group
				id="profileCurtain"
				if={{"what":"@property.showCurtain","is":true}}
				direction="horizontal"
				className="w-100 min-h-100 fg-100 position-absolute bg-darken" >
				<Group
					id="component1659119620738"
					direction="vertical"
					className="w-100 h-auto valign-center position-relative" >
					<Lottie
						id="profileLoadingLottie"
						src={pink_loading}
						className="w-128px h-auto align-center valign-center position-relative" />
				</Group>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
