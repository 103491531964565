import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Input, List, Card, Group, Image, Text } from '../components';
import { useTranslation } from 'react-i18next';
import avellana from '../assets/helados/avellana.png';

export const A_add_productDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const storeProductCard1667333205072OnClick = (options) => {
		run([{"function":"set","what":"@cookie.product","value":"@element.selectProductList.value"},{"function":"set","what":"@cookie.productID","value":"@element.selectProductList.selected"},{"function":"set","what":"@property.itemUnits","value":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.productID).units","if":{"what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.productID).added","else":[{"function":"set","what":"@property.itemUnits","value":0}],"is":true}},{"function":"set","what":"@property.itemUnits","value":"@property.itemUnits.+1"},{"function":"add","what":{"units":"@property.itemUnits","name":"@cookie.product.name","description":"@cookie.product.description","id":"@cookie.productID1667334031463","added":true,"image":"@cookie.product.image","sku":"@cookie.product.sku"},"to":"@firebase.firestore.orders.(@cookie.orderID).cart","key":"@cookie.productID"},{"id":"hide1667334183770","function":"hide","dialog":"a_add_product"}], options)
	}
	const a_add_productOnLoaded = (options) => {
		run([{"function":"set","what":"@property.size","value":480,"if":{"what":"@property.size","is":null}}], options)
	}

  return (
    
    
			<Offcanvas id="a_add_product" placement="end" onShow={a_add_productOnLoaded}>
					<Offcanvas.Body>
						<Input.Select
							id="storeSizeFilter1667333260234"
							name="select"
							src="@property.size"
							dataSrc="@firebase.firestore.data.sizes.values"
							mapSrc={{"label":"label","value":"value"}}
							order={{"type":"asc","field":"position"}}
							className="w-100px h-auto position-relative" >
						</Input.Select>
						<List
							id="selectProductList"
							direction="vertical"
							src="@firebase.firestore.products"
							filter={{"what":"size","is":"@property.size","and":{"what":"available","is":true}}}
							order={{"type":"asc","field":"name"}}
							className="w-100 h-auto position-relative mt-16px" >
							<Card
								id="storeProductCard1667333205072"
								onClick={storeProductCard1667333205072OnClick}
								className="w-100 h-auto border-radius-15px position-relative mb-4px" >
								<Card.Body>
										<Group
											id="storeProductCardContent1667333205072"
											direction="horizontal"
											className="w-100 h-auto position-relative p-4px" >
											<Image
												id="productImage1667333205072"
												fallback={avellana}
												src="image"
												className="w-64px h-auto align-center position-relative" />
											<Text
												id="productName1667333205072"
												tag="b"
												src="name"
												className="w-fill h-auto valign-center position-relative ps-16px text-start" >
												{t('a_add_product.productName1667333205072')}
											</Text>
										</Group>
								</Card.Body>
							</Card>
						</List>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default A_add_productDialog;
