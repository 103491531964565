import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input, Button } from '../components';
import retrato from '../assets/logos/retrato.png';
import { useTranslation } from 'react-i18next';

export const Recover_passwordView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const recoverGotoLoginOnClick = (options) => {
		run([{"function":"goto","view":"login"}], options)
	}
	const recoverButtonOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","forgot":true,"email":"@property.email","onvalid":[{"function":"set","what":"@property.msg","value":"reset_password_sent"},{"function":"popup","dialog":"msg_dialog"}],"oninvalid":[{"function":"set","what":"@property.msg","value":"reset_password_error"},{"function":"popup","dialog":"msg_dialog"}]}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('recover_password.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="recoverWrapper"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative bg-white" >
				<Group
					id="recoverHeader"
					direction="vertical"
					className="w-100 min-h-300px h-300px align-start position-relative bg-pink" >
					<Image
						id="loginLogo1646385212430"
						src={retrato}
						className="w-auto min-h-300px h-300px align-center valign-end position-absolute-bottom mb--120px" />
				</Group>
				<Group
					id="recoverBody"
					direction="vertical"
					className="w-100 h-auto align-start valign-center position-relative px-32px pt-150px pb-32px" >
					<Text
						id="recoverTitle"
						className="w-100 h-auto position-relative my-32px f-54px ff-antonregular text-center" >
						{t('recover_password.recoverTitle')}
					</Text>
					<Text
						id="recoverSubtitle"
						className="w-100 h-auto position-relative mb-32px f-32px ff-antonregular text-center" >
						{t('recover_password.recoverSubtitle')}
					</Text>
					<Group
						id="recoverEmailWrapper"
						direction="vertical"
						className="w-250px h-auto align-center position-relative" >
						<Text
							id="emailLabel1646385212430"
							className="w-100 h-auto position-relative ff-antonregular" >
							{t('recover_password.emailLabel1646385212430')}
						</Text>
						<Input.Text
							id="emailInput1646385212430"
							name="text"
							src="@property.email"
						placeholder={t('recover_password.emailInput1646385212430.hint')}
						autoComplete="off"
							className="w-100 h-auto align-center position-relative bg-pink" />
					</Group>
					<Button
						id="recoverButton"
						onClick={recoverButtonOnClick}
						variant="text-on-accent"
						className="w-250px h-auto border-radius-100px align-center position-relative bg-accent mt-48px" >
						{t('recover_password.recoverButton')}
					</Button>
					<Text
						id="recoverGotoLogin"
						onClick={recoverGotoLoginOnClick}
						className="w-auto h-auto border-radius-100px align-center position-relative px-72px py-12px mt-8px text-primary-dark" >
						{t('recover_password.recoverGotoLogin')}
					</Text>
				</Group>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
