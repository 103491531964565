import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Sales_accessView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const sales_accessParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( sales_accessParams, [])
	const sales_accessOnLoaded = (options) => {
		run([{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/getCompanies","into":"@database.companies","method":"post","onsuccess":[{"function":"goto","view":"sales"}],"onerror":[{"function":"goto","view":"sales"}],"response":"json-api"}], options)
	}
	useEffect(sales_accessOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('sales_access.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Group
				id="salesAccessBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px" >
			</Group>
      </FullscreenWrapper>
    </>
  );
};
