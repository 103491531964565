import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text } from '../components';
import { useTranslation } from 'react-i18next';

export const DashboardView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    <>
      <Helmet>
				<title>{t('dashboard.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="form1664041369789"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Text
					id="text1664041390369"
					className="w-100 h-auto position-relative" >
					{t('dashboard.text1664041390369')}
				</Text>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
