import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Text, Group, Input, Button, Lottie } from '../components';
import anim_wait from '../assets/animation/anim_wait.json';
import { useTranslation } from 'react-i18next';

export const Admin_validate_companyDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminValidateButtonOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.users.(@property.application.user_id).type","value":"admin"},{"function":"delete","what":"@firebase.firestore.customers.(@property.application.user_id)"},{"function":"delete","what":"@firebase.firestore.customers.(@property.application.user_id).companies.(@property.application.cif)"},{"function":"delete","what":"@firebase.firestore.applications.(@property.applicationID)"},{"function":"set","what":"@property.error","value":"Administrador validado","if":{"what":"@property.response.data.error","is_not":true,"else":[{"function":"set","what":"@property.error","value":"@property.response.error_message"}]}},{"function":"popup","dialog":"error_dialog"},{"function":"hide","dialog":"admin_validate_company"},{"function":"set","what":"@cookie.paso","value":"valid"}], options)
	}
	const salesmaneValidateButtonOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.users.(@property.application.user_id).type","value":"sales"},{"function":"delete","what":"@firebase.firestore.customers.(@property.application.user_id).companies.(@property.application.cif)"},{"function":"delete","what":"@firebase.firestore.customers.(@property.application.user_id)"},{"function":"delete","what":"@firebase.firestore.applications.(@property.applicationID)"},{"function":"set","what":"@property.error","value":"Comercial validado","if":{"what":"@property.response.data.error","is_not":true,"else":[{"function":"set","what":"@property.error","value":"@property.response.error_message"}]}},{"function":"popup","dialog":"error_dialog"},{"function":"hide","dialog":"admin_validate_company"},{"function":"set","what":"@cookie.paso","value":"valid"}], options)
	}
	const validateButtonOnClick = (options) => {
		run([{"function":"set","what":"@element.applicationPreview.visible","value":false},{"function":"set","what":"@element.validationWait.visible","value":true},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/validateCIF\n","params":{"cif":"@property.application.cif","userID":"@property.application.user_id","applicationID":"@property.applicationID","name":"@property.company_name"},"method":"post","into":"@cookie.response","response":"json-api","onerror":[{"function":"set","what":"@property.error","value":"@property.response.data.error_message"},{"function":"set","what":"@element.validationWait.visible","value":false},{"function":"set","what":"@element.applicationPreview.visible","value":true},{"function":"hide","dialog":"admin_validate_company"},{"function":"popup","dialog":"error_dialog"},{"function":"set","what":"@cookie.paso","value":"error"}],"onsuccess":[{"function":"set","what":"@property.error","value":"Solicitud validada","if":{"what":"@property.response.data.error","is_not":true,"else":[{"function":"set","what":"@property.error","value":"@property.response.error_message"}]}},{"function":"popup","dialog":"error_dialog"},{"function":"set","what":"@element.validationWait.visible","value":false},{"function":"set","what":"@element.applicationPreview.visible","value":true},{"function":"hide","dialog":"admin_validate_company"},{"function":"set","what":"@cookie.paso","value":"valid"}]}], options)
	}
	const admin_validate_companyOnLoaded = (options) => {
		run([{"function":"set","what":"@element.validationWait.visible","value":false}], options)
	}

  return (
    
    
			<Offcanvas id="admin_validate_company" placement="end" scrollable onShow={admin_validate_companyOnLoaded}>
					<Offcanvas.Body>
						<Text
							id="validateAccountTitle"
							className="w-100 h-auto position-relative ff-antonregular text-center" >
							{t('admin_validate_company.validateAccountTitle')}
						</Text>
						<Group
							id="applicationPreview"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Group
								id="applicationData"
								if={{"what":"@property.neverUse","is":true}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative bg-primary-light p-12px mt-24px" >
								<Group
									id="applicationGroupName"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Text
										id="applicationNameLabel"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationNameLabel')}
									</Text>
									<Text
										id="applicationNameValue"
										src="@property.application.company_name"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationNameValue')}
									</Text>
								</Group>
								<Group
									id="applicationGroupStreet1648056460794"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationStreetLabel1648056460794"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationStreetLabel1648056460794')}
									</Text>
									<Text
										id="applicationStreetValue1648056460794"
										src="@property.application.cif"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationStreetValue1648056460794')}
									</Text>
								</Group>
								<Group
									id="applicationGroupStreet1648056628620"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationStreetLabel1648056628620"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationStreetLabel1648056628620')}
									</Text>
									<Text
										id="applicationStreetValue1648056628620"
										src="@property.application.email"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationStreetValue1648056628620')}
									</Text>
								</Group>
								<Group
									id="applicationGroupStreet"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationStreetLabel"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationStreetLabel')}
									</Text>
									<Text
										id="applicationStreetValue"
										src="@property.application.street"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationStreetValue')}
									</Text>
								</Group>
								<Group
									id="applicationGroupStreet1648056294764"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationStreetLabel1648056294764"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationStreetLabel1648056294764')}
									</Text>
									<Text
										id="applicationStreetValue1648056294764"
										src="@property.application.street_number"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationStreetValue1648056294764')}
									</Text>
								</Group>
								<Group
									id="applicationGroupStreet1648056334990"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationStreetLabel1648056334990"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationStreetLabel1648056334990')}
									</Text>
									<Text
										id="applicationStreetValue1648056334990"
										src="@property.application.city"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationStreetValue1648056334990')}
									</Text>
								</Group>
								<Group
									id="applicationGroupComments"
									direction="vertical"
									className="w-100 h-auto position-relative mt-12px" >
									<Text
										id="applicationCommentsLabel"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationCommentsLabel')}
									</Text>
									<Text
										id="applicationCommentsValue"
										src="@property.application.comments"
										className="w-100 h-auto position-relative ff-librebaskervillebold" >
										{t('admin_validate_company.applicationCommentsValue')}
									</Text>
								</Group>
							</Group>
							<Group
								id="applicationNameInput"
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative bg-primary-light p-12px mt-24px" >
								<Group
									id="applicationGroupNameInput"
									direction="vertical"
									className="w-100 h-auto position-relative py-12px" >
									<Text
										id="applicationNameLabel1650208589408"
										className="w-100 h-auto position-relative ff-librebaskervilleregular" >
										{t('admin_validate_company.applicationNameLabel1650208589408')}
									</Text>
									<Input.Text
										id="applicationNameInputValue"
										name="text"
										src="@property.company_name"
									autoComplete="off"
										className="w-100 h-auto position-relative" />
								</Group>
							</Group>
							<Button
								id="validateButton"
								onClick={validateButtonOnClick}
								variant="accent"
								className="w-100 h-auto border-radius-50px position-relative mt-24px" >
								{t('admin_validate_company.validateButton')}
							</Button>
							<Button
								id="salesmaneValidateButton"
								onClick={salesmaneValidateButtonOnClick}
								variant="gray"
								className="w-100 h-auto border-radius-50px position-relative mt-24px" >
								{t('admin_validate_company.salesmaneValidateButton')}
							</Button>
							<Button
								id="adminValidateButton"
								onClick={adminValidateButtonOnClick}
								variant="gray"
								className="w-100 h-auto border-radius-50px position-relative my-24px" >
								{t('admin_validate_company.adminValidateButton')}
							</Button>
						</Group>
						<Group
							id="validationWait"
							visible={false}
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Lottie
								id="validationAnimation"
								src={anim_wait}
								className="w-50 h-auto align-center position-relative" />
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Admin_validate_companyDialog;
