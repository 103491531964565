import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, Input, Image, Icon, Button } from '../components';
import placeholder_cup from '../assets/image/placeholder_cup.png';
import { useTranslation } from 'react-i18next';

export const Product_editDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const productEditButtonSaveOnClick = (options) => {
		run([{"function":"add","what":{"name":"@property.productName","description":"@property.productDescription","size":"@property.productSize","sku":"@property.productSku","holded_id":"@property.productHoldedId"},"to":"@firebase.firestore.products","key":"@cookie.productID"},{"function":"hide","dialog":"product_edit"}], options)
	}
	const productEditDeleteOnClick = (options) => {
		run([{"function":"hide","dialog":"product_edit"},{"function":"popup","dialog":"product_delete"}], options)
	}
	const editProductPhotoEditButtonOnClick = (options) => {
		run([{"function":"file","into":"@cookie.filetemp","type":"image\/*","onsuccess":[{"function":"set","what":"@firebase.storage.images\/products\/(@cookie.productID).img","value":"@cookie.filetemp"},{"function":"set","what":"@property.productImage","value":"@firebase.storage.images\/products\/(@cookie.productID).img"},{"function":"set","what":"@firebase.firestore.products.(@cookie.productID).image","value":"@firebase.storage.images\/products\/(@cookie.productID).img"}],"multiple":false}], options)
	}
	const product_editOnLoaded = (options) => {
		run([{"function":"set","what":"@property.productName","value":"@cookie.product.name"},{"function":"set","what":"@property.productDescription","value":"@cookie.product.description"},{"function":"set","what":"@property.productSize","value":"@cookie.product.size"},{"function":"set","what":"@property.productSku","value":"@cookie.product.sku"},{"function":"set","what":"@property.productImage","value":"@cookie.product.image"},{"function":"set","what":"@property.productHoldedId","value":"@cookie.product.holded_id"}], options)
	}

  return (
    
    
			<Offcanvas id="product_edit" placement="end" onShow={product_editOnLoaded}>
					<Offcanvas.Body>
						<Group
							id="productEditBody"
							direction="horizontal"
							className="w-100 min-h-100 fg-100 position-relative scrollable" >
							<Group
								id="productEditInfoCenter"
								direction="vertical"
								className="max-w-1200px w-100 h-auto align-center valign-center position-relative" >
								<Input.Switch
									id="availableProductSwitch"
									label={t('product_edit.availableProductSwitch.label')}
									name="switch"
									src="@firebase.firestore.products.(@cookie.productID).available"
									className="w-auto h-auto valign-start position-relative" />
								<Group
									id="productEditContent"
									direction="vertical"
									className="w-100 h-auto position-relative pt-12px" >
									<Image
										id="productEditImage"
										fallback={placeholder_cup}
										src="@property.productImage"
										className="w-200px h-auto align-center position-relative" />
									<Group
										id="productPhotoInput"
										direction="vertical"
										className="w-32px min-h-32px h-32px align-center position-relative" >
										<Icon
											id="editProductPhotoEditButton"
											onClick={editProductPhotoEditButtonOnClick}
											src="upload"
											className="w-auto h-auto border-radius-100px position-relative bg-gray"
										/>
									</Group>
									<Group
										id="productEditInfoGroup"
										direction="vertical"
										className="w-fill h-auto position-relative" >
										<Input.Text
											id="editProductName"
											label={t('product_edit.editProductName.label')}
											name="text"
											src="@property.productName"
										autoComplete="off"
											className="w-100 h-auto position-relative" />
										<Input.Text
											id="editProductDescription"
											label={t('product_edit.editProductDescription.label')}
											name="text"
											src="@property.productDescription"
										autoComplete="off"
											className="w-100 h-auto position-relative" />
										<Input.Text
											id="editProductSize"
											label={t('product_edit.editProductSize.label')}
											name="text"
											src="@property.productSize"
										autoComplete="off"
											className="w-100 h-auto position-relative" />
										<Input.Text
											id="editProductSku"
											label={t('product_edit.editProductSku.label')}
											name="text"
											src="@property.productSku"
										autoComplete="off"
											className="w-100 h-auto position-relative" />
										<Input.Text
											id="editProductHoldedID"
											label={t('product_edit.editProductHoldedID.label')}
											name="text"
											src="@property.productHoldedId"
										autoComplete="off"
											className="w-100 h-auto position-relative" />
									</Group>
								</Group>
								<Group
									id="productEditButtons"
									direction="vertical"
									className="w-100 h-auto align-center position-relative mt-64px" >
									<Button
										id="productEditDelete"
										onClick={productEditDeleteOnClick}
										variant="primary"
										className="w-100 h-auto border-radius-100px position-relative" >
										{t('product_edit.productEditDelete')}
									</Button>
									<Button
										id="productEditButtonSave"
										onClick={productEditButtonSaveOnClick}
										variant="accent"
										className="w-100 h-auto border-radius-100px position-relative mt-12px" >
										{t('product_edit.productEditButtonSave')}
									</Button>
								</Group>
							</Group>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Product_editDialog;
