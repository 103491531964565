import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, List, Card, Image, Button } from '../components';
import { useEffect } from 'react';
import avellana from '../assets/helados/avellana.png';
import { useTranslation } from 'react-i18next';

export const StoreView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const storeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( storeParams, [])
	const storeOnLoaded = (options) => {
		run([{"function":"goto","if":{"what":"@firebase.firestore.customers.(@cookie.userID).status","is":"uncompanied"},"view":"customer_unverified"},{"function":"set","what":"@property.size","value":480,"if":{"what":"@property.size","is":null}},{"function":"popup","dialog":"order_accepted","if":{"what":"@property.sayThanks","is":true}},{"function":"set","what":"@property.sayThanks","value":false},{"function":"popup","dialog":"order_canceled","if":{"what":"@cookie.orderCanceled","is":true}},{"function":"set","what":"@cookie.orderCanceled","value":false}], options)
	}
	useEffect(storeOnLoaded, [])
	const storeReviewButtonOnClick = (options) => {
		run([{"function":"goto","view":"orders"}], options)
	}
	const storeProductAddButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.product","value":"@element.storeProductsList.value"},{"function":"set","what":"@cookie.productID","value":"@element.storeProductsList.selected"},{"function":"set","what":"@cookie.shopButtons","value":"shop"},{"function":"set","what":"@property.itemUnits","value":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.productID).units","if":{"what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.productID).added","else":[{"function":"set","what":"@property.itemUnits","value":0}],"is":true}},{"function":"set","what":"@property.itemUnits","value":"@property.itemUnits.+1"},{"function":"add","what":{"units":"@property.itemUnits","name":"@cookie.product.name","description":"@cookie.product.description","id":"@cookie.productID","added":true,"image":"@cookie.product.image","sku":"@cookie.product.sku"},"to":"@firebase.firestore.orders.(@cookie.orderID).cart","key":"@cookie.productID"},{"function":"popup","dialog":"product_shop"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('store.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="storeWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="storeHeader"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Text
						id="storeTitle"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('store.storeTitle')}
					</Text>
				</Group>
				<Input.Select
					id="storeSizeFilter"
					name="select"
					src="@property.size"
					dataSrc="@firebase.firestore.data.sizes.values"
					mapSrc={{"label":"label","value":"value"}}
					order={{"type":"asc","field":"position"}}
					className="w-100px h-auto position-relative" >
				</Input.Select>
				<List
					id="storeProductsList"
					direction="horizontal"
					src="@firebase.firestore.products"
					filter={{"what":"size","is":"@property.size","and":{"what":"available","is":true}}}
					order={{"type":"asc","field":"name"}}
					columns={"auto"}
					className="w-100 h-auto position-relative mt-16px" >
					<Card
						id="storeProductCard"
						className="max-w-400px min-w-300px w-fill h-auto border-radius-15px position-relative m-4px" >
						<Card.Body>
								<Group
									id="storeProductCardContent"
									direction="vertical"
									className="w-100 min-h-170px h-170px position-relative pb-24px scrollable" >
									<Image
										id="productImage1657094577433"
										fallback={avellana}
										src="image"
										className="w-96px h-auto align-center position-relative" />
									<Text
										id="productName1657094577433"
										tag="b"
										src="name"
										className="w-100 h-auto position-relative text-center" >
										{t('store.productName1657094577433')}
									</Text>
								</Group>
								<Button
									id="storeProductAddButton"
									onClick={storeProductAddButtonOnClick}
									variant="green"
									className="w-100 h-auto border-radius-32px align-center position-relative mt-16px" >
									{t('store.storeProductAddButton')}
								</Button>
						</Card.Body>
					</Card>
				</List>
			</Group>
			<Group
				id="storeFooter"
				direction="horizontal"
				className="w-100 h-auto position-relative bg-white p-16px" >
				<Button
					id="storeReviewButton"
					onClick={storeReviewButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative" >
					{t('store.storeReviewButton')}
				</Button>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
