import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, Input, Icon, List } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const A_usersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const a_usersParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( a_usersParams, [])
	const a_usersOnLoaded = (options) => {
		run([{"function":"clone","what":"@firebase.firestore.customers","into":"@database.users"},{"function":"set","what":"@property.keyword","value":null}], options)
	}
	useEffect(a_usersOnLoaded, [])
	const userListGotoUserButtonOnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.objectuser","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const icon1664135272284OnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"popup","dialog":"a_delete_customer"}], options)
	}
	const userListHeadersOnClick = (options) => {
		run([{"function":"set","what":"@cookie.customerID","value":"@element.userList.selected"},{"function":"set","what":"@database.object","value":"@element.userList.value"},{"function":"goto","view":"a_user"}], options)
	}
	const userListSearchInputOnChanged = (options) => {
		run([{"function":"set","what":"@property.keywordlower","value":"@property.keyword.lower"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_users.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="userBody"
				direction="vertical"
				className="w-100 h-fill position-relative p-32px" >
				<Group
					id="usersListHeaders"
					direction="auto"
					className="w-100 h-auto position-relative" >
					<Text
						id="usersListTitle"
						tag="h2"
						className="min-w-300px w-fill h-auto position-relative py-6px ff-admin_title" >
						{t('a_users.usersListTitle')}
					</Text>
					<Group
						id="userListSearchBox"
						direction="vertical"
						className="max-w-200px w-fill h-auto valign-center position-relative py-6px" >
						<Input.Text
							id="userListSearchInput"
							name="text"
							src="@property.keyword"
						autoComplete="off"
						onChange={userListSearchInputOnChanged}
							className="w-auto h-auto border-radius-5px position-relative" />
						<Icon
							id="customerListSearchIcon1664037912364"
							src="magnify"
							color="var(--secondary-text)"
							className="w-auto h-auto align-end valign-center position-absolute p-4px"
						/>
					</Group>
				</Group>
				<Group
					id="userListHeaders"
					onClick={userListHeadersOnClick}
					direction="horizontal"
					className="w-100 h-auto position-relative bg-primary-dark p-8px mt-16px" >
					<Text
						id="userListHeaderEmail"
						className="w-350px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_users.userListHeaderEmail')}
					</Text>
					<Text
						id="userListHeaderID"
						className="w-300px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_users.userListHeaderID')}
					</Text>
					<Text
						id="userListHeaderCompany"
						className="w-400px h-auto position-relative text-text-on-primary ff-admin_regular" >
						{t('a_users.userListHeaderCompany')}
					</Text>
				</Group>
				<List
					id="userList"
					direction="vertical"
					src="@database.users"
					filter={{"what":"email","includes":"@property.keywordlower","or":{"what":"current_company","includes":"@property.keywordlower"}}}
					order={{"type":"asc","field":"email"}}
					className="w-fill h-auto position-relative" >
					<Group
						id="salesCustomerName1664037912364"
						direction="horizontal"
						className="w-100 h-auto position-relative bg-row_top-to-row_bottom p-8px" >
						<Text
							id="userListName"
							src="email"
							className="w-350px h-auto valign-center position-relative text-primary-text ff-admin_regular" >
							{t('a_users.userListName')}
						</Text>
						<Text
							id="userListID"
							className="w-300px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_users.userListID')}
						</Text>
						<Text
							id="userListCompany"
							src="current_company"
							className="w-400px h-auto valign-center position-relative text-secondary-text ff-admin_regular" >
							{t('a_users.userListCompany')}
						</Text>
						<Group
							id="userListRowSpacer"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Group
							id="userListRowActions"
							direction="horizontal"
							className="w-auto h-auto position-relative" >
							<Icon
								id="icon1664135272284"
								onClick={icon1664135272284OnClick}
								src="trash-can"
								color="var(--gray)"
								className="w-auto h-auto position-relative"
							/>
							<Icon
								id="userListGotoUserButton"
								onClick={userListGotoUserButtonOnClick}
								src="arrow-right-bold"
								color="var(--gray)"
								className="w-auto h-auto position-relative ms-16px"
							/>
						</Group>
					</Group>
				</List>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
