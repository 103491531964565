import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Card, Image, Icon, Button } from '../components';
import { useEffect } from 'react';
import avellana from '../assets/helados/avellana.png';
import { useTranslation } from 'react-i18next';

export const Customer_cartView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const customer_cartParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( customer_cartParams, [])
	const customer_cartOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.orderStatus","value":"@firebase.firestore.orders.(@cookie.locationID).order_status"},{"function":"set","what":"@cookie.orderStatus","value":"open","if":{"what":"@cookie.orderStatus","is_not":"confirmed"}},{"function":"goto","if":{"what":"@firebase.firestore.customers.(@cookie.userID).status","is":"uncompanied"},"view":"customer_unverified"}], options)
	}
	useEffect(customer_cartOnLoaded, [])
	const cancelButtonOnClick = (options) => {
		run([{"function":"popup","dialog":"order_cancelation"}], options)
	}
	const confirmButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.comments","value":"@firebase.firestore.companies.(@cookie.companyID).locations.(@cookie.locationID).comments"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/getCartInfo","params":{"orderID":"@cookie.locationID"},"method":"post","response":"json-api","into":"@cookie.cart","onsuccess":[{"function":"popup","dialog":"order_confirmation","if":{"what":"@cookie.cart.units","more_than":3,"else":[{"function":"popup","dialog":"order_minimum"}]}}]}], options)
	}
	const cartKeepButtonOnClick = (options) => {
		run([{"function":"goto","view":"customer_products"}], options)
	}
	const cartButtonRemoveOnClick = (options) => {
		run([{"function":"delete","what":"@firebase.firestore.orders.(@cookie.locationID).cart.(@element.cartItemList.selected)"}], options)
	}
	const cartButtonEditOnClick = (options) => {
		run([{"function":"set","what":"@cookie.product","value":"@element.cartItemList.value"},{"function":"set","what":"@cookie.productID","value":"@element.cartItemList.selected"},{"function":"set","what":"@cookie.shopButtons","value":"cart"},{"function":"popup","dialog":"product_buy"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('customer_cart.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="cartWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="cartHeader"
					direction="horizontal"
					className="w-100 h-auto position-relative" >
					<Text
						id="orderTitle"
						tag="h2"
						className="w-auto h-auto position-relative ff-antonregular" >
						{t('customer_cart.orderTitle')}
					</Text>
					<Text
						id="orderConfirmedText"
						if={{"what":"@cookie.orderStatus","is":"confirmed"}}
						tag="h2"
						className="w-auto h-auto position-relative ps-16px text-primary ff-antonregular" >
						{t('customer_cart.orderConfirmedText')}
					</Text>
				</Group>
				<List
					id="cartItemList"
					direction="vertical"
					src="@firebase.firestore.orders.(@cookie.locationID).cart"
					className="w-100 h-auto position-relative mt-16px" >
					<Card
						id="cartItemCard"
						className="w-100 h-auto position-relative mb-16px" >
						<Card.Body>
								<Group
									id="cartItemCardContent"
									direction="auto"
									className="w-100 h-auto position-relative" >
									<Group
										id="cartItemImageGroup"
										direction="vertical"
										className="max-w-128px min-w-96px w-fill h-auto align-center valign-center position-relative" >
										<Image
											id="cartItemImage"
											fallback={avellana}
											src="image"
											className="w-96px h-auto align-center valign-center position-relative" />
									</Group>
									<Group
										id="cartItemDataGroup"
										direction="auto"
										className="min-w-320px w-fill h-auto valign-center position-relative" >
										<Text
											id="cartItemUnits"
											tag="h2"
											className="w-64px h-auto valign-center position-relative ms-48px ff-librebaskervillebold text-center" >
											{t('customer_cart.cartItemUnits')}
										</Text>
										<Group
											id="cartItemInfo"
											direction="vertical"
											className="w-fill h-auto valign-center position-relative p-24px" >
											<Text
												id="cartItemName"
												tag="h3"
												src="name"
												className="w-100 h-auto position-relative ff-librebaskervillebold text-start" >
												{t('customer_cart.cartItemName')}
											</Text>
											<Text
												id="cartItemDesc"
												src="description"
												className="w-100 h-auto position-relative ff-librebaskervilleregular text-start" >
												{t('customer_cart.cartItemDesc')}
											</Text>
										</Group>
									</Group>
									<Group
										id="cartItemButtons"
										direction="horizontal"
										className="w-auto h-auto align-center valign-center position-relative" >
										<Icon
											id="cartButtonEdit"
											onClick={cartButtonEditOnClick}
											src="pencil"
											size={4}
											className="w-auto h-auto border-radius-100px valign-center position-relative bg-accent p-4px m-6px"
										/>
										<Icon
											id="cartButtonRemove"
											onClick={cartButtonRemoveOnClick}
											src="trash-can-outline"
											size={4}
											className="w-auto h-auto border-radius-100px valign-center position-relative bg-primary p-4px m-6px"
										/>
									</Group>
								</Group>
						</Card.Body>
					</Card>
				</List>
			</Group>
			<Group
				id="cartFooter"
				direction="vertical"
				className="w-100 h-auto position-relative bg-white p-16px" >
				<Button
					id="cartKeepButton"
					onClick={cartKeepButtonOnClick}
					variant="accent"
					className="w-100 h-auto border-radius-32px position-relative" >
					{t('customer_cart.cartKeepButton')}
				</Button>
				<Button
					id="confirmButton"
					if={{"what":"@cookie.orderStatus","is_not":"confirmed"}}
					onClick={confirmButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative mt-16px" >
					{t('customer_cart.confirmButton')}
				</Button>
				<Button
					id="cancelButton"
					if={{"what":"@cookie.orderStatus","is":"confirmed"}}
					onClick={cancelButtonOnClick}
					variant="primary-text"
					className="w-100 h-auto border-radius-32px position-relative mt-16px" >
					{t('customer_cart.cancelButton')}
				</Button>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
