import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { FullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Text, Group, Image } from '../components';
import { useEffect } from 'react';
import ic_warning from '../assets/assets/ic_warning.svg';
import { useTranslation } from 'react-i18next';

export const TempView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const tempParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( tempParams, [])
	const tempOnLoaded = (options) => {
		run([{"id":"api1682411739778","function":"api","api":{"method":"sendgrid.sendTemplate","template_id":"","to":""}}], options)
	}
	useEffect(tempOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('temp.title')}</title>
      </Helmet>
      <FullscreenWrapper>
          
			<Text
				id="component1646571301582"
				src="@property.response.status"
				className="w-100 h-auto position-relative" >
				{t('temp.component1646571301582')}
			</Text>
			<Text
				id="component1646571323881"
				src="@property.response.cif"
				className="w-100 h-auto position-relative" >
				{t('temp.component1646571323881')}
			</Text>
			<Group
				id="group1664279076300"
				direction="vertical"
				className="w-100 h-auto position-relative" >
				<Image
					id="image1664279080640"
					src={ic_warning}
					className="w-16 h-auto position-relative" />
				<Text
					id="text1664279116105"
					className="w-100 h-auto position-relative" >
					{t('temp.text1664279116105')}
				</Text>
			</Group>
      </FullscreenWrapper>
    </>
  );
};
