import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Wrapper_adminWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List } from '../components';
import { useTranslation } from 'react-i18next';

export const A_ordersView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const adminOrderActionDelete1664065039812OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"popup","dialog":"order_delete"}], options)
	}
	const adminOrderActionReopen1664065039812OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).order_status","value":"open"}], options)
	}
	const openOrderActionConfirm1664065039812OnClick = (options) => {
		run([{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"popup","dialog":"order_confirmation"}], options)
	}
	const openOrderActionEditDate1664065039812OnClick = (options) => {
		run([{"function":"do","what":[{"function":"set","what":"@property.order","value":"@element.adminOrderList.value"},{"function":"set","what":"@cookie.userID","value":"@property.order.customer"},{"function":"set","what":"@cookie.orderID","value":"@element.adminOrderList.selected"},{"function":"set","what":"@property.minDate","value":null},{"function":"popup","dialog":"customer_edit_order"}]}], options)
	}
	const forceShippingOnClick = (options) => {
		run([{"function":"set","what":"@property.action","value":"force_shipping"},{"function":"popup","dialog":"confirmation"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('a_orders.title')}</title>
      </Helmet>
      <Wrapper_adminWrapper>
          
			<Group
				id="adminOrdersBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-admin_background p-32px scrollable" >
				<Group
					id="adminOrdersBody1664065039812"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="ordersHeader1664065039812"
						direction="horizontal"
						className="w-100 h-auto position-relative" >
						<Text
							id="ordersHeaderTitleOpen"
							if={{"what":"@cookie.order_status","is":"open"}}
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-admin_title" >
							{t('a_orders.ordersHeaderTitleOpen')}
						</Text>
						<Text
							id="ordersHeaderTitleConfirmed"
							if={{"what":"@cookie.order_status","is":"confirmed"}}
							tag="h2"
							className="w-fill h-auto valign-center position-relative ff-admin_title" >
							{t('a_orders.ordersHeaderTitleConfirmed')}
						</Text>
						<Group
							id="group1664065176611"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="forceShipping"
							onClick={forceShippingOnClick}
							className="w-auto h-auto border-radius-50px valign-center position-relative bg-accent px-16px py-6px" >
							{t('a_orders.forceShipping')}
						</Text>
					</Group>
					<List
						id="adminOrderList"
						src="@firebase.firestore.orders"
						filter={{"what":"order_status","is":"@cookie.order_status"}}
						className="w-100 h-auto position-relative mt-32px" >
						<Group
							id="historyOrderCard1664065039812"
							direction="vertical"
							className="w-100 h-auto border-primary-dark border-top border-bottom border-start border-end position-relative bg-card_bg mb-24px" >
							<Group
								id="historyOrderCardHeader1664065039812"
								direction="auto"
								className="w-100 h-auto position-relative bg-primary-dark" >
								<Group
									id="openOrderCreationGroup1664065039812"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderCreationTitle1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_orders.openOrderCreationTitle1664065039812')}
									</Text>
									<Text
										id="openOrderCreationValue1664065039812"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="created_at"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderDeliveryGroup1664065039812"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px me-24px" >
									<Text
										id="openOrderDeliveryTitle1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_orders.openOrderDeliveryTitle1664065039812')}
									</Text>
									<Text
										id="openOrderDeliveryValue1664065039812"
										format={{"type":"date","day":"numeric","month":"long","year":"numeric"}}
										tag="small"
										src="delivery_date"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('')}
									</Text>
								</Group>
								<Group
									id="openOrderBilledGroup1664065039812"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderBilledTitle1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-primary ff-admin_regular" >
										{t('a_orders.openOrderBilledTitle1664065039812')}
									</Text>
									<Text
										id="openOrderBilledValue1664065039812"
										tag="small"
										src="company"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_orders.openOrderBilledValue1664065039812')}
									</Text>
								</Group>
								<Group
									id="openOrderLocationGroup1664065039812"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderLocationTitle1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_orders.openOrderLocationTitle1664065039812')}
									</Text>
									<Text
										id="openOrderLocationValue1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-admin_regular" >
										{t('a_orders.openOrderLocationValue1664065039812')}
									</Text>
								</Group>
								<Group
									id="component1664065039812"
									direction="vertical"
									className="w-fill h-auto position-relative" >
								</Group>
								<Group
									id="openOrderIdGroup1664065039812"
									direction="vertical"
									className="w-auto h-auto position-relative p-8px" >
									<Text
										id="openOrderIdTitle1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-primary-dark ff-label" >
										{t('a_orders.openOrderIdTitle1664065039812')}
									</Text>
									<Text
										id="openOrderIdValue1664065039812"
										tag="small"
										className="w-100 h-auto position-relative text-text-on-primary ff-label" >
										{t('a_orders.openOrderIdValue1664065039812')}
									</Text>
								</Group>
							</Group>
							<Group
								id="historyOrderCardContent1664065039812"
								direction="auto"
								className="w-100 h-auto position-relative p-8px" >
								<Group
									id="historyOrderCardCart1664065039812"
									direction="vertical"
									className="w-fill h-auto position-relative" >
									<List
										id="openOrderCartList1664065039812"
										src="@firebase.firestore.orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="openOrderCartListRow1664065039812"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Text
												id="openOrderCartListRowUnits1664065039812"
												className="w-50px h-auto position-relative me-12px ff-label" >
												{t('a_orders.openOrderCartListRowUnits1664065039812')}
											</Text>
											<Text
												id="openOrderCartListRowName1664065039812"
												src="name"
												className="w-fill h-auto position-relative ff-label" >
												{t('a_orders.openOrderCartListRowName1664065039812')}
											</Text>
										</Group>
									</List>
								</Group>
								<Group
									id="historyOrderCardActions1664065039812"
									direction="vertical"
									className="max-w-400px min-w-300px w-fill h-auto position-relative p-8px" >
									<Text
										id="openOrderActionEditDate1664065039812"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionEditDate1664065039812OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_orders.openOrderActionEditDate1664065039812')}
									</Text>
									<Text
										id="openOrderActionConfirm1664065039812"
										if={{"what":"@field.order_status","is":"open"}}
										onClick={openOrderActionConfirm1664065039812OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_orders.openOrderActionConfirm1664065039812')}
									</Text>
									<Text
										id="adminOrderActionReopen1664065039812"
										if={{"what":"@field.order_status","is":"confirmed"}}
										onClick={adminOrderActionReopen1664065039812OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-accent p-4px mb-8px text-center" >
										{t('a_orders.adminOrderActionReopen1664065039812')}
									</Text>
									<Text
										id="adminOrderActionDelete1664065039812"
										if={{"what":"@field.order_status","is":"open","or":{"what":"@field.order_status","is":"confirmed"}}}
										onClick={adminOrderActionDelete1664065039812OnClick}
										tag="small"
										className="w-150px h-auto border-radius-64px align-end position-relative bg-primary p-4px mb-8px text-center" >
										{t('a_orders.adminOrderActionDelete1664065039812')}
									</Text>
								</Group>
							</Group>
						</Group>
					</List>
				</Group>
			</Group>
      </Wrapper_adminWrapper>
    </>
  );
};
