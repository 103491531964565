import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Customer_add_companyDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const customer_add_company_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@property.showCurtain","value":true},{"function":"set","what":"@cookie.isCompanied","value":true},{"function":"request","url":"https:\/\/europe-west1-(@cookie.projectID).cloudfunctions.net\/createCompany","method":"post","params":{"userID":"@cookie.userID","cif":"@property.cif","name":"@property.sales_name"},"into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@property.showCurtain","value":false},{"function":"do","what":[{"function":"popup","dialog":"customer_company_exists"}],"if":{"what":"@database.response.status","is":"already_exists"}},{"function":"do","what":[{"function":"set","what":"@property.error","value":"La compa\u00f1\u00eda que has introducido existe en nuestros sistemas. Necesitamos verificar los datos para que puedas realizar pedidos. En cuanto verifiquemos, ya podr\u00e1s realizar pedidos."},{"function":"popup","dialog":"error_dialog"}],"if":{"what":"@database.response.status","is":"exists_on_holded"}},{"function":"do","what":[{"function":"set","what":"@property.cif","value":"@database.response.cif"},{"function":"set","what":"@cookie.cif","value":"@property.cif"},{"function":"set","what":"@property.isCompanied","value":true},{"function":"goto","view":"customer_application_form"}],"if":{"what":"@database.response.status","is":"pending"}}],"onerror":[{"function":"set","what":"@property.showCurtain","value":false},{"function":"set","what":"@property.error","value":"Se ha producido un error al intentar crear la empresa"},{"function":"popup","dialog":"error_dialog"}],"if":{"what":"@firebase.firestore.companies.(@property.cif).cif","else":[{"function":"popup","dialog":"customer_company_exists"}],"is":null}},{"function":"hide","dialog":"customer_add_company"}], options)
	}
	const customer_add_company_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"customer_add_company"}], options)
	}

  return (
    
    
			<Modal id="customer_add_company">
					<Modal.Body>
						<Text
							id="component1653055062808"
							className="w-100 h-auto position-relative ff-librebaskervilleregular" >
							{t('customer_add_company.component1653055062808')}
						</Text>
						<Input.Text
							id="addCompanyCifInput"
							name="text"
							src="@property.cif"
						autoComplete="off"
							className="w-auto h-auto position-relative mt-16px" />
						<Text
							id="addCompanyNameLabel"
							className="w-100 h-auto position-relative mt-24px ff-librebaskervilleregular" >
							{t('customer_add_company.addCompanyNameLabel')}
						</Text>
						<Input.Text
							id="addCompanyNameInput"
							name="text"
							src="@property.sales_name"
						autoComplete="off"
							className="w-auto h-auto position-relative mt-16px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="customer_add_company_buttons_negative"
							onClick={customer_add_company_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('customer_add_company.customer_add_company_buttons_negative')}
						</Button>
						<Button
							id="customer_add_company_buttons_positive"
							onClick={customer_add_company_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('customer_add_company.customer_add_company_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Customer_add_companyDialog;
