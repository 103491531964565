import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Order_canceledDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const order_canceled_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"order_canceled"}], options)
	}

  return (
    
    
			<Modal id="order_canceled" size="md">
					<Modal.Body>
						<Text
							id="orderThanksText1649794704553"
							tag="h1"
							className="w-100 h-auto position-relative text-primary ff-antonregular" >
							{t('order_canceled.orderThanksText1649794704553')}
						</Text>
						<Text
							id="cartConfirmCompany1649794704553"
							className="w-100 h-auto position-relative mt-48px ff-librebaskervillebold" >
							{t('order_canceled.cartConfirmCompany1649794704553')}
						</Text>
						<Text
							id="orderDoneAddress1649794704553"
							className="w-100 h-auto position-relative mt-24px ff-librebaskervillebold" >
							{t('order_canceled.orderDoneAddress1649794704553')}
						</Text>
						<Text
							id="orderDoneCity1649794704553"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_canceled.orderDoneCity1649794704553')}
						</Text>
						<Text
							id="orderDoneProvince1649794704553"
							className="w-100 h-auto position-relative ff-librebaskervillebold" >
							{t('order_canceled.orderDoneProvince1649794704553')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="order_canceled_buttons_positive"
							onClick={order_canceled_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('order_canceled.order_canceled_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Order_canceledDialog;
