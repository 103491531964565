import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { CustomerwrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Card, Button } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Customer_historyView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const customer_historyParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( customer_historyParams, [])
	const customer_historyOnLoaded = (options) => {
		run([{"function":"goto","if":{"what":"@firebase.firestore.customers.(@cookie.userID).status","is":"uncompanied"},"view":"customer_unverified"}], options)
	}
	useEffect(customer_historyOnLoaded, [])
	const orderReplayOnClick = (options) => {
		run([{"function":"set","what":"@property.orderID","value":"@element.historyList.selected"},{"function":"popup","dialog":"order_repeat"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('customer_history.title')}</title>
      </Helmet>
      <CustomerwrapperWrapper>
          
			<Group
				id="historyWrapper"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px scrollable" >
				<Group
					id="historyBody"
					direction="vertical"
					className="w-100 h-fill position-relative bg-white p-32px" >
					<Text
						id="historyTitle"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('customer_history.historyTitle')}
					</Text>
					<List
						id="historyList"
						direction="vertical"
						src="@firebase.firestore.companies.(@cookie.companyID).orders"
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="orderCard"
							className="w-100 h-auto position-relative m-4px" >
							<Card.Body>
									<Group
										id="orderHeaderGroup"
										direction="horizontal"
										className="w-100 h-auto position-relative" >
										<Text
											id="orderDate"
											tag="h3"
											src="date"
											className="w-fill h-auto position-relative ff-librebaskervillebold" >
											{t('customer_history.orderDate')}
										</Text>
										<Group
											id="orderStatusGroup"
											direction="vertical"
											className="w-auto h-auto position-relative" >
											<Text
												id="orderStatusText"
												if={{"what":"@field.order_status","is":"processing"}}
												tag="small"
												className="w-100 h-auto position-relative text-primary-text ff-librebaskervillebold" >
												{t('customer_history.orderStatusText')}
											</Text>
											<Text
												id="orderStatusTextSent"
												if={{"what":"@field.order_status","is":"sent"}}
												tag="small"
												className="w-100 h-auto position-relative text-primary-text ff-librebaskervillebold" >
												{t('customer_history.orderStatusTextSent')}
											</Text>
											<Text
												id="orderStatusTextDelivered"
												if={{"what":"@field.order_status","is":"delivered"}}
												tag="small"
												className="w-100 h-auto position-relative text-accent ff-librebaskervillebold" >
												{t('customer_history.orderStatusTextDelivered')}
											</Text>
											<Text
												id="orderStatusTextError"
												if={{"what":"@field.order_status","is":"error"}}
												tag="small"
												className="w-100 h-auto position-relative text-pink ff-librebaskervillebold" >
												{t('customer_history.orderStatusTextError')}
											</Text>
										</Group>
									</Group>
									<Group
										id="orderListHeader"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-24px" >
										<Text
											id="orderItemHeaderAmount"
											tag="b"
											className="max-w-130px min-w-80px w-fill h-auto position-relative pe-16px ff-librebaskervilleregular text-end" >
											{t('customer_history.orderItemHeaderAmount')}
										</Text>
										<Text
											id="orderItemHeaderName"
											tag="b"
											className="w-fill h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_history.orderItemHeaderName')}
										</Text>
									</Group>
									<List
										id="orderProductList"
										src="@firebase.firestore.companies.(@cookie.companyID).orders.(@field.id).cart"
										className="w-100 h-auto position-relative" >
										<Group
											id="orderItemListRow"
											direction="horizontal"
											className="w-100 h-auto position-relative" >
											<Text
												id="orderItemAmount"
												className="max-w-130px min-w-80px w-fill h-auto position-relative pe-16px ff-librebaskervilleregular text-end" >
												{t('customer_history.orderItemAmount')}
											</Text>
											<Text
												id="orderItemName"
												src="name"
												className="w-fill h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_history.orderItemName')}
											</Text>
										</Group>
									</List>
									<Button
										id="orderReplay"
										onClick={orderReplayOnClick}
										variant="green"
										className="w-256px h-auto border-radius-32px align-end position-relative mt-24px" >
										{t('customer_history.orderReplay')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </CustomerwrapperWrapper>
    </>
  );
};
