import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Group, List, Card, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

export const CartDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:

  return (
    
    
			<Offcanvas id="cart" placement="bottom">
					<Offcanvas.Header>
						<Offcanvas.Title>{t('cart.title')}</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Group
							id="cartWrapper"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-relative" >
							<List
								id="cartList"
								src="@database.cart"
								className="w-100 h-auto position-relative" >
								<Card
									id="cartItem"
									className="w-100 h-auto position-relative" >
									<Card.Body>
											<Group
												id="cartItemContent"
												direction="horizontal"
												className="w-100 h-auto position-relative" >
												<Group
													id="cartItemContentTexts"
													direction="vertical"
													className="w-fill h-auto position-relative" >
													<Text
														id="cartItemName"
														src="name"
														className="w-100 h-auto position-relative" >
														{t('cart.cartItemName')}
													</Text>
													<Text
														id="cartItemSize"
														src="size"
														className="w-100 h-auto position-relative" >
														{t('cart.cartItemSize')}
													</Text>
													<Text
														id="cartItemQuantity"
														src="quantity"
														className="w-100 h-auto position-relative" >
														{t('cart.cartItemQuantity')}
													</Text>
												</Group>
												<Group
													id="cartItemContentSubtotal"
													direction="vertical"
													className="w-auto h-auto position-relative" >
													<Text
														id="cartItemSubtotal"
														src="subtotal"
														className="w-auto h-auto position-relative f-30px" >
														{t('cart.cartItemSubtotal')}
													</Text>
												</Group>
											</Group>
									</Card.Body>
								</Card>
							</List>
							<Group
								id="cartFooter"
								direction="vertical"
								className="w-100 h-auto position-relative" >
								<Group
									id="cartTotalWrapper"
									direction="horizontal"
									className="w-100 h-auto position-relative p-4px mt-16px" >
									<Text
										id="cartTotalLabel"
										className="w-fill h-auto position-relative f-30px" >
										{t('cart.cartTotalLabel')}
									</Text>
									<Text
										id="cartTotal"
										src="@database.cart.total"
										className="w-auto h-auto position-relative f-30px" >
										{t('cart.cartTotal')}
									</Text>
								</Group>
								<Button
									id="checkoutButton"
									variant="accent"
									className="w-100 h-auto position-relative mt-16px" >
									{t('cart.checkoutButton')}
								</Button>
							</Group>
						</Group>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default CartDialog;
