import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Admin_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Text, List, Card, Button, Image } from '../components';
import placeholder_user from '../assets/image/placeholder_user.svg';
import { useTranslation } from 'react-i18next';

export const AdminView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const candidateValidateButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.candidateList.value"},{"function":"set","what":"@property.applicationID","value":"@element.candidateList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"popup","dialog":"admin_validate_company"}], options)
	}
	const newApplicationValidateButton1652866938556OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationPendingList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationPendingList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":false},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}
	const newApplicationValidateButton1652432124413OnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationErrorList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationErrorList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":true},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}
	const newApplicationValidateButtonOnClick = (options) => {
		run([{"function":"set","what":"@property.application","value":"@element.newApplicationList.value"},{"function":"set","what":"@cookie.applicationID","value":"@element.newApplicationList.selected"},{"function":"set","what":"@property.company_name","value":null},{"function":"set","what":"@property.allowValidation","value":true},{"function":"popup","dialog":"admin_new_company_review"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('admin.title')}</title>
      </Helmet>
      <Admin_wrapperWrapper>
          
			<Group
				id="customerAdminBody"
				direction="vertical"
				className="w-100 h-fill position-relative bg-white p-32px" >
				<Group
					id="newApplicationsWrapper"
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Text
						id="newApplicationsTitle"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('admin.newApplicationsTitle')}
					</Text>
					<List
						id="newApplicationList"
						direction="auto"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"sent"}}
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newApplicationCard"
							className="max-w-400px min-w-200px w-fill h-auto border-radius-15px position-relative m-4px" >
							<Card.Body>
									<Text
										id="newApplicationName"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('admin.newApplicationName')}
									</Text>
									<Button
										id="newApplicationValidateButton"
										onClick={newApplicationValidateButtonOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('admin.newApplicationValidateButton')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="newApplicationErrorsWrapper"
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Text
						id="newApplicationsTitle1652432124413"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('admin.newApplicationsTitle1652432124413')}
					</Text>
					<List
						id="newApplicationErrorList"
						direction="auto"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"error"}}
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newApplicationErrorCard"
							className="max-w-400px min-w-200px w-fill h-auto border-radius-15px position-relative m-4px" >
							<Card.Body>
									<Text
										id="newApplicationName1652432124413"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('admin.newApplicationName1652432124413')}
									</Text>
									<Button
										id="newApplicationValidateButton1652432124413"
										onClick={newApplicationValidateButton1652432124413OnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('admin.newApplicationValidateButton1652432124413')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="newApplicationsPending"
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Text
						id="newApplicationsTitle1652866938556"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('admin.newApplicationsTitle1652866938556')}
					</Text>
					<List
						id="newApplicationPendingList"
						direction="auto"
						src="@firebase.firestore.new_customers"
						filter={{"what":"status","is":"new"}}
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="newApplicationCard1652866938556"
							className="max-w-400px min-w-200px w-fill h-auto border-radius-15px position-relative m-4px" >
							<Card.Body>
									<Text
										id="newApplicationName1652866938556"
										src="legal_name"
										className="w-100 h-auto position-relative" >
										{t('admin.newApplicationName1652866938556')}
									</Text>
									<Button
										id="newApplicationValidateButton1652866938556"
										onClick={newApplicationValidateButton1652866938556OnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('admin.newApplicationValidateButton1652866938556')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
				<Group
					id="applicationsWrapper"
					direction="vertical"
					className="w-100 h-auto position-relative bg-white p-32px" >
					<Text
						id="candidatesTitle"
						tag="h2"
						className="w-100 h-auto position-relative ff-antonregular" >
						{t('admin.candidatesTitle')}
					</Text>
					<List
						id="candidateList"
						direction="auto"
						src="@firebase.firestore.applications"
						columns={"auto"}
						className="w-fill h-auto position-relative mt-12px" >
						<Card
							id="candidateCard"
							className="min-w-200px w-fill h-auto border-radius-15px position-relative m-4px" >
							<Card.Body>
									<Image
										id="candidateGravatar"
										fallback={placeholder_user}
										src="gravatar"
										className="w-64px h-auto position-relative" />
									<Text
										id="candidateCIF"
										tag="b"
										className="w-100 h-auto position-relative" >
										{t('admin.candidateCIF')}
									</Text>
									<Text
										id="candidateEmail"
										src="email"
										className="w-100 h-auto position-relative" >
										{t('admin.candidateEmail')}
									</Text>
									<Button
										id="candidateValidateButton"
										onClick={candidateValidateButtonOnClick}
										variant="green"
										className="w-auto h-auto border-radius-32px position-relative mt-16px" >
										{t('admin.candidateValidateButton')}
									</Button>
							</Card.Body>
						</Card>
					</List>
				</Group>
			</Group>
      </Admin_wrapperWrapper>
    </>
  );
};
