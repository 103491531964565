import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, List, Group, Icon, Text } from '../components';
import { useTranslation } from 'react-i18next';

export const A_edit_orderDialog = () => {
    const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const openOrderPlusButton1667402783484OnClick = (options) => {
		run([{"function":"set","what":"@cookie.itemID","value":"@element.editOrderCartList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@cookie.itemID).units","value":"@property.units.+1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.itemID).units","value":"@property.units.+1"}]}], options)
	}
	const openOrderMinusButton1667402783484OnClick = (options) => {
		run([{"function":"set","what":"@cookie.itemID","value":"@element.editOrderCartList.selected"},{"function":"do","what":[{"function":"set","what":"@property.units","value":"@field.units"},{"function":"set","what":"@database.orders.(@cookie.orderID).cart.(@cookie.itemID).units","value":"@property.units.-1"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.itemID).units","value":"@property.units.-1"}],"if":{"what":"@field.units","more_than":1}},{"function":"do","what":[{"function":"delete","what":"@database.orders.(@cookie.orderID).cart.(@cookie.itemID)"},{"function":"delete","what":"@firebase.firestore.orders.(@cookie.orderID).cart.(@cookie.itemID)"}],"if":{"what":"@field.units","is":1}}], options)
	}

  return (
    
    
			<Offcanvas id="a_edit_order" placement="bottom">
					<Offcanvas.Body>
						<List
							id="editOrderCartList"
							src="@firebase.firestore.orders.(@cookie.orderID).cart"
							className="w-100 h-auto position-relative" >
							<Group
								id="openOrderCartListRow1667402561783"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<Group
									id="openOrderCartListRowInput1667402783484"
									direction="horizontal"
									className="w-auto h-auto position-relative me-16px" >
									<Icon
										id="openOrderMinusButton1667402783484"
										onClick={openOrderMinusButton1667402783484OnClick}
										src="minus-circle-outline"
										color="var(--primary-dark)"
										className="w-auto h-auto valign-center position-relative"
									/>
									<Text
										id="openOrderCartListRowUnits1667402783484"
										className="w-40px h-auto position-relative ff-label text-center" >
										{t('a_edit_order.openOrderCartListRowUnits1667402783484')}
									</Text>
									<Icon
										id="openOrderPlusButton1667402783484"
										onClick={openOrderPlusButton1667402783484OnClick}
										src="plus-circle-outline"
										color="var(--primary-dark)"
										className="w-auto h-auto valign-center position-relative"
									/>
								</Group>
								<Text
									id="openOrderCartListRowName1667402561783"
									src="name"
									className="w-fill h-auto position-relative ff-label" >
									{t('a_edit_order.openOrderCartListRowName1667402561783')}
								</Text>
							</Group>
						</List>
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default A_edit_orderDialog;
