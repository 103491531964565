import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Lottie, Text, List, Card, Input, Button } from '../components';
import anim_wait from '../assets/animation/anim_wait.json';
import { useTranslation } from 'react-i18next';

const Customer_edit_orderDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const buttonCloseEditOrderOnClick = (options) => {
		run([{"function":"hide","dialog":"customer_edit_order"}], options)
	}
	const editOrder_date_inputOnChanged = (options) => {
		run([{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).delivery_date","value":"@property.deliveryDate"}], options)
	}
	const editOrderLocationCardOnClick = (options) => {
		run([{"function":"set","what":"@cookie.locationID","value":"@element.editOrderLocationList.selected"},{"function":"set","what":"@property.minDate","value":"@database.response.companies.(@cookie.companyID).locations.(@cookie.locationID).minDate"},{"function":"set","what":"@cookie.location","value":"@element.editOrderLocationList.value"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).location_id","value":"@cookie.locationID"},{"function":"set","what":"@firebase.firestore.orders.(@cookie.orderID).location_name","value":"@cookie.location.name"},{"function":"set","what":"@property.minDate","value":"@database.response.companies.(@cookie.companyID).locations.(@cookie.locationID).minDate","if":{"what":"@cookie.locationID","is_not":null,"and":{"what":"@cookie.companyID","is_not":null}}},{"function":"set","what":"@property.orderStep","value":"date"}], options)
	}
	const editOrderCompanyCardOnClick = (options) => {
		run([{"function":"set","what":"@cookie.companyID","value":"@element.editOrderCompanyList.selected"},{"function":"set","what":"@property.orderStep","value":"location"}], options)
	}
	const customer_edit_orderOnLoaded = (options) => {
		run([{"function":"set","what":"@property.orderStep","value":"wait"},{"function":"request","url":"https:\/\/europe-west1-customers-8d060.cloudfunctions.net\/preOrder\n","params":{"userID":"@cookie.userID"},"method":"post","into":"@database.response","response":"json-api","onsuccess":[{"function":"set","what":"@property.orderStep","value":"@database.response.step"},{"function":"set","what":"@cookie.locationID","value":"@database.response.locationID"},{"function":"set","what":"@cookie.companyID","value":"@database.response.companyID"},{"function":"set","what":"@property.minDate","value":"@database.response.minDate"},{"function":"set","what":"@property.deliveryDate","value":"@firebase.firestore.orders.(@cookie.orderID).delivery_date"}]}], options)
	}

  return (
    
    
			<Modal id="customer_edit_order" centered size="md" onShow={customer_edit_orderOnLoaded}>
					<Modal.Header>
						<Modal.Title>{t('customer_edit_order.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Group
							id="editOrder_wait"
							if={{"what":"@property.orderStep","is":"wait"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Lottie
								id="component1657102008392"
								src={anim_wait}
								className="w-96px h-auto align-center valign-center position-absolute" />
						</Group>
						<Group
							id="editOrder_company"
							if={{"what":"@property.orderStep","is":"company"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="editOrder_company_content"
								direction="vertical"
								className="w-100 min-h-100 fg-100 position-relative scrollable" >
								<Text
									id="editOrder_company_label"
									className="w-100 h-auto position-relative" >
									{t('customer_edit_order.editOrder_company_label')}
								</Text>
								<List
									id="editOrderCompanyList"
									direction="auto"
									src="@database.response.companies"
									className="w-100 h-auto position-relative mt-32px" >
									<Card
										id="editOrderCompanyCard"
										onClick={editOrderCompanyCardOnClick}
										className="min-w-140px w-auto h-auto position-relative bg-primary me-8px mb-8px" >
										<Card.Body>
												<Text
													id="editOrderCompanyName"
													src="name"
													className="w-100 h-auto position-relative" >
													{t('customer_edit_order.editOrderCompanyName')}
												</Text>
												<Text
													id="editOrderCompanyID"
													src="company"
													className="w-100 h-auto position-relative" >
													{t('customer_edit_order.editOrderCompanyID')}
												</Text>
										</Card.Body>
									</Card>
								</List>
							</Group>
						</Group>
						<Group
							id="editOrder_location"
							if={{"what":"@property.orderStep","is":"location"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="editOrder_location_content"
								direction="vertical"
								className="w-100 min-h-100 fg-100 position-relative scrollable" >
								<Text
									id="editOrder_location_label"
									className="w-100 h-auto position-relative" >
									{t('customer_edit_order.editOrder_location_label')}
								</Text>
								<List
									id="editOrderLocationList"
									direction="auto"
									src="@database.response.companies.(@cookie.companyID).locations"
									order={{"type":"asc","field":"name"}}
									className="w-100 h-auto position-relative mt-32px" >
									<Card
										id="editOrderLocationCard"
										onClick={editOrderLocationCardOnClick}
										className="min-w-140px w-auto h-auto border-radius-15px position-relative bg-primary me-8px mb-8px" >
										<Card.Body>
												<Text
													id="component1657371689764"
													src="name"
													className="w-100 h-auto position-relative" >
													{t('customer_edit_order.component1657371689764')}
												</Text>
												<Text
													id="component1657057829132vcv1657371689764"
													src="company"
													className="w-100 h-auto position-relative" >
													{t('customer_edit_order.component1657057829132vcv1657371689764')}
												</Text>
										</Card.Body>
									</Card>
								</List>
							</Group>
						</Group>
						<Group
							id="editOrder_date"
							if={{"what":"@property.orderStep","is":"date"}}
							direction="vertical"
							className="w-100 min-h-300px h-300px position-relative" >
							<Group
								id="editOrder_date_content"
								direction="vertical"
								className="w-100 h-fill position-relative" >
								<Text
									id="editOrder_date_label"
									className="w-100 h-auto position-relative" >
									{t('customer_edit_order.editOrder_date_label')}
								</Text>
								<Text
									id="editOrder_date_instructions"
									tag="small"
									className="w-100 h-auto position-relative text-primary-light" >
									{t('customer_edit_order.editOrder_date_instructions')}
								</Text>
								<Input.Date
									id="editOrder_date_input"
									name="date"
									src="@property.deliveryDate"
	min="@property.minDate"
								onChange={editOrder_date_inputOnChanged}
									className="w-auto h-auto position-relative mt-32px" />
							</Group>
							<Button
								id="buttonCloseEditOrder"
								if={{"what":"@property.deliveryDate","is_not":null}}
								onClick={buttonCloseEditOrderOnClick}
								variant="primary"
								className="w-auto h-auto align-end position-relative" >
								{t('customer_edit_order.buttonCloseEditOrder')}
							</Button>
						</Group>
					</Modal.Body>
			</Modal>
    
  );
};

export default Customer_edit_orderDialog;
