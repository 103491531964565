import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Group, Text, List, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Customer_new_company_reviewDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const customer_new_company_review_buttons_positiveOnClick = (options) => {
		run([{"function":"set","what":"@firebase.firestore.new_customers.(@cookie.userID).status","value":"sent"},{"function":"set","what":"@firebase.firestore.customers.(@cookie.userID).status","value":"must_be_accepted_by_admin"},{"function":"goto","view":"customer_unverified","if":{"what":"@firebase.firestore.customers.(@cookie.userID).current_company","is":null}},{"function":"hide","dialog":"customer_new_company_review"}], options)
	}
	const customer_new_company_review_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"customer_new_company_review"}], options)
	}

  return (
    
    
			<Modal id="customer_new_company_review" size="md">
					<Modal.Body>
						<Group
							id="reviewNewCompanyBody"
							direction="vertical"
							className="w-100 h-auto position-relative scrollable" >
							<Text
								id="newCompanyInstructions"
								className="w-100 h-auto position-relative mb-16px text-primary ff-librebaskervillebold" >
								{t('customer_new_company_review.newCompanyInstructions')}
							</Text>
							<Group
								id="newCompanyNamingGroup"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyNamingLabel"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyNamingLabel')}
								</Text>
								<Group
									id="newCompanyLegalName"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyLegalNameLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyLegalNameLabel')}
									</Text>
									<Text
										id="newCompanyLegalNameValue"
										src="@property.legal_name"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyLegalNameValue')}
									</Text>
								</Group>
								<Group
									id="newCompanySalesName"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanySalesNameLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanySalesNameLabel')}
									</Text>
									<Text
										id="newCompanySalesNameValue"
										src="@property.sales_name"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanySalesNameValue')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyContactGroup"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyContactLabel"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyContactLabel')}
								</Text>
								<Group
									id="newCompanyContactName"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactNameLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactNameLabel')}
									</Text>
									<Text
										id="newCompanyContactNameValue"
										src="@property.contact_name"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactNameValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactNIF"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactNIFLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactNIFLabel')}
									</Text>
									<Text
										id="newCompanyContactNIFValue"
										src="@property.contact_nif"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactNIFValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactEmail"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactEmailLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactEmailLabel')}
									</Text>
									<Text
										id="newCompanyContactEmailValue"
										src="@property.contact_email"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactEmailValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactPhone"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactPhoneLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactPhoneLabel')}
									</Text>
									<Text
										id="newCompanyContactPhoneValue"
										src="@property.phone"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactPhoneValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContactMobile"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactMobileLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactMobileLabel')}
									</Text>
									<Text
										id="newCompanyContactMobileValue"
										src="@property.mobile"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactMobileValue')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyBillingGroup"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyBillingLabel"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyBillingLabel')}
								</Text>
								<Group
									id="newCompanyContacBillingAddress"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingAddressLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingAddressLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingAddressValue"
										src="@property.billing_adress"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingAddressValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCity"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCityLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingCityLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingCityValue"
										src="@property.billing_city"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingCityValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCP"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCPLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingCPLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingCPValue"
										src="@property.billing_cp"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingCPValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingProvince"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingProvinceLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingProvinceLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingProvineValue"
										src="@property.billing_province"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingProvineValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingCountry"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingCountryLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingCountryLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingCountryValue"
										src="@property.billing_country"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingCountryValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacIban"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactIbanLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactIbanLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingIbanValue"
										src="@property.billing_iban"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingIbanValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacSwift"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactSwiftLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactSwiftLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingSwiftValue"
										src="@property.billing_swift"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingSwiftValue')}
									</Text>
								</Group>
								<Group
									id="newCompanyContacBillingRef"
									direction="horizontal"
									className="w-100 h-auto position-relative mt-16px" >
									<Text
										id="newCompanyContactBillingRefLabel"
										className="w-auto h-auto position-relative ff-librebaskervilleregular" >
										{t('customer_new_company_review.newCompanyContactBillingRefLabel')}
									</Text>
									<Text
										id="newCompanyContactBillingRefValue"
										src="@property.billing_ref"
										className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
										{t('customer_new_company_review.newCompanyContactBillingRefValue')}
									</Text>
								</Group>
							</Group>
							<Group
								id="newCompanyDeliveryGroup"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyDelivery"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyDelivery')}
								</Text>
								<Group
									id="newCompanyDeliveryGroup"
									direction="vertical"
									className="w-100 h-auto border-radius-5px position-relative bg-light mb-8px" >
									<Group
										id="newCompanyDeliveryName"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryNameLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryNameLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryNameValue"
											src="@property.delivery_name"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryNameValue')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCity"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCityLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryCityLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryCityValue"
											src="@property.delivery_city"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryCityValue')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCP"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCPLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryCPLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryCPValue"
											src="@property.delivery_cp"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryCPValue')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryProvince"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryProvinceLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryProvinceLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryProvinceValue"
											src="@property.delivery_province"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryProvinceValue')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryCountry"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCountryLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryCountryLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryCountryValue"
											src="@property.delivery_country"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryCountryValue')}
										</Text>
									</Group>
									<Group
										id="newCompanyDeliveryComments"
										direction="horizontal"
										className="w-100 h-auto position-relative mt-16px" >
										<Text
											id="newCompanyDeliveryCommentsLabel"
											className="w-auto h-auto position-relative ff-librebaskervilleregular" >
											{t('customer_new_company_review.newCompanyDeliveryCommentsLabel')}
										</Text>
										<Text
											id="newCompanyDeliveryCommentsValue"
											src="@property.delivery_comments"
											className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
											{t('customer_new_company_review.newCompanyDeliveryCommentsValue')}
										</Text>
									</Group>
								</Group>
								<List
									id="newCompanyDeliveryList"
									src="@firebase.firestore.new_customers.(@cookie.userID).delivery"
									className="w-100 h-auto position-relative" >
									<Group
										id="newCompanyDeliveryCard"
										direction="vertical"
										className="w-100 h-auto border-radius-5px position-relative bg-light mb-8px" >
										<Group
											id="newCompanyDeliveryListName"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListNameLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListNameLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListNameValue"
												src="delivery_name"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListNameValue')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCity"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCityLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListCityLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListCityValue"
												src="delivery_city"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListCityValue')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCP"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCPLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListCPLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListCPValue"
												src="delivery_cp"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListCPValue')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListProvince"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListProvinceLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListProvinceLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListProvinceValue"
												src="delivery_province"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListProvinceValue')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListCountry"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCountryLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListCountryLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListCountryValue"
												src="delivery_country"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListCountryValue')}
											</Text>
										</Group>
										<Group
											id="newCompanyDeliveryListComments"
											direction="horizontal"
											className="w-100 h-auto position-relative mt-16px" >
											<Text
												id="newCompanyDeliveryListCommentsLabel"
												className="w-auto h-auto position-relative ff-librebaskervilleregular" >
												{t('customer_new_company_review.newCompanyDeliveryListCommentsLabel')}
											</Text>
											<Text
												id="newCompanyDeliveryListCommentsValue"
												src="delivery_comments"
												className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
												{t('customer_new_company_review.newCompanyDeliveryListCommentsValue')}
											</Text>
										</Group>
									</Group>
								</List>
							</Group>
							<Group
								id="newCompanyCommentsGroup"
								direction="vertical"
								className="w-100 h-auto border-gray border-top border-bottom border-start border-end border-radius-15px position-relative p-16px mb-16px" >
								<Text
									id="newCompanyCommentsLabel"
									className="w-auto h-auto position-relative pb-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyCommentsLabel')}
								</Text>
								<Text
									id="newCompanyLegalCommentsValue"
									src="@property.comments"
									className="w-fill h-auto position-relative ps-8px ff-librebaskervillebold" >
									{t('customer_new_company_review.newCompanyLegalCommentsValue')}
								</Text>
							</Group>
						</Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="customer_new_company_review_buttons_negative"
							onClick={customer_new_company_review_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('customer_new_company_review.customer_new_company_review_buttons_negative')}
						</Button>
						<Button
							id="customer_new_company_review_buttons_positive"
							onClick={customer_new_company_review_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('customer_new_company_review.customer_new_company_review_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Customer_new_company_reviewDialog;
